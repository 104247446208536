import { ReactNode, useEffect, useRef, useState } from "react";
import { Box, Button, Flex, Menu, MenuButton, MenuItem, MenuList, Portal, Switch, Icon, useColorMode, useDisclosure, useMediaQuery, useColorModeValue, MenuDivider, IconButton, VStack, Image, theme, HStack, Text } from "@chakra-ui/react";
import { useNavigate } from 'react-router-dom'
import Navbar from "./Navbar";
import PrivacyFrame from "./PrivacyFrame"
import symbioseLogo from '../../img/logo/alt/logov3.svg';
import {ReactComponent as symbioseSvg} from '../../img/logo/main/Logo_Symbiose_MANAGEMENT_arbre_blank.svg';
import {ReactComponent as symbioseTitleSvg} from '../../img/logo/main/SYMBIOSE_MANAGEMENT_Black_sans_fond.svg';
import Cookies from 'universal-cookie';
import ReactGA from "react-ga4";
import { logout, truncateStr, isPrivacyPolicyAgreed } from "../../lib/utils";
import { symbioseTheme } from "../../App";
import { useTranslation } from "react-i18next";
import { GB, FR, US } from 'country-flag-icons/react/3x2'
import { MdLogin, MdLogout, MdOutlineLanguage } from "react-icons/md";
import Burger from "./Burger";
import { ChevronDownIcon } from "@chakra-ui/icons";

declare let window: any;

export interface FontFamilyConfig {
  main: string;
  code: string;
}

const FONT_FAMILIES = new Map<string, FontFamilyConfig>()
FONT_FAMILIES.set('Default', {main: '', code: ''})
FONT_FAMILIES.set('Manaspace', {main: 'Manaspace', code: 'Manaspace'})
FONT_FAMILIES.set('AtariClassic', {main: 'AtariClassic', code: 'AtariClassic'})

const SUPPORTED_LANGUAGES = ['en', 'fr']
const htmlId = function(id : string) {
  return "HEADER-" + id
}
const LOGIN_BUTTON_ID = htmlId("login-button")

interface Props {
  transparentBackground?: boolean
}


export default function Header({transparentBackground = false} : Props) {
  const navigate = useNavigate()
  const cookies = new Cookies()
  const { t, i18n } = useTranslation()  
  const { colorMode, toggleColorMode } = useColorMode()
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [dummy, setDummy] = useState<Date | null>(null)
  const [isLargerThan1400] = useMediaQuery('(min-width: 1400px)')
  const [isLargerThan1300] = useMediaQuery('(min-width: 1300px)')
  const [isLargerThan1024] = useMediaQuery('(min-width: 1024px)')
  const [isLargerThan750] = useMediaQuery('(min-width: 750px)')
  const [isLargerThan512] = useMediaQuery('(min-width: 512px)')
  const [isLargerThan400] = useMediaQuery('(min-width: 400px)')
  
  const block = useRef<{bnum: number }| null>(null)
  

  function refresh() {
    setDummy(new Date())
  }


  const getFlagComponent = (language : string, width : string) => {
    if(language.includes('-'))
      language = language.split('-')[0]
    switch(language) { 
      case "en":
        return <GB title="english" width={width} />
      case "fr":
        return <FR title="français" width={width} />
      default: 
        return <GB title="english" width={width} />
    } 
  }

  const setLanguage = (language : string) => {
    if(language.includes('-'))
    language = language.split('-')[0]
    switch(language) { 
      case "en":
        i18n.changeLanguage('en')        
      break
      case "fr":
        i18n.changeLanguage('fr')
        break
      default: 
        console.error('Unhandled language ' + language)
      break
    }
    
    if(isPrivacyPolicyAgreed(cookies))
      ReactGA.event({
        category: "user_event",
        action: "language",
        label: language, // optional
        //value: 99, // optional, must be a number
        //nonInteraction: true, // optional, true/false
        transport: "xhr", // optional, beacon/xhr/image
      });
  }

  const logginClick = () => {

    const button = document.getElementById(LOGIN_BUTTON_ID) as HTMLButtonElement
    button.disabled = true

    try {
      if(cookies.get('account')) {
        logout(cookies, navigate)
      } else
        navigate('/login')
    } finally {
      button.disabled = false
    }
  }

  const toggleFont =   function () {
    const cookies = new Cookies();
    const key = nextFontFamilyKey();
    if(!key) {
      cookies.remove('userFontFamily')
      return
    }
    const newFontFamily = FONT_FAMILIES.get(key)
    if(!newFontFamily) {
      cookies.remove('userFontFamily')
      return
    }
    document.body.style.fontFamily = newFontFamily.main
    window.fontFamilyCode = newFontFamily.code
    
    cookies.set('userFontFamily', key);
    refresh()
  }

  const nextFontFamilyKey = function() : string | null {
    let defaultKey : string | null = null
    let key : string | null = '' 
    for (const entry of Array.from(FONT_FAMILIES.entries())) {

      if(key == null) {
        key = entry[0]
        break
      }
      if(entry[1].main == document.body.style.fontFamily) {
        key = null
      }
      if(defaultKey == null)
        defaultKey = entry[0]
    }

    if(key && key.length > 0)
      return key

    return defaultKey 
  }

  const logo2 = <Image
                  ml='5px'
                  boxSize='40px'
                  objectFit='cover'
                  src={symbioseLogo}
                  alt='Symbiose Management'
                  cursor='pointer'
                  fill={'red'}
                  onClick={() => navigate('/')}
                />
  const logo = <Icon as={symbioseSvg} boxSize='40px' style={{stroke: symbioseTheme.colors.headerTextColor, fill: symbioseTheme.colors.headerTextColor}} cursor='pointer'/>

  const contentWidth = isLargerThan1024 ? '80%' : '100%'
  const innerGroupSpacing = isLargerThan512 ? 10 : 5
  const companyNameSize = isLargerThan1400 ? 'xl' : isLargerThan750 ? 'lg' : 'xs'

  return (
    <Flex
      flexDirection="row"
      bg={transparentBackground ? "transparent" :  symbioseTheme.colors.headerColor}
      py={3}
      w='100%'
      alignItems="center" justifyContent="center"
      h='50px'
      //boxShadow='md'
      //backgroundColor={symbioseTheme.colors.drawerGreen}
    >  
      <PrivacyFrame />

      <Flex flexDirection="row" maxW={contentWidth} w={contentWidth}>
        <Flex flexDirection="row" flexGrow={1}>
          {isLargerThan1024 ?
            <>
              <>
                <a href="/">{logo}</a>
                { isLargerThan1024 &&
                  <HStack color={symbioseTheme.colors.headerTextColor}>
                    <Box w='1px'/>
                    <Text fontSize={companyNameSize} fontWeight='medium'>SYMBIOSE</Text>
                    <Text fontSize={companyNameSize} fontWeight='bold'>MANAGEMENT</Text>
                  </HStack>
                }
                <Box w='1px'/>
              </>
              <Navbar accountMenu={cookies.get('account') ? true : false} />          
              <Box flexGrow={1} minWidth={10}/>
            </>
            :
            <>
              <Box w='5px'/>
              <Burger accountMenu={cookies.get('account') ? true : false}/><Box flexGrow={1} />              
              <Box flexGrow={1}/>
              {isLargerThan400 ? 
              <>
                {logo} 
                <HStack color={symbioseTheme.colors.headerTextColor}>
                  <Box w='1px'/>
                  <Text fontSize={companyNameSize} fontWeight='medium'>SYMBIOSE</Text>
                  <Text fontSize={companyNameSize} fontWeight='bold'>MANAGEMENT</Text>
                  <Box w='1px'/>
                </HStack>
              </>
              :
              <>                
                <VStack color={symbioseTheme.colors.headerTextColor} spacing={1}>
                  <Text fontSize={companyNameSize} fontWeight='medium'>SYMBIOSE</Text>
                  <Text fontSize={companyNameSize} fontWeight='bold'>MANAGEMENT</Text>
                </VStack>
              </>
              }
              <Box flexGrow={1}/>
            </>
          }   

          <Flex zIndex={10}>
            <HStack spacing={innerGroupSpacing}>
              <Button id={LOGIN_BUTTON_ID} onClick={logginClick}>
                {isLargerThan512 ?
                <>{cookies.get('account') ? t('headerSignOutButton') : t('headerSignInButton')}</>
                :
                <>{cookies.get('account') ? <MdLogout /> : <MdLogin/>}</>
                }
              </Button>
              <Menu>
                <MenuButton h='100%' p={0} m={0} color={symbioseTheme.colors.headerTextColor}>
                  <HStack spacing={1} alignItems="center" justifyContent="center">
                    <MdOutlineLanguage />
                    <Text fontSize='sm'>{i18n.language.toUpperCase()}</Text>
                    <ChevronDownIcon />
                  </HStack>                  
                </MenuButton>
                <MenuList border='none'mt={-2} pr={0} pl={0} minW='0' w={'90px'} color={symbioseTheme.colors.headerTextColor} bg={symbioseTheme.colors.headerColor}>
                  <MenuItem>
                    <HStack w='100%' onClick={() => setLanguage('fr')}>
                      <span style={{width: '100%', textAlign: 'left'}}>Français</span>
                    </HStack>
                  </MenuItem>
                  <MenuItem>
                    <HStack w='100%' onClick={() => setLanguage('en')}>
                      <span style={{width: '100%', textAlign: 'left'}}>English</span>
                    </HStack>
                  </MenuItem>                         
                </MenuList>
              </Menu>
            </HStack>                     
            <Flex marginRight={2}/>
          </Flex>          
        </Flex>
      </Flex>
    </Flex>
  )
}