import { Box, Flex,Text, Icon, useColorModeValue, Link, useMediaQuery, Button, Stack, InputGroup, InputLeftElement, Input, InputRightElement, InputLeftAddon, background, VStack, Divider, Center, Tooltip, HStack, FormLabel, FormControl, FormHelperText, Table, Tbody, Td, Tr } from "@chakra-ui/react";
import { symbioseTheme } from "../../../../App";
import { useTranslation } from "react-i18next";

const htmlId = function(id : string) {
  return "PORTFOLIOTREECARD-" + id
}

interface Props {
  currency : string,
  tree : any,
  _color? : string,
  _bg? : string,
  headerColor? : string,
  headerBg? : string
}

export default function PortfolioTreeCard({currency, tree, _color, _bg, headerColor, headerBg} : Props) {

  const { t, i18n } = useTranslation()
  const [isLargerThan1024] = useMediaQuery('(min-width: 1024px)')
  const [isTalerThan512] = useMediaQuery('(min-height: 512px)')    

  const boxShadow = 'none'//'3px 3px 8px 1px #454545'
  const tableCellMaxWidth = isTalerThan512 ? 'initial' : '200px'
  const tableCellWidth = isTalerThan512 ? 'initial' : 'initial'
  const titleFontSize = 'sm'
  const valueFontSize = 'md'

  return (
    <VStack border="1px solid" borderColor={headerBg} borderRadius={0} w='100%' color={_color} bg={_bg} boxShadow={boxShadow}>
      <Table size='sm' variant='unstyled' >
        <Tbody >
        <Tr bg={headerBg} color={headerColor}>
          <Td colSpan={2} textAlign='center' fontSize={valueFontSize} w={tableCellWidth} maxW={tableCellMaxWidth}>{tree.species}</Td>
        </Tr>
        <Tr>
          <Td fontSize={titleFontSize}>{t('portfolioQuantity')}</Td>
          <Td textAlign='center' fontSize={valueFontSize} w={tableCellWidth} maxW={tableCellMaxWidth}>{tree.count}</Td>
        </Tr>
        <Tr>
          <Td fontSize={titleFontSize}>{t('portfolioCO2Captured')}</Td>
          <Td textAlign='center' fontSize={valueFontSize} w={tableCellWidth} maxW={tableCellMaxWidth}>{tree.totalBiometrics.co2CapturedKg.population == 0 ? '--' : tree.totalBiometrics.co2CapturedKg.value}</Td>
        </Tr>
        <Tr>
          <Td fontSize={titleFontSize}>{t('portfolioValue')}</Td>
          <Td textAlign='center' fontSize={valueFontSize} w={tableCellWidth} maxW={tableCellMaxWidth}>{tree.totalValuation.population == 0 ? '--' : tree.totalValuation.valuation.prices[currency]}</Td>
        </Tr>
        </Tbody>
      </Table>
    </VStack>
  )
}