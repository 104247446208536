import {
    useDisclosure,
    MenuItem,
    Menu,
    MenuButton,
    MenuList,
    MenuDivider,
    useColorModeValue,
    useMediaQuery,
    Flex,
    Text,
    IconButton,
    Drawer,
    DrawerOverlay,
    DrawerContent,
    DrawerHeader,
    DrawerBody,
    Button,
    DrawerCloseButton,
    MenuGroup,
} from "@chakra-ui/react"
import { useNavigate } from 'react-router-dom'

import { ChevronDownIcon, ChevronUpIcon, HamburgerIcon } from '@chakra-ui/icons'
import Cookies from "universal-cookie"
import { useTranslation } from "react-i18next"
import { symbioseTheme } from "../../App"
import { AiFillBank, AiFillHome, AiFillSetting, AiOutlineShoppingCart } from "react-icons/ai"
import { BsBuilding, BsInfoCircleFill, BsReceipt, BsReceiptCutoff } from "react-icons/bs"
import { BiReceipt, BiUser } from "react-icons/bi"
import { ImEarth } from "react-icons/im"
import { FaBlog } from "react-icons/fa";


interface Props {
    accountMenu? : boolean
}

export default function Burger({accountMenu = true} : Props) {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const navigate = useNavigate()
    const [isLargerThan350] = useMediaQuery('(min-width: 350px)')
    const cookies = new Cookies()
    const { t, i18n } = useTranslation() 
    
    const textColor = symbioseTheme.colors.headerTextColor
    const menuFontSize = 'lg'
    const menuOpacity = 0.85
    const menuItemHoverColor = symbioseTheme.colors.headerColor

    
    const navigateAndCloseDrawer = function(location : string) {
        onClose()
        navigate(location)
    }

    const aboutHeader = t('navbarAbout') ? t('navbarAbout') : undefined

    const drawer = (
        <>
        <Button
            as={IconButton}
            aria-label='Options'
            icon={<HamburgerIcon />}
            onClick={onOpen}
        />
        <Drawer size='2xs' placement={'right'} onClose={onClose} isOpen={isOpen}>
            <DrawerOverlay />
            <DrawerContent color={textColor} bg={symbioseTheme.colors.headerColor}>
            <DrawerCloseButton />
            <DrawerHeader />
            <DrawerBody>
                <Menu>
                    {accountMenu ?
                    <>
                        <MenuItem opacity={menuOpacity} _hover={{opacity: 1, color: menuItemHoverColor}} onClick={() => navigateAndCloseDrawer('/')}>
                            <AiFillHome size={25}/>
                            <Text ml='20px' fontSize={menuFontSize} w='100%'>{t('navbarHome')} </Text>
                        </MenuItem>
                        <MenuDivider />
                        <MenuDivider />
                        <MenuItem opacity={menuOpacity} _hover={{opacity: 1, color: menuItemHoverColor}} onClick={() => navigateAndCloseDrawer('/portfolio')}>
                            <AiFillBank size={25}/>
                            <Text ml='20px' fontSize={menuFontSize} w='100%'>{t('navbarAccountOverview')} </Text>
                        </MenuItem>
                        <MenuDivider />
                        <MenuItem opacity={menuOpacity} _hover={{opacity: 1, color: menuItemHoverColor}} onClick={() => navigateAndCloseDrawer('/marketplace')}>
                            <AiOutlineShoppingCart size={25}/>
                            <Text ml='20px' fontSize={menuFontSize} w='100%'>{t('navbarMarketplace')} </Text>
                        </MenuItem>
                        <MenuDivider />
                        <MenuItem opacity={menuOpacity} _hover={{opacity: 1, color: menuItemHoverColor}} onClick={() => navigateAndCloseDrawer('/invoices')}>
                            <BiReceipt size={25}/>
                            <Text ml='20px' fontSize={menuFontSize} w='100%'>{t('navbarAccountInvoices')} </Text>
                        </MenuItem>
                        <MenuDivider />
                        <MenuItem opacity={menuOpacity} _hover={{opacity: 1, color: menuItemHoverColor}} onClick={() => navigateAndCloseDrawer('/settings')}>
                            <AiFillSetting size={25}/>
                            <Text ml='20px' fontSize={menuFontSize} w='100%'>{t('navbarAccountSettings')} </Text>
                        </MenuItem>
                        <MenuDivider />
                        <MenuDivider />
                        <MenuItem opacity={menuOpacity} _hover={{opacity: 1, color: menuItemHoverColor}} onClick={() => navigateAndCloseDrawer('/blog')}>
                            <FaBlog size={25}/>
                            <Text ml='20px' fontSize={menuFontSize} w='100%'>{t('navbarBlog')} </Text>
                        </MenuItem>
                    </>
                    :
                    <>
                        <MenuItem opacity={menuOpacity} _hover={{opacity: 1, color: menuItemHoverColor}} onClick={() => navigateAndCloseDrawer('/')}>
                            <AiFillHome size={25}/>
                            <Text ml='20px' fontSize={menuFontSize} w='100%'>{t('navbarHome')} </Text>
                        </MenuItem>
                        <MenuDivider />
                        <MenuDivider />
                        <MenuGroup title={aboutHeader} fontSize='lg'>
                            <MenuItem opacity={menuOpacity} _hover={{opacity: 1, color: menuItemHoverColor}} onClick={() => navigateAndCloseDrawer('/about-retail')}>
                                <BiUser size={25}/>
                                <Text ml='20px' fontSize={menuFontSize} w='100%'>{t('navbarAboutRetail')} </Text>
                            </MenuItem> 
                            <MenuItem opacity={menuOpacity} _hover={{opacity: 1, color: menuItemHoverColor}} onClick={() => navigateAndCloseDrawer('/about-business')}>
                                <BsBuilding size={25}/>
                                <Text ml='20px' fontSize={menuFontSize} w='100%'>{t('navbarAboutPro')} </Text>
                            </MenuItem>
                            <MenuItem opacity={menuOpacity} _hover={{opacity: 1, color: menuItemHoverColor}} onClick={() => navigateAndCloseDrawer('/charter')}>
                                <ImEarth size={25}/>
                                <Text ml='20px' fontSize={menuFontSize} w='100%'>{t('navbarAboutCharter')} </Text>
                            </MenuItem>
                        </MenuGroup> 
                        <MenuDivider />
                        <MenuDivider />
                        <MenuItem opacity={menuOpacity} _hover={{opacity: 1, color: menuItemHoverColor}} onClick={() => navigateAndCloseDrawer('/blog')}>
                            <FaBlog size={25}/>
                            <Text ml='20px' fontSize={menuFontSize} w='100%'>{t('navbarBlog')} </Text>
                        </MenuItem>
                    </>
                    }
                </Menu>
            </DrawerBody>
            </DrawerContent>
        </Drawer>
        </>
    )

    const menuList = (
        <Menu>
            <MenuButton
                as={IconButton}
                aria-label='Options'
                icon={<HamburgerIcon />}
                variant='solid'
            />
            <MenuList boxShadow='lg' border='none' color={textColor} bg={symbioseTheme.colors.headerColor}>
                {accountMenu ?
                <>
                    <MenuItem opacity={menuOpacity} _hover={{opacity: 1, color: menuItemHoverColor}} onClick={() => navigateAndCloseDrawer('/')}>
                        <AiFillHome size={25}/>
                        <Text ml='20px' fontSize={menuFontSize} w='100%'>{t('navbarHome')} </Text>
                    </MenuItem>
                    <MenuDivider />
                    <MenuItem opacity={menuOpacity} _hover={{opacity: 1, color: menuItemHoverColor}} onClick={() => navigate('/portfolio')}>
                        <AiFillBank size={25}/>
                        <Text ml='20px' fontSize={menuFontSize} w='100%'>{t('navbarAccountOverview')} </Text>
                    </MenuItem>
                    <MenuDivider />
                    <MenuItem opacity={menuOpacity} _hover={{opacity: 1, color: menuItemHoverColor}} onClick={() => navigate('/marketplace')}>
                        <AiOutlineShoppingCart size={25}/>
                        <Text ml='20px' fontSize={menuFontSize} w='100%'>{t('navbarMarketplace')} </Text>
                    </MenuItem>
                    <MenuDivider />
                    <MenuItem opacity={menuOpacity} _hover={{opacity: 1, color: menuItemHoverColor}} onClick={() => navigate('/invoices')}>
                        <BiReceipt size={25}/>
                        <Text ml='20px' fontSize={menuFontSize} w='100%'>{t('navbarAccountInvoices')} </Text>
                    </MenuItem>
                    <MenuDivider />
                    <MenuItem opacity={menuOpacity} _hover={{opacity: 1, color: menuItemHoverColor}} onClick={() => navigate('/settings')}>
                        <AiFillSetting size={25}/>
                        <Text ml='20px' fontSize={menuFontSize} w='100%'>{t('navbarAccountSettings')} </Text>
                    </MenuItem>
                    <MenuDivider />
                    <MenuItem opacity={menuOpacity} _hover={{opacity: 1, color: menuItemHoverColor}} onClick={() => navigate('/blog')}>
                        <FaBlog size={25}/>
                        <Text ml='20px' fontSize={menuFontSize} w='100%'>{t('navbarBlog')} </Text>
                    </MenuItem>
                </>
                :
                <>
                    <MenuItem opacity={menuOpacity} _hover={{opacity: 1, color: menuItemHoverColor}} onClick={() => navigate('/')}>
                        <AiFillHome size={25}/>
                        <Text ml='20px' fontSize={menuFontSize} w='100%'>{t('navbarHome')} </Text>
                    </MenuItem>
                    <MenuDivider />
                    <MenuGroup title={aboutHeader} fontSize='lg'>
                        <MenuItem opacity={menuOpacity} _hover={{opacity: 1, color: menuItemHoverColor}} onClick={() => navigate('/about-retail')}>
                            <BiUser size={25}/>
                            <Text ml='20px' fontSize={menuFontSize} w='100%'>{t('navbarAboutRetail')} </Text>
                        </MenuItem> 
                        <MenuItem opacity={menuOpacity} _hover={{opacity: 1, color: menuItemHoverColor}} onClick={() => navigate('/about-business')}>
                            <BsBuilding size={25}/>
                            <Text ml='20px' fontSize={menuFontSize} w='100%'>{t('navbarAboutPro')} </Text>
                        </MenuItem>
                        <MenuItem opacity={menuOpacity} _hover={{opacity: 1, color: menuItemHoverColor}} onClick={() => navigate('/charter')}>
                            <ImEarth size={25}/>
                            <Text ml='20px' fontSize={menuFontSize} w='100%'>{t('navbarAboutCharter')} </Text>
                        </MenuItem>
                    </MenuGroup>
                    <MenuDivider />
                    <MenuItem opacity={menuOpacity} _hover={{opacity: 1, color: menuItemHoverColor}} onClick={() => navigate('/blog')}>
                        <FaBlog size={25}/>
                        <Text ml='20px' fontSize={menuFontSize} w='100%'>{t('navbarBlog')} </Text>
                    </MenuItem>
                </>
                }                          
            </MenuList>
        </Menu>
    )

    return isLargerThan350 ? drawer : menuList;
}