import { Box, Flex,Text, Icon, useColorModeValue, Link, useMediaQuery, Button, Stack, InputGroup, InputLeftElement, Input, InputRightElement, InputLeftAddon, background, VStack, Divider, Center, Tooltip, HStack, FormLabel, FormControl, FormHelperText, Table, Tbody, Td, Tr, Image, NumberInput, NumberDecrementStepper, NumberInputField, NumberIncrementStepper } from "@chakra-ui/react";
import { axiosConfig, formatDocumentTitle, formatPrice, getBackgroundComponent, getLanguage, getLanguageEntry, getLanguageValue, isHttpCodeInRange, logout } from "../../../lib/utils";
import { symbioseTheme } from "../../../App";
import { useTranslation } from "react-i18next";

const htmlId = function(id : string) {
  return "PRODUCTCARD-" + id
}

interface Props {
  
  product : any,
  currency : string,
  quantity : {min : number, max : number, default : number},
  canAddProductToCart(productId : string) : boolean
  addToCartFromProductCard(product : any, htmlId : string) : any
}

export default function ProductCard({product, currency, quantity, canAddProductToCart, addToCartFromProductCard} : Props) { 

  const { t, i18n } = useTranslation()
  const [isLargerThan1024] = useMediaQuery('(min-width: 1024px)')
  const [isLargerThan360] = useMediaQuery('(min-width: 360px)')
  const [isTalerThan512] = useMediaQuery('(min-height: 512px)')

  const cardWidth = isLargerThan360 ? '350px' : '256PX'
  const cardHeight = '500px'
  const cardBoxShadow = '3px 3px 8px 1px #454545'
  const cardMargin = '10px 10px 10px 10px'
  const cardTransition = 'transform .2s'
  const cardHover = isLargerThan1024 ? {transform: 'scale(1.02)'} : {}
  const cardBackground = 'white'
  const cardBandBackground = symbioseTheme.colors.textBackgroundColor
  const cardTitleColor = symbioseTheme.colors.headerColor
  const cardBorderColor = cardBandBackground

  const productTitle = getLanguageValue(i18n, product.titles)

  return (
    <Flex flexDir={'column'} w={cardWidth} h={cardHeight} boxShadow={cardBoxShadow} margin={cardMargin} transition={cardTransition} _hover={cardHover} borderColor={cardBorderColor} borderWidth='0px' borderRadius={15}>
      <Box w='100%' h='50px' p={2} bg={cardBandBackground} color={cardTitleColor} borderTopRadius={15}>
        <Text fontWeight='800' fontSize='xl' textAlign='center' overflow={'hidden'} textOverflow='ellipsis' whiteSpace='nowrap'>{productTitle}</Text>
      </Box>   
      <Box w='100%' h='35%' position='relative'>
        <Image 
            w='100%' h='100%'
            display='block'
            objectFit='cover'
            src={product.imageUrl}
            alt={productTitle}
        />
        {/*<Box position='absolute' top={0} right={0} margin={2}>
          <Tooltip label='Icon for flag'>
            <InfoIcon boxSize={25} /> 
          </Tooltip>
        </Box>*/}
      </Box>
      
      <Flex flexDirection={'column'} flexGrow={1} bg={cardBackground} padding='2px 2px 0px 2px' alignItems="center" justifyContent="center" textAlign="center">
        <Box h='10px'/>
        <Text w='100%' px='10px' textAlign={'left'}>{getLanguageValue(i18n, product.descriptions)}</Text>
        <Box w='100%' flexGrow={1}/>
        <VStack spacing={3} >
          <Text w='100%' textAlign={'center'} fontWeight='xl'>{formatPrice(currency, product.prices[currency].fractionAmount)}</Text>
          <Box w='70%' alignItems="center" justifyContent="center" textAlign="center" >
            <NumberInput size='md' defaultValue={quantity.default} min={quantity.min} max={quantity.max}>
              <HStack>
                <NumberDecrementStepper border='none'>
                    <Button >-</Button>
                </NumberDecrementStepper>
                <NumberInputField id={htmlId(product.id + "-quantity")} textAlign='center' border='none' margin='none' p='0 0 0 0' minW='80px'/>
                <NumberIncrementStepper border='none'>
                    <Button>+</Button>
                </NumberIncrementStepper>
              </HStack>
            </NumberInput>
          </Box>
          <Button variant={'solid'} disabled={!canAddProductToCart(product.id)} onClick={() => {addToCartFromProductCard(product, htmlId(product.id + "-quantity"))}}>{t('marketplaceAddToCart')}</Button>
        </VStack>                    
        <Box h='10px'/>
        <Text w='100%' fontSize='xs' opacity={0.7} textAlign='left' p='0px 2px 0px 5px'>REF: {product.id}</Text>
      </Flex>
    </Flex>
  )
}