import { Box, Flex,Text, Icon, useColorModeValue, Link, useMediaQuery, Button, Stack, InputGroup, InputLeftElement, Input, InputRightElement, InputLeftAddon, background, VStack, Divider, Center, Tooltip, HStack, FormLabel, FormControl, FormHelperText, Table, Tbody, Td, Tr, Image, NumberInput, NumberDecrementStepper, NumberInputField, NumberIncrementStepper } from "@chakra-ui/react";
import { axiosConfig, formatDocumentTitle, formatPrice, getBackgroundComponent, getLanguage, getLanguageEntry, getLanguageValue, isHttpCodeInRange, logout } from "../../../lib/utils";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { symbioseTheme } from "../../../App";
import {ReactComponent as symbioseSvg} from '../../../img/logo/main/SYMBIOSE_MANAGEMENT_Black.svg';


const htmlId = function(id : string) {
  return "ARTICLEPREVIEWCARD-" + id
}

interface ArticlePreview {  
  content : any,
  aliases : [string],
  writer : string,
  creationTime : string,
  updateTime : string

}

interface Props {  
  articlePreview : ArticlePreview
}

export default function PreviewCard({ articlePreview} : Props) { 

  const { t, i18n } = useTranslation()
  const [isLargerThan1024] = useMediaQuery('(min-width: 1024px)')
  const [isLargerThan512] = useMediaQuery('(min-width: 512px)')
  const [isLargerThan360] = useMediaQuery('(min-width: 360px)')
  

  const cardWidth = isLargerThan360 ? '347px' : '90%'
  const cardHeight = '440px'
  const cardBoxShadow = '3px 3px 8px 1px #454545'
  const cardMargin = '10px 10px 10px 10px'
  const cardTransition = 'transform .2s'
  const cardHover = isLargerThan1024 ? {transform: 'scale(1.02)'} : {}
  const cardBackground = 'white'
  const cardBandBackground = symbioseTheme.colors.textBackgroundColor
  const cardTitleColor = symbioseTheme.colors.headerColor
  const cardBorderColor = symbioseTheme.colors.textBackgroundColor //cardBandBackground
  const cardBorderRadius = 16

  const imageBoxSize = isLargerThan512 ? '200px' : '100px'

  const imageHover = {transform: 'scale(1.20)'}
  const imageTransition = 'transform .5s;'

  const titleFontWeight = isLargerThan512 ? 'bold' : 'normal'

  const titleFontSize = isLargerThan360 ? '2xl' : 'md'
  const metadataFontSize = isLargerThan360 ? 'sm' : 'xs'
  const summaryFontSize = isLargerThan360 ? 'md' : 'sm'



  return (
    <a href={'/blog/post/'+articlePreview.aliases[0]} >
      <Flex flexDir={'column'} w={cardWidth} h={cardHeight} margin={cardMargin} bg={cardBorderColor}      
        boxShadow={cardBoxShadow}  /*transition={cardTransition} _hover={cardHover}*/ 
        borderColor={cardBorderColor} borderWidth='0px'  borderRadius={cardBorderRadius} cursor={'pointer'}>
        <Box w='100%' h='35%' minH='35%' position='relative' overflow={'hidden'} borderTopRadius={cardBorderRadius} bg={symbioseTheme.colors.headerColor}> 
          {articlePreview.content.image ?
            <Image 
                w='100%' h='100%'
                display='block'
                objectFit='cover'
                //_hover={imageHover}
                transition={imageTransition}
                src={articlePreview.content.image}
                alt={articlePreview.content.title}
            />
          :
          <Icon as={symbioseSvg} w='100%' h='100%' display='block' objectFit='cover'/>
          }
          {/*<Box position='absolute' top={0} right={0} margin={2}>
            <Tooltip label='Icon for flag'>
              <InfoIcon boxSize={25} /> 
            </Tooltip>
          </Box>*/}
        </Box>
        
        <Flex flexDirection={'column'} flexGrow={1} h='65%' minH='65%' padding='2px 5% 0px 5%'  bg={cardBackground} 
          borderBottomRadius={cardBorderRadius}        
          overflow={'hidden'} alignItems="center" justifyContent="center" textAlign="center" >
          <Box h='10px'/>
          <Text w='100%' maxW="100%" px='10px' minH={'20%'} maxH={'40%'} fontSize={titleFontSize} fontWeight={titleFontWeight} textAlign={'left'} 
            style={{overflow: 'hidden', display: '-webkit-box', WebkitBoxOrient: 'vertical', WebkitLineClamp: 3}}>
            {articlePreview.content.title}
          </Text>
          <Box w='100%' my={2}>
            <Text float='left' bg='#F7F7F7' borderRadius={20} px={2} marginRight={2} fontSize={metadataFontSize}>{articlePreview.writer}</Text>
            <Text float='left' bg='#F7F7F7' borderRadius={20} px={2} marginLeft={2} fontSize={metadataFontSize}>
              {new Date((articlePreview.creationTime as string).replace('[UTC]','')).toLocaleDateString(i18n.language, {year:"numeric", month:"short", day:"numeric"} as Intl.DateTimeFormatOptions)}          
            </Text>
          </Box>
          <Box h='5px'/>
          <Text w='100%' textAlign={'left'} fontSize={summaryFontSize}>{articlePreview.content.summary}</Text>
          <Box flexGrow={1} />
        </Flex>
      </Flex>
    </a>
  )
}