import { Box, HStack, Spinner, Text, VStack } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { symbioseTheme } from "../../App";

interface Props {
  message? : string | null,
  _color? : string,
  _bg? : string
}

function Loading ({message = null, _bg, _color} : Props) {
  const { t, i18n } = useTranslation()

  return (
    <VStack h='100%' w='100%' alignItems="center" justifyContent="center" textAlign="center" bg={_bg} color={_color}>
      <Spinner
        thickness='4px'
        speed='0.65s'
        emptyColor='gray.200'
        color={symbioseTheme.colors.textBackgroundColor}
        size='xl'
      />
      <Box h='20px'/>
      <Text>{message ? message : t('loading')}</Text>
    </VStack>
  )
}

export default Loading;