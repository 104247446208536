import { ReactNode, useEffect, useState } from "react";
import { Box, Flex,Text, Icon, useColorModeValue, Link, useMediaQuery, Button, Stack, InputGroup, InputLeftElement, Input, InputRightElement, InputLeftAddon, background, VStack, Divider, Center, Tooltip, HStack, FormLabel, FormControl, FormHelperText, Tbody, Th, Tr, Thead, Table, Td, useToast } from "@chakra-ui/react";
import { CheckIcon, EmailIcon, LockIcon, PhoneIcon, QuestionOutlineIcon } from "@chakra-ui/icons";
import { axiosConfig, checkAuth, errorToast, formatDocumentTitle, getBackgroundComponent, isHttpCodeInRange, logout, parseAxiosError } from "../../../../lib/utils";
import { symbioseTheme } from "../../../../App";
import axios from "axios";
import Cookies from 'universal-cookie';
import { URL_CUSTODIAN_GET_PORTFOLIO, URL_ACCOUNT_IS_LOGGED_IN, URL_ACCOUNT_LOGIN } from "../../../../constants";
import { useNavigate } from "react-router-dom";
import Header from "../../../common/Header";
import { Chart } from "react-google-charts";
import PortfolioTreeCard from "./PortfolioTreeCard";
import { GiConsoleController } from "react-icons/gi";
import { useTranslation } from "react-i18next";
import Loading from "../../../common/Loading";
import './Portfolio.css'

const htmlId = function(id : string) {
  return "PORTFOLIO-" + id
}

const CURRENCY_VALUATION = 'EUR'

export default function Portfolio() {
  
  const navigate = useNavigate()
  const cookies = new Cookies()
  const { t, i18n } = useTranslation()
  const [isLargerThan1024] = useMediaQuery('(min-width: 1024px)')
  const [isLargerThan512] = useMediaQuery('(min-width: 512px)')
  const toast = useToast()

  const [portfolio, setPortfolio] = useState<{summary: any, species: any [], chartData: any } | null>(null)
    
  document.title = formatDocumentTitle(t('portfolioPageTitle'))

  const loadPortfolio = async () => {
    try {
      const account = cookies.get('account')
      const response = await axios.post(URL_CUSTODIAN_GET_PORTFOLIO, {currencyValuation: CURRENCY_VALUATION}, axiosConfig(account.token, i18n))
      if(response.status == 401) {
        logout(cookies, navigate)
        return
      }

      if(!isHttpCodeInRange(200, response.status))
        throw parseAxiosError(response)
      
      const keys = Object.keys(response.data['trees'])
      const treeBuffer : any [] = []
      const chartData : any []  = []
      chartData.push([t('portfolioSpecies'), t('portfolioQuantity')])

      for(let i=0;i<keys.length;i++) {
        const t : any = response.data['trees'][keys[i]]
        treeBuffer.push(t)
        chartData.push([t.species, t.count])
      }
      
      setPortfolio({summary: response.data.treeSummary, species: treeBuffer, chartData: chartData})
      
    } catch(error) {
      console.error(error)
      toast(errorToast(null, typeof error == 'string' ? error : t('genericError')))
      setPortfolio(null)
    }
  }

  useEffect(() => {
    const load = async () => {
      const auth = await checkAuth(cookies, navigate);
      if(auth.error)
        toast(errorToast(null, t('genericError')))
      else if (auth.auth)
        await loadPortfolio()
    }
    load()
  }, [])
 
  const tableDividerColor = symbioseTheme.colors.headerTextColor
  const containerWidth = isLargerThan1024 ? '60%' : '80%'
  const containerColor = symbioseTheme.colors.headerTextColor
  const containerBg = symbioseTheme.colors.headerColor
  const cardHeaderColor = symbioseTheme.colors.textColor
  const cardHeaderBg = symbioseTheme.colors.textBackgroundColor
  const headerMarginY = isLargerThan1024 ? '100px' : '40px'
  const summaryTableWidth = isLargerThan1024 ? '40%' : (isLargerThan512 ? '450px' : '95%')
  const summaryChartWidth = isLargerThan1024 ? '60%' : (isLargerThan512 ? '450px' : '95%')

  const summarryContent = () => {
    if(!portfolio)
      throw "Portfolio not set"
    return (
          <>
          <Box w={summaryTableWidth} maxW={summaryTableWidth}>
            <Table >
              <Tbody>
              <Tr borderColor={tableDividerColor}>
                <Td borderColor={tableDividerColor}>{t('portfolioTreeCount')}</Td><Td borderColor={tableDividerColor}>{portfolio.summary.count}</Td>
              </Tr>
              <Tr borderColor={tableDividerColor}>
                <Td borderColor={tableDividerColor}>{t('portfolioCO2Captured')}</Td><Td borderColor={tableDividerColor}>{(portfolio.summary.totalBiometrics.co2CapturedKg.population > 0 ? (portfolio.summary.totalBiometrics.co2CaptureKg.biometric.value + " KG") : "--")}</Td>
              </Tr>
              <Tr borderColor={tableDividerColor} fontWeight='bold'>
                <Td borderColor={tableDividerColor}>{t('portfolioValue')}</Td><Td borderColor={tableDividerColor}>{(portfolio.summary.totalValuation.population > 0 ? (portfolio.summary.totalValuation.valuation.prices[CURRENCY_VALUATION] + CURRENCY_VALUATION) : "--")}</Td>
              </Tr>
              </Tbody>
            </Table>
          </Box>
          <Box flexGrow={1} minWidth={10}/>
          <Box w={summaryTableWidth} maxW={summaryChartWidth} h='300px'>
            <Chart
              chartType="PieChart"
              data={portfolio.chartData}
              options={{backgroundColor: "transparent", legend: "none", is3D: false}}
              width={"100%"}
              height={"100%"}
            />
          </Box>
          </>
          )
  }



  return (
    <>
    {getBackgroundComponent()}
    <Header />
    {portfolio ?
      <Flex flexDirection="column" alignItems="center" justifyContent="center" textAlign="center" color={containerColor} bg={containerBg}>
        <VStack w={containerWidth} >
          <Text size="xl" fontSize='2xl' my={headerMarginY} mx='10px'> {t('portfolioHeaderTitle')} <b>{cookies.get('account').id}</b></Text>
            {isLargerThan512 ? 
            <>              
              <HStack spacing={0} w='100%'>
                {summarryContent()}
              </HStack>
              <Box h='50px'/>
              <Table variant='striped' colorScheme={tableDividerColor}>
                <Thead>
                    <Tr>
                      <Th textAlign='center'>{t('portfolioSpecies')}</Th>
                      <Th textAlign='center'>{t('portfolioQuantity')}</Th>
                      <Th textAlign='center'>{t('portfolioCO2Captured')}</Th>
                      <Th textAlign='center'>{t('portfolioValue')}</Th>
                    </Tr>
                </Thead>
                <Tbody>
                  {portfolio.species.map((o, i) => {
                    return (
                      <Tr key={i}>
                        <Td textAlign='center'>{o.species}</Td>
                        <Td textAlign='center'>{o.count}</Td>
                        <Td textAlign='center'>{o.totalBiometrics.co2CapturedKg.population > 0 ? o.totalBiometrics.co2CapturedKg.biometric.value : "--" }</Td>
                        <Td textAlign='center'>{o.totalValuation.population > 0 ? o.totalValuation.prices[CURRENCY_VALUATION] : "--"}</Td>
                      </Tr>
                    )
                  })}               
                </Tbody>
              </Table>
            </>
            :
            <>
              <VStack w='100%' spacing={0}>
                {summarryContent()}
              </VStack>
              <Box h='40px'/>
              {portfolio.species.map((o, i) => (
                <>
                  <PortfolioTreeCard key={i} tree={o} currency={CURRENCY_VALUATION} _color={containerColor} _bg={containerBg} headerColor={cardHeaderColor} headerBg={cardHeaderBg} />
                  <Box h='3px'/>
                </>
              ))}
            </>
            }            
        </VStack>
        <Box h='40px' />
      </Flex>
    :
      <Loading _color={containerColor} _bg={containerBg}/>
    }
    
    </>
  )
}