import { ReactNode, useEffect } from "react";
import { Box, Flex, Text, Icon, useColorModeValue, Image, VStack, HStack, Link, useColorMode, Button, useMediaQuery, useToast } from "@chakra-ui/react";
import Header from "../common/Header";
import { formatDocumentTitle, getBackgroundComponent, getHomeBackgroundComponent, successToast } from "../../lib/utils";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import { useTranslation } from "react-i18next";

export default function ContactVerified() {
  
   const navigate = useNavigate()
  const cookies = new Cookies()
  const { t, i18n } = useTranslation()
  const { colorMode } = useColorMode()
  const [isLargerThan1024] = useMediaQuery('(min-width: 1024px)')
  const toast = useToast()

  document.title = formatDocumentTitle(t('loginPageTitle'))

  useEffect(() => {
    toast(successToast(t('contactVerifiedSuccessToast'), null, 'top'))
    navigate('/')    
  })


  return (
    <>
      {getHomeBackgroundComponent()}
      <Header transparentBackground={true} />
    </>
  )
}