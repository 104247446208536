import { ReactNode, useEffect, useState } from "react";
import { Box, Flex,Text, Icon, useColorModeValue, Link, useMediaQuery, Button, Stack, InputGroup, InputLeftElement, Input, InputRightElement, InputLeftAddon, background, VStack, Divider, Center, Tooltip, HStack, FormLabel, FormControl, FormHelperText, Table, Tbody, Td, Tr, Image, NumberInput, NumberDecrementStepper, NumberInputField, NumberIncrementStepper, SimpleGrid, Switch, useToast, useDisclosure } from "@chakra-ui/react";
import { CheckIcon, EmailIcon, InfoIcon, LockIcon, PhoneIcon, QuestionOutlineIcon, ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import { axiosConfig, errorToast, formatDocumentTitle, formatPrice, getBackgroundComponent, getLanguage, getLanguageEntry, getLanguageValue, is2FARequired, isHttpCodeInRange, parseAxiosError, parsePinInput, string2NullIfEmpty, successToast, tryClearInput } from "../../../../lib/utils";
import { symbioseTheme } from "../../../../App";
import axios from "axios";
import Cookies from 'universal-cookie';
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { AiOutlineLock } from "react-icons/ai";
import { IconType } from "react-icons";
import SigninPassword from "../../login/SigninPassword";
import SignupPasswords from "../../login/SignupPasswords";
import BeatLoader from "react-spinners/BeatLoader";
import { URL_ACCOUNT_2FA, URL_ACCOUNT_UPDATE } from "../../../../constants";
import OTA from "../../../common/OTA";
import QRCode from "react-qr-code";
import TFA from "../../../common/TFA";
import TFAModal from "../../../common/TFAModal";

const htmlId = function(id : string) {
  return "SETTINGUSER-" + id
}

const OTA_ID = htmlId("ota")
const FIRSTNAME_ID = htmlId("firstname")
const LASTNAME_ID = htmlId("lastname")
const NICKNAME_ID = htmlId("nickname")

interface Props {
  accountInfo : any,
  refreshAccountInfo() : any,
  _color? : string,
  _bg? : string
}

export default function User({accountInfo, refreshAccountInfo, _color, _bg} : Props) { 

  const navigate = useNavigate()
  const cookies = new Cookies()
  const { t, i18n } = useTranslation()
  const [isLargerThan1024] = useMediaQuery('(min-width: 1024px)')
  const [isTalerThan512] = useMediaQuery('(min-height: 512px)')
  const toast = useToast()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [processing, setProcessing] = useState(false)
  const [otaCoolDown, setOtaCoolDown] = useState<number | null>(null)

  
  const updateUser = async function(tfa : string | null = null) {
    const account = cookies.get('account')
    const firstName = document.getElementById(FIRSTNAME_ID) as HTMLInputElement
    const lastName = document.getElementById(LASTNAME_ID) as HTMLInputElement
    const nickName = document.getElementById(NICKNAME_ID) as HTMLInputElement
    setProcessing(true)
    try {      
    

      const ota = document.getElementById(OTA_ID) as HTMLInputElement
      const otaInput =  string2NullIfEmpty(ota?.value)
      if(!otaInput) {
        toast(errorToast(null, t('otaTokenMissing')))
        return
      }

      const response = await axios.post(URL_ACCOUNT_UPDATE, 
        {
          identifier: {accountId: account?.id},
          auth: {
            ota: otaInput,
            twoFactor: tfa
          },
          updates: {
            contact: {
              fullName: {
                given: firstName.value,
                family: lastName.value
              }
            },
            socials: {
              username: {
                handle: nickName.value
              }
            }
          }
        },
        axiosConfig(account?.token, i18n)
      )

      if(is2FARequired(response)) {
        onOpen()
      } else if(response.status == 401) {
        tryClearInput(OTA_ID)
        if(response.data.error)
          toast(errorToast(null, response.data.error))
        else {
          toast(errorToast(null, t('genericError')))
          navigate('/')
          return
        }
      } else if(!isHttpCodeInRange(200, response.status)){
        tryClearInput(OTA_ID)
        throw parseAxiosError(response)
      } else {
        toast(successToast(null, t('settingsDataUpdateToastSuccess')))
        tryClearInput(OTA_ID)
        refreshAccountInfo()  
      }
      
    } catch(error) {
      console.error(error)
      toast(errorToast(null, typeof error == 'string' ? error : t('genericError')))
    } finally {
      setProcessing(false)
    }
  }


  useEffect(() => {
   
  }, [])

  const containerWidth = isLargerThan1024 ? '90%' : '95%'
  const spaceBox = <Box h='25px'/>
  const titleFontSize = 'md'
  const titleFontWeight = 'bold'
  const labelFontSize = 'sm'
  const labelMargin = '0 0 0 0'

  return (
    <VStack w={containerWidth}>
      <TFAModal isOpen={isOpen} onClose={onClose} on2FA={(tfa) => updateUser(tfa)}/>
      <FormControl>
        <FormLabel fontSize={titleFontSize} fontWeight={titleFontWeight} margin={labelMargin} textAlign='center'><u>{t('settingsUpdateUserTitle')}</u></FormLabel>
        {spaceBox}
        <FormControl border='none' >
          <FormLabel fontSize={labelFontSize} margin={labelMargin}><u>{t('loginSignUpFirstName')}</u></FormLabel>
          <Input id={FIRSTNAME_ID} variant='flushed'textAlign='center' defaultValue={accountInfo.contact.fullName.given} />
        </FormControl>
        {spaceBox}
        <FormControl border='none'>
          <FormLabel fontSize={labelFontSize} margin={labelMargin}><u>{t('loginSignUpLastName')}</u></FormLabel>
          <Input id={LASTNAME_ID} variant='flushed' textAlign='center' defaultValue={accountInfo.contact.fullName.family} />
        </FormControl>
        {spaceBox}
        <FormControl border='none'>
          <FormLabel fontSize={labelFontSize} margin={labelMargin}><u>{t('loginSignUpUsername')}</u></FormLabel>
          <Input id={NICKNAME_ID} variant='flushed' textAlign='center' defaultValue={accountInfo.socials.username.handle} />
          <FormHelperText fontStyle='italic' fontSize='sm' opacity={0.5} >{t('loginSignUpNickNameInfo')}</FormHelperText>
        </FormControl> 
        {spaceBox}  
        <VStack spacing={3}>
          <OTA emailAddress={accountInfo.contact.email.address} otaId={OTA_ID} globalCoolDown={{value: otaCoolDown, setValue: setOtaCoolDown}}  _color={_color} />
          <Button isLoading={processing} disabled={processing} spinner={<BeatLoader size={8} color={symbioseTheme.colors.darkGreen} />} onClick={() => updateUser()}>{t('settingsValidateButton')}</Button>
        </VStack>                   
      </FormControl>
      <Box h='30px' /*Scrollbar on all settings*/ />
    </VStack>
  )
}