import { ReactNode, useEffect, useState } from "react";
import { Box, Flex,Text, Icon, useColorModeValue, Link, useMediaQuery, Button, Stack, InputGroup, InputLeftElement, Input, InputRightElement, InputLeftAddon, background, VStack, Divider, Center, Tooltip, HStack, FormLabel, FormControl, FormHelperText, Tbody, Th, Tr, Thead, Table, Td, useToast, Image, useNumberInput, NumberInput, NumberInputField, NumberInputStepper, NumberDecrementStepper, NumberIncrementStepper, Popover, PopoverTrigger, PopoverHeader, PopoverContent, PopoverArrow, PopoverCloseButton, PopoverBody, TableContainer, Accordion, AccordionItem, AccordionButton, AccordionIcon, AccordionPanel } from "@chakra-ui/react";
import { CheckIcon, EmailIcon, InfoIcon, LockIcon, PhoneIcon, QuestionOutlineIcon, ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import { axiosConfig, checkAuth, errorToast, formatDocumentTitle, formatPrice, getBackgroundComponent, getLanguage, getLanguageEntry, getLanguageValue, isHttpCodeInRange, logout, parseAxiosError } from "../../../../lib/utils";
import axios from "axios";
import Cookies from 'universal-cookie';
import { URL_ACCOUNT_INFO, URL_ACCOUNT_INVOICES } from "../../../../constants";
import { useNavigate, useSearchParams } from "react-router-dom";
import { GiConsoleController, GiShoppingCart } from "react-icons/gi";
import { useTranslation } from "react-i18next";
import { AiOutlineDelete, AiOutlineLock, AiOutlineShoppingCart } from "react-icons/ai";
import Header from "../../../common/Header";
import Message from "../../../common/Message";
import Loading from "../../../common/Loading";
import { symbioseTheme } from "../../../../App";
import SettingCard from "./SettingCard";
import { BsFillShieldLockFill } from "react-icons/bs";
import { RiUser2Fill, RiUser3Fill, RiUserFill } from "react-icons/ri";
import { MdEmail, MdGroups } from "react-icons/md";
import Security from "./Security";
import User from "./User";
import Contact from "./Contact";
import Socials from "./Socials";


const htmlId = function(id : string) {
  return "SETTINGS-" + id
}

enum SettingsDisplay {
  Security,
  User,
  Contact,
  Socials,
}

export default function Settings() {
  
  const navigate = useNavigate()
  const cookies = new Cookies()
  const { t, i18n } = useTranslation()
  const [isLargerThan1024] = useMediaQuery('(min-width: 1024px)')
  const [isTalerThan512] = useMediaQuery('(min-height: 512px)')
  const toast = useToast()
  const [accountInfo, setAccountInfo] = useState<any | null>(null)
  const [settingDisplayEnum, setSettingDisplayEnum] = useState<SettingsDisplay | null>(null)
  const [settingDisplay, setSettingDisplay] = useState<any | null>(null)
 
 
  document.title = formatDocumentTitle(t('settingsPageTitle'))
  

  const containerWidth = isLargerThan1024 ? "60%" : "95%"
  const containerColor = symbioseTheme.colors.headerTextColor
  const containerBg = symbioseTheme.colors.headerColor
  const buttonColor = symbioseTheme.colors.textColor
  const buttonBg = symbioseTheme.colors.textBackgroundColor
  const buttonOpacity = 0.8
  const buttonSelectedOpacity = 1
  const buttonHoverOpacity = 1
  const headerMarginTop = isLargerThan1024 ? '30px' : '15px'


  const loadAccountInfo = async () => {
    try {
             
      const account = cookies.get('account')
     
      const response = await axios.post(URL_ACCOUNT_INFO, {}, axiosConfig(account.token, i18n))
      if(response.status == 401) {
        logout(cookies, navigate)
        return
      }

      if(!isHttpCodeInRange(200, response.status)) 
        throw parseAxiosError(response)
      
      setAccountInfo(response.data)
      if(!settingDisplay) {
        setSettingDisplayEnum(SettingsDisplay.Security)
        setSettingDisplay(<Security refreshAccountInfo={loadAccountInfo} accountInfo={response.data} _color={containerColor} _bg={containerBg} />)
      }
     
    } catch(error) {
      console.error(error)
      toast(errorToast(null, typeof error == 'string' ? error : t('genericError')))
    }
  }  


  const securityDisplay = <Security _color={containerColor} _bg={containerBg} refreshAccountInfo={loadAccountInfo} accountInfo={accountInfo} />
  const userDisplay = <User _color={containerColor} _bg={containerBg} refreshAccountInfo={loadAccountInfo} accountInfo={accountInfo} />
  const contactDisplay = <Contact _color={containerColor} _bg={containerBg} refreshAccountInfo={loadAccountInfo} accountInfo={accountInfo} />
  const socialsDisplay = <Socials _color={containerColor} _bg={containerBg} refreshAccountInfo={loadAccountInfo} accountInfo={accountInfo} />


  const setDisplay = function(display : SettingsDisplay) {
    setSettingDisplayEnum(display)
    switch(display) {
      case SettingsDisplay.Security:
        setSettingDisplay(securityDisplay)
        break
      case SettingsDisplay.User:
        setSettingDisplay(userDisplay)
        break
      case SettingsDisplay.Contact:
        setSettingDisplay(contactDisplay)
        break
      case SettingsDisplay.Socials:
        setSettingDisplay(socialsDisplay)
        break
    }
  }

  useEffect(() => {
    const load = async () => {
      const auth = await checkAuth(cookies, navigate);
      if(auth.error)
        toast(errorToast(null, t('genericError')))
      else if (auth.auth) {
          await loadAccountInfo()
      }
    }
    load()
  }, [])
   
  
  return (
    <>
    {getBackgroundComponent()}
    <Header />
    {accountInfo ? 

    <Flex flexDirection="column" w='100%' alignItems="center" justifyContent="center" textAlign="center" color={containerColor} bg={containerBg} >
      <Text size="xl" fontSize='2xl' mt={headerMarginTop} mx='10px'> {t('settingsPageTitle')}</Text>
      <Flex flexDirection='row' maxW={containerWidth} flexWrap='wrap' alignItems="center" justifyContent="center" textAlign="center" mt='40px'>
        <SettingCard icon={()=> <BsFillShieldLockFill size={35}/>} title={t('settingsCardSecurity')} info={t('settingsCardSecurityInfo')} 
          onClick={()=> setDisplay(SettingsDisplay.Security)} 
          _color={buttonColor}
          _bg={buttonBg}
          _opacity={settingDisplayEnum == null || settingDisplayEnum == SettingsDisplay.Security ? buttonSelectedOpacity : buttonOpacity}
          _hoverOpacity={buttonHoverOpacity}
        />
        <SettingCard icon={()=> <RiUser3Fill size={35}/>} title={t('settingsCardUser')} info={t('settingsCardUserInfo')}
          onClick={()=> setDisplay(SettingsDisplay.User)} 
          _color={buttonColor}
          _bg={buttonBg}
          _opacity={settingDisplayEnum == SettingsDisplay.User ? buttonSelectedOpacity : buttonOpacity}
          _hoverOpacity={buttonHoverOpacity}
        />
        <SettingCard icon={()=> <MdEmail size={35}/>} title={t('settingsCardContact')} info={t('settingsCardContactInfo')}
          onClick={()=> setDisplay(SettingsDisplay.Contact)}
          _color={buttonColor}
          _bg={buttonBg}
          _opacity={settingDisplayEnum == SettingsDisplay.Contact ? buttonSelectedOpacity : buttonOpacity}
          _hoverOpacity={buttonHoverOpacity}
        />
        <SettingCard icon={()=> <MdGroups size={35}/>} title={t('settingsCardSocials')} info={t('settingsCardSocialsInfo')}
          onClick={()=> setDisplay(SettingsDisplay.Socials)} 
          _color={buttonColor}
          _bg={buttonBg}
          _opacity={settingDisplayEnum == SettingsDisplay.Socials ? buttonSelectedOpacity : buttonOpacity}
          _hoverOpacity={buttonHoverOpacity}
        />
      </Flex>
      <VStack maxW={containerWidth}>
        <Box h='50px'/>
        {settingDisplay}
      </VStack>
      <Box h='40px'/>
    </Flex>
    :
    <Loading message={t('settingsLoading')} _color={containerColor} _bg={containerBg} />
    }
    
    </>
  )
}