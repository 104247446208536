import { ReactNode, useEffect, useState } from "react";
import { Box, Flex,Text, Icon, useColorModeValue, Link, useMediaQuery, Button, Stack, InputGroup, InputLeftElement, InputRightElement, InputLeftAddon, background, VStack, Divider, Center, Tooltip, HStack, FormLabel, FormControl, FormHelperText, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, useDisclosure, Input, useToast, Tabs, TabList, Tab, TabPanels, TabPanel } from "@chakra-ui/react";
import { CheckIcon, EmailIcon, LockIcon, PhoneIcon, QuestionOutlineIcon, ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import { axiosConfig, errorToast, formatDocumentTitle, getBackgroundComponent, is2FARequired, isHttpCodeInRange, parseAxiosError, string2NullIfEmpty, successToast } from "../../../lib/utils";
import { symbioseTheme } from "../../../App";
import axios from "axios";
import Cookies from 'universal-cookie';
import ReCAPTCHA from "react-google-recaptcha";
import { URL_ACCOUNT_LOGIN, URL_ACCOUNT_REGISTER } from "../../../constants";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Footer from "../../common/Footer";
import SigninPassword from "./SigninPassword";
import SignupPasswords from "./SignupPasswords";
import Email from "../../common/Email";
import BeatLoader from "react-spinners/BeatLoader";
import { AiOutlineArrowRight } from "react-icons/ai";
import { MdArrowBack, MdArrowForward } from "react-icons/md";
import TFAModal from "../../common/TFAModal";
import OTA from "../../common/OTA";

const htmlId = function(id : string) {
  return "LOGIN-" + id
}


const SIGNIN_EMAIL_ID = htmlId("signin-email")
const SIGNIN_PWD_ID = htmlId("signin-pwd")
const SIGNIN_OTA_ID = htmlId("signin-ota")
const SIGNIN_BUTTON_ID = htmlId("signin-button")


const SIGNUP_FIRSTNAME_ID = htmlId("signup-firstname")
const SIGNUP_LASTNAME_ID = htmlId("signup-lastname")
const SIGNUP_NICKNAME_ID = htmlId("signup-nickname")
const SIGNUP_EMAIL_ID = htmlId("signup-email")
const SIGNUP_PWD_ID = htmlId("signup-pwd")
const SIGNUP_PWD2_ID = htmlId("signup-pwd-2")
const SIGNUP_REFERRAL_ID = htmlId("signup-referral")
const SIGNUP_BUTTON_ID = htmlId("signup-button")

export default function Login() {

  const navigate = useNavigate()
  const cookies = new Cookies()
  const { t, i18n } = useTranslation()
  const [isLargerThan1024] = useMediaQuery('(min-width: 1024px)')
  const [isLargerThan512] = useMediaQuery('(min-width: 512px)')
  const toast = useToast()
  const { isOpen, onOpen, onClose } = useDisclosure()
  
  const [processing, setProcessing] = useState(false)
  const [reCaptcha, setReCaptcha] = useState(false)

  
  document.title = formatDocumentTitle(t('loginPageTitle'))

  useEffect(() => {
    if(cookies.get('account'))
      navigate('/')
  }, [])

 
  const login = async function(tfa : string | null = null) {

    const email = string2NullIfEmpty((document.getElementById(SIGNIN_EMAIL_ID) as HTMLInputElement)?.value)
    const pwd = string2NullIfEmpty((document.getElementById(SIGNIN_PWD_ID) as HTMLInputElement)?.value)
    const ota = string2NullIfEmpty((document.getElementById(SIGNIN_OTA_ID) as HTMLInputElement)?.value)

    setProcessing(true)
    try {

      if(!email || email.trim().length <= 0
        || ((!pwd || pwd.trim().length <= 0) && (!ota || ota.trim().length <= 0))) {
        toast(errorToast(null, t('loginInvalidCredentials')))
        return
      }

      const response = await axios.post(URL_ACCOUNT_LOGIN, {
        identifier: {email: email},
        auth: {pwd: pwd, ota: ota, twoFactor: tfa}
      }, axiosConfig(null, i18n))
      
      if(is2FARequired(response)) {
        onOpen()
      } else if(response.status == 401) {
        if("[ResetPassword]" in response.data)
          navigate('/reset-pwd')
        toast(errorToast(null, t('loginInvalidCredentials')))
      } else if(!isHttpCodeInRange(200, response.status)) 
        throw parseAxiosError(response)
      else {
        toast(successToast(t('loginSignInSuccessToast')))
        cookies.set('account', {id: response.data.accountId, token: response.data.token})
        navigate('/')
      }
    } catch(error) {
      console.error(error)
      toast(errorToast(null, typeof error == 'string' ? error : t('genericError')))
    } finally {
      setProcessing(false)
    }
  }

  const register = async function() {
    
    const firstname = document.getElementById(SIGNUP_FIRSTNAME_ID) as HTMLInputElement
    const lastname = document.getElementById(SIGNUP_LASTNAME_ID) as HTMLInputElement
    const username = document.getElementById(SIGNUP_NICKNAME_ID) as HTMLInputElement
    const email = document.getElementById(SIGNUP_EMAIL_ID) as HTMLInputElement
    const pwd = document.getElementById(SIGNUP_PWD_ID) as HTMLInputElement
    const pwd2 = document.getElementById(SIGNUP_PWD2_ID) as HTMLInputElement
    const referralCode = document.getElementById(SIGNUP_REFERRAL_ID) as HTMLInputElement

    setProcessing(true)
    try {
      if(!firstname.value || firstname.value.trim().length <= 0
        || !lastname.value || lastname.value.trim().length <= 0
        || !username.value || username.value.trim().length <= 0) {
        toast(errorToast(null, t('loginSignUpMissingRequiredFields')))
        return
      }

      if(!pwd.value || pwd.value.trim().length <= 0
        || !email.value || email.value.trim().length <= 0) {
        toast(errorToast(null, t('loginSignUpMissingFields')))
        return
      }

      if(pwd.value != pwd2.value) {
        toast(errorToast(null, t('loginSignUpPasswordMismatch')))
        return
      }

      const response = await axios.post(URL_ACCOUNT_REGISTER, {
        referee: referralCode.value.trim().length == 0 ? null : referralCode.value,
        type: "Retail",
        contact: {
          fullName: {
            given: firstname.value,
            family: lastname.value
          },
          email: {address: email.value},
        },
        socials: {
          username: {
            handle: username.value
          }
        },
        auth: {
          pwd: pwd.value
        }
      }, axiosConfig(null, i18n))
      
      if(!isHttpCodeInRange(200, response.status)) {
        if(response.data.error)
          toast(errorToast(null, response.data.error))
        else
          throw parseAxiosError(response)
      } else {
        toast(successToast(t('loginSignUpSucessTitle'), t('loginSignUpSucessMessage'), 'top', 30*24*60*60*1000))
        cookies.remove('account')
        navigate('/')
      }
    } catch(error) {
      console.error(error)
      toast(errorToast(null, typeof error == 'string' ? error : t('genericError')))
    } finally {
      setProcessing(false)
    }
  }

  const labelFontSize = 'sm'
  const labelMargin = '30px 0 0 0'
  const signInW = isLargerThan1024 ? '400px' : (isLargerThan512 ? '300px' : '95%')
  const signinColor = symbioseTheme.colors.headerTextColor
  const signinBg = symbioseTheme.colors.headerColor
  const secretTabTheme = {color: symbioseTheme.colors.textColor, bg: symbioseTheme.colors.buttonColor, opacity: 0.85}

  const signUpMaxW = isLargerThan1024 ? '400px' : (isLargerThan512 ? '300px' : '95%')
  const signupColor = symbioseTheme.colors.textColor
  const signupBg = symbioseTheme.colors.textBackgroundColor

  return (
    <>
    {getBackgroundComponent()}
    <Flex flexDirection="column" alignItems="center" justifyContent="center" textAlign="center" width="100%" height='100%'>
      <a href="/" style={{width: '50%'}}>
        <HStack w='100%' py={'20px'} color={symbioseTheme.colors.headerTextColor} bg={symbioseTheme.colors.headerColor}>
          <MdArrowBack /> 
          <Text><u><i>{t('backToHome')}</i></u></Text>
        </HStack>
      </a>

      <VStack w='100%' h='100%' spacing={0}>        
        <VStack spacing={4} bg={signinBg} color={signinColor} w={signInW} maxW={signInW}>
          <TFAModal _bg={signinBg} _color={signinColor} isOpen={isOpen} onClose={onClose} on2FA={(tfa)=> login(tfa)}/>
          <Text><b><i>{t('loginSignInMessage')}</i></b></Text>
          <Box w='100%' px={4}>
            <Email emailId={SIGNIN_EMAIL_ID} _color={signinColor} />
          </Box>
          <Tabs isFitted variant='soft-rounded'/*'line'*/ w='100%'>
            <TabList mb='1em'>
              <Tab _selected={secretTabTheme}>{t('loginSignUpPassword')}</Tab>
              <Tab _selected={secretTabTheme}>{t('ota')}</Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                <SigninPassword passwordId={SIGNIN_PWD_ID} _color={signinColor} />
              </TabPanel>
              <TabPanel>
                <OTA otaId={SIGNIN_OTA_ID} emailId={SIGNIN_EMAIL_ID} _color={signinColor} />
              </TabPanel>
            </TabPanels>
          </Tabs>          
          <Button id={SIGNIN_BUTTON_ID} isLoading={processing} disabled={processing} spinner={<BeatLoader size={8} />} onClick={()=>login()}>{t('loginSignInButton')}</Button>
          <Box />
          <HStack cursor='pointer'>
            <a href="/reset-pwd">
              <Text><u><i>{t('loginSignInForgotPassword')}</i></u></Text>
            </a>
          </HStack>
          <Box h='40px'/>
        </VStack>
      
        <VStack w='100%' color={signupColor} bg={signupBg}>
          <Box h='40px'/>
          <VStack spacing={4} maxW={signUpMaxW}>
            <Text><b><i>{t('loginSignUpMessage')}</i></b></Text>
            <VStack>
              <FormControl isRequired border='none' >
                <FormLabel fontSize={labelFontSize} margin={labelMargin}><u>{t('loginSignUpFirstName')}</u></FormLabel>
                <Input id={SIGNUP_FIRSTNAME_ID} variant='flushed'textAlign='center' />
              </FormControl>
              <FormControl isRequired border='none'>
                <FormLabel fontSize={labelFontSize} margin={labelMargin}><u>{t('loginSignUpLastName')}</u></FormLabel>
                <Input id={SIGNUP_LASTNAME_ID} variant='flushed' textAlign='center' />
              </FormControl>
              <FormControl isRequired border='none'>
                <FormLabel fontSize={labelFontSize} margin={labelMargin}><u>{t('loginSignUpUsername')}</u></FormLabel>
                <Input id={SIGNUP_NICKNAME_ID} variant='flushed' textAlign='center' />
                <FormHelperText color={signupColor} fontStyle='italic' fontSize='sm' opacity={0.7}>{t('loginSignUpNickNameInfo')}</FormHelperText>
              </FormControl>                       
            </VStack>
                    
            <Box h='25px'/>

            <Email _color={signupColor} emailId={SIGNUP_EMAIL_ID}/>         
            <SignupPasswords _color={signupColor} password1Id={SIGNUP_PWD_ID} password2Id={SIGNUP_PWD2_ID} />          
            
            <Box h='25px'/>

            <VStack>
              <FormControl border='none' >
                <Input id={SIGNUP_REFERRAL_ID} variant='flushed'textAlign='center' placeholder={t('loginSignUpReferral') as string}/>
                <FormHelperText fontStyle='italic' fontSize='sm' opacity={0.5}>{t('loginSignUpReferralInfo')}</FormHelperText>
              </FormControl>
            </VStack>
          </VStack>
        
          <Box h='20px'/>
          <Box h='20px'/>
          <ReCAPTCHA
            sitekey="6LergzcpAAAAACDl4rSDt_fb5FYK9X67upNsxALA"
            onChange={()=>setReCaptcha(true)}
            size={isLargerThan512 ? 'normal' : 'compact'}
          />
          <Box h='10px'/>
          <VStack spacing={3}>  
            <Button id={SIGNUP_BUTTON_ID} minW='150px' variant='outline' isLoading={processing} disabled={processing || !reCaptcha} spinner={<BeatLoader size={8} />} onClick={register}>{t('loginSignUpButton')}</Button>
            <Text fontSize='xs' maxW='350px' opacity={0.75}>{t('loginSignUpLegalDisclaimer')}</Text>
          </VStack>
        </VStack>
        <Box flexGrow={1} w='100%' bg={symbioseTheme.colors.textBackgroundColor} />
      </VStack>
    </Flex>
    <Footer />
    </>
  )
}