import { ReactNode, useEffect } from "react";
import { Box, Flex, Text, Icon, useColorModeValue, Image, VStack, HStack, Link, useColorMode, Button, useMediaQuery } from "@chakra-ui/react";

import Header from "../common/Header";
import { formatDocumentTitle, getBackgroundComponent, getHomeBackgroundComponent } from "../../lib/utils";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import { useTranslation } from "react-i18next";
import Message from "../common/Message";
import { symbioseTheme } from "../../App";

export default function NotFound() {
  
   const navigate = useNavigate()
  const cookies = new Cookies()
  const { t, i18n } = useTranslation()
  const { colorMode } = useColorMode()
  const [isLargerThan1024] = useMediaQuery('(min-width: 1024px)')

  document.title = formatDocumentTitle(t('notFoundPageTitle'))

  useEffect(() => {
    
  })


  const containerColor = symbioseTheme.colors.headerTextColor
  const containerBg = symbioseTheme.colors.headerColor

  return (
    <>
      {getBackgroundComponent()}
      <Header transparentBackground={true} />
      <Message _color={containerColor} _bg={containerBg} message={t('notFoundMessage')}/>
    </>
  )
}