import { ReactNode, useEffect, useState } from "react";
import { Box, Flex,Text, Icon, useColorModeValue, Link, useMediaQuery, Button, Stack, InputGroup, InputLeftElement, Input, InputRightElement, InputLeftAddon, background, VStack, Divider, Center, Tooltip, HStack, FormLabel, FormControl, FormHelperText, Table, Tbody, Td, Tr, Image, NumberInput, NumberDecrementStepper, NumberInputField, NumberIncrementStepper, useToast, InputRightAddon } from "@chakra-ui/react";
import { symbioseTheme } from "../../App";
import axios from "axios";
import Cookies from 'universal-cookie';
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { errorToast, parseAxiosError, successToast } from "../../lib/utils";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'


interface Props {
  value : any
  setValue(value : any) : any,
  _color? : string,
  _bg? : string
}

export default function PhoneNumber({value, setValue, _color, _bg} : Props) { 

  const { t, i18n } = useTranslation()
  const toast = useToast()
  

  return (
    <HStack w='100%' alignItems="center" justifyContent="center">
      <Box w='10px'/>
      <PhoneInput
        preferredCountries={['fr', 'lu', 'gb']}
        enableSearch={true}
        placeholder={t('settingsContactPhoneNumberPlaceholder') as string}
        value={value}
        onChange={setValue}
        inputStyle={{color: _color, border: '1px solid', borderColor: _color, backgroundColor: 'transparent', marginLeft: '0px', textAlign: 'left', height: '40px'}}
        buttonStyle={{color: _color, backgroundColor: _bg, border: '1px solid', borderColor: _color, width: '53px'}}
      />
    </HStack>
  )
}