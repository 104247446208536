import { ReactNode, useEffect, useState } from "react";
import { Box, Flex,Text, Icon, useColorModeValue, Link, useMediaQuery, Button, Stack, InputGroup, InputLeftElement, Input, InputRightElement, InputLeftAddon, background, VStack, Divider, Center, Tooltip, HStack, FormLabel, FormControl, FormHelperText, Table, Tbody, Td, Tr, Image, NumberInput, NumberDecrementStepper, NumberInputField, NumberIncrementStepper } from "@chakra-ui/react";
import { CheckIcon, EmailIcon, InfoIcon, LockIcon, PhoneIcon, QuestionOutlineIcon } from "@chakra-ui/icons";
import { axiosConfig, formatDocumentTitle, formatPrice, getBackgroundComponent, getLanguage, getLanguageEntry, getLanguageValue, isHttpCodeInRange, logout } from "../../../../lib/utils";
import { symbioseTheme } from "../../../../App";
import axios from "axios";
import Cookies from 'universal-cookie';
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { AiOutlineLock } from "react-icons/ai";
import { IconType } from "react-icons";

const htmlId = function(id : string) {
  return "SETTINGCARD-" + id
}

interface Props {
  icon : IconType,
  title : string,
  info : string,
  onClick() : any,
  _color? : string
  _bg? : string,
  _opacity? : number,
  _hoverOpacity? : number
}

export default function SettingCard({icon : Icon, title, info, onClick, _color, _bg, _opacity, _hoverOpacity} : Props) { 

  const { t, i18n } = useTranslation()
  const [isLargerThan1024] = useMediaQuery('(min-width: 1024px)')
  const [isTalerThan512] = useMediaQuery('(min-height: 512px)')

  const cardWidth = '210px'
  const cardHeight = '120px'
  const cardBoxShadow = 'none'//'3px 3px 8px 1px #454545'
  const cardPadding = 2
  const cardMargin = '10px 10px 10px 10px'
  const cardTransition = 'transform .2s'
  
  const cardHover = isLargerThan1024 ? {opacity: 1} : {}
  const cardBorderRadius = 15
  const cardBorder = '1px solid'
  

  return (
    
    <Flex flexDir={'column'} w={cardWidth} h={cardHeight} border={cardBorder} borderColor={_bg} borderRadius={cardBorderRadius} boxShadow={cardBoxShadow} margin={cardMargin} transition={cardTransition} opacity={_opacity} color={_color} bg={_bg} _hover={{opacity: _hoverOpacity}} p={cardPadding} cursor='pointer' onClick={onClick}>
      <Box h='10px'/>
        <HStack spacing={3} >
          <Icon />
          <Text>{title}</Text>
        </HStack>  
        <Box flexGrow={1}/>     
        <Text w='100%' fontSize='xs' opacity={0.7} textAlign='left' p='0px 2px 0px 5px'>{info}</Text>
      <Box h='10px'/>
    </Flex>
  )
}