import { ReactNode, useEffect, useState } from "react";
import { Box, Flex,Text, Icon, useColorModeValue, Link, useMediaQuery, Button, Stack, InputGroup, InputLeftElement, Input, InputRightElement, InputLeftAddon, background, VStack, Divider, Center, Tooltip, HStack, FormLabel, FormControl, FormHelperText, Tbody, Th, Tr, Thead, Table, Td, useToast, LinkBox, Image, layout } from "@chakra-ui/react";
import { CheckIcon, EmailIcon, LockIcon, PhoneIcon, QuestionOutlineIcon } from "@chakra-ui/icons";
import { axiosConfig, checkAuth, errorToast, formatDocumentTitle, formatPrice, getBackgroundComponent, getLanguageValue, isHttpCodeInRange, isValidCurrency, logout, parseAxiosError } from "../../../lib/utils";
import { symbioseTheme } from "../../../App";
import axios from "axios";
import Cookies from 'universal-cookie';
import { URL_MARKETPLACE_CHECKOUT, URL_PAYMENT_GATEWAYS_INFO } from "../../../constants";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "../../common/Header";
import { Chart } from "react-google-charts";
import { GiConsoleController } from "react-icons/gi";
import { useTranslation } from "react-i18next";
import Loading from "../../common/Loading";
import Footer from "../../common/Footer";
import CartItem from "../marketplace/CartItem";
import { off } from "process";
import PaymentItem from "./PaymentItem";
import BeatLoader from "react-spinners/BeatLoader";
import {loadStripe, StripeElementLocale, Appearance} from '@stripe/stripe-js';
import {useElements, PaymentElement} from '@stripe/react-stripe-js';
import {Elements} from '@stripe/react-stripe-js';
import StripeGateway from "./StripeGateway";
import PaymentGateway from "./PaymentGateway";
import Message from "../../common/Message";
import { MdArrowBack } from "react-icons/md";

const htmlId = function(id : string) {
  return "ORDERREVIEW-" + id
}

export default function OrderReview() {
  
  const navigate = useNavigate()
  const cookies = new Cookies()
  const { t, i18n } = useTranslation()
  const [isLargerThan1024] = useMediaQuery('(min-width: 1024px)')
  const [isTalerThan512] = useMediaQuery('(min-height: 512px)')
  const toast = useToast()
  const location = useLocation();

  const [processingCheckout, setProcessingCheckout] = useState(false)
  

  const parseParams = () : {cart : Map<string, any>, currency : string} | null => {
    if(!location.state || !location.state.cart || !location.state.currency || !isValidCurrency(location.state.currency))
      return null
    
    const ccy = location.state.currency
    
    const buffer = new Map()
    try {
      Array.from(location.state.cart.keys()).forEach((k) => {
        const entry = location.state.cart.get(k)
        buffer.set(k, {product: entry.product, quantity: entry.quantity})
      })
      return {cart: buffer, currency: ccy}
    } catch(error) {
      console.error(error)
      toast(errorToast(null, typeof error == 'string' ? error : t('genericError')))
      return null
    }
  }
  
  const params = parseParams()
  const totalItems = params?.cart ? Array.from(params.cart.values()).reduce<number>((sum, o) => {return sum + o.quantity}, 0) : 5
  const currency = params?.currency
  const totalPriceAmount = params ? Array.from(params.cart.values()).reduce<number>((sum, o) => {return sum + o.product.prices[params.currency].fractionAmount * o.quantity}, 0) : null
  const totalPrice = params ? formatPrice(params.currency, totalPriceAmount!) : null
  
  document.title = formatDocumentTitle(t('orderReview'))

  const checkout = async () => {
    setProcessingCheckout(true)
    try {
      if(!params) {
        console.error('params is not set')
        toast(errorToast(null, t('genericError')))
        return
      }
      const account = cookies.get('account')
      const checkoutCart : any = {}
      Array.from(params.cart.values()).forEach((e) => {
        checkoutCart[e.product.id] = e.quantity
      });
      const response = await axios.post(URL_MARKETPLACE_CHECKOUT, {
        cart: checkoutCart,
        paymentCurrency: params.currency,
        paymentGateway: 'Stripe',
        data: {payment_method_types: ['card']}
      }, axiosConfig(account.token, i18n))

      if(isHttpCodeInRange(200, response.status)) {
        const checkoutData = response.data
        if(totalPriceAmount != checkoutData.total.fractionAmount)
          throw "Price mismatch (expected " + totalPriceAmount + " but received " + response.data.total.fractionAmount +")"
        navigate('/payment-gateway',
          {
            replace: true,
            state: {
              invoiceId: checkoutData.invoiceId,
              payment: {
                gateway: 'Stripe',
                data: {
                  clientSecret: response.data.data['stripeClientSecret']
                }
              }
            }
          }
        )
      } else if(response.status == 401) {
        logout(cookies, navigate)
        return
      } else 
        throw parseAxiosError(response)

    } catch(error) {
      console.error(error)
      toast(errorToast(null, typeof error == 'string' ? error : t('genericError')))
    } finally {
      setProcessingCheckout(false)
    }
  }


  useEffect(() => {
    const load = async () => {
      const auth = await checkAuth(cookies, navigate);
      if(auth.error)
        toast(errorToast(null, t('genericError')))
      else if (auth.auth) {
       
      }
    }
    load()
  }, [])
 
  const containerWidth = isLargerThan1024 ? '60%' : '80%'
  const containerColor = symbioseTheme.colors.headerTextColor
  const containerBg = symbioseTheme.colors.headerColor 
  const checkoutTitleMarginY = isLargerThan1024 ? '40px' : '20px'
  const boxPadding = 2
  const boxBorderRadius = 7
  const boxBorder = '1px solid'
  const boxMarginY = 5
  
  return (
    <>
    {getBackgroundComponent()}
    <Header />
    {params ?
    <Flex flexDirection="column" alignItems="center" justifyContent="center" textAlign="center" w='100%' h='100%' color={containerColor} bg={containerBg}>
      {processingCheckout ?
      <Loading message={t('orderReviewCheckoutLoading')}/>
      :
      <>
        <HStack my={checkoutTitleMarginY}>
          <Text fontSize='2xl'> {t('paymentCheckoutTitle')} </Text>
          <Text fontSize='md'>({totalItems + " " + (totalItems > 1 ? t('paymentCheckoutItems') : t('paymentCheckoutItem'))})</Text>
        </HStack>
        <HStack w={containerWidth} my='20px' cursor='pointer' onClick={() => navigate('/marketplace')}>
          <MdArrowBack /> 
          <Text fontSize={'xs'}><u><i>{t('paymentDiscardAndGoToMarketplace')}</i></u></Text>
        </HStack>
        <VStack w={containerWidth}>
          <Table variant={'unstyled'} size={'xs'}>
            <Tbody>
              {/*<Tr> Uncomment when multiple payment method
                <Td>
                  <Box border='1px solid' w='100%'  p={boxPadding} borderRadius={boxBorderRadius}>
                    <VStack>
                      <Text fontSize='lg' fontWeight='bold' w='100%' textAlign='left'>{t('paymentPaymentGateway')}</Text>
                      <VStack>
                        ici mettre une dropdown avec les modes de paiements
                      </VStack>
                    </VStack>
                  </Box>
                </Td>
                        </Tr>*/}
              <Tr>
                <Td>
                  <VStack border={boxBorder} w='100%'  p={boxPadding} borderRadius={boxBorderRadius} my={boxMarginY}>
                    <Text fontSize='lg' fontWeight='bold' w='100%' textAlign='left'>{t('paymentItems')}</Text>
                    <VStack w='100%'>
                      <Flex flexDirection='row' flexWrap='wrap' w='100%' alignItems="center" justifyContent="center" textAlign="center">
                      {Array.from(params.cart.values()).map((o, i) => {
                        const title = getLanguageValue(i18n, o.product.titles)
                        const unitPrice = o.product.prices[params.currency].fractionAmount
                        return (                     
                          <PaymentItem key={i} product={o.product} title={title} unitPrice={unitPrice} quantity={o.quantity} currency={params.currency}/>
                        )
                        })
                      }
                      </Flex>
                    </VStack>
                  </VStack>
                </Td>
              </Tr>
              <Tr>
                <Td>
                  <VStack border={boxBorder} w='100%' p={boxPadding} borderRadius={boxBorderRadius} my={boxMarginY}>
                    <Text fontSize='xl' fontWeight='bold' color={symbioseTheme.colors.textColorRed} wordBreak='break-all'>
                      {t('paymentTotal')+":"}&nbsp;{totalPrice}</Text>
                    <Button minW='150px' bg={symbioseTheme.colors.importantButtonBackgroundColor}
                      isLoading={processingCheckout} disabled={processingCheckout} spinner={<BeatLoader size={8} color={symbioseTheme.colors.darkGreen} />}
                      onClick={checkout}
                    >
                      {t('paymentConfirmOrder')}
                    </Button>
                    <VStack>                  
                      <Text fontSize='xs' opacity={0.75}>{t('paymentLegalDisclaimer')}</Text>
                    </VStack>
                  </VStack>
                </Td>
              </Tr>
            </Tbody>
          </Table>
          <Box h='40px' />
        </VStack>
      </>
      }
    </Flex>
    :
    <>
      {!params ?
      <Message _color={containerColor} _bg={containerBg} message={t('paymentEmptyCart')} _navigate={{url: '/marketplace', message: t('paymentGoToMarketplace')}}/>
      :
      <Loading _color={containerColor} _bg={containerBg} />
      }
    </>
    }    
    </>
  )
}