import { Box, ResponsiveValue, Toast, useToast } from "@chakra-ui/react";
import { EnumType } from "typescript"
import { useNavigate } from "react-router-dom";
import ReactGA from "react-ga4";
import Cookies from "universal-cookie";
import axios from "axios";
import { URL_ACCOUNT_IS_LOGGED_IN, URL_ACCOUNT_LOGOUT } from "../constants";
import { i18n, t } from "i18next";
import { symbioseTheme } from "../App";
import { PRIVACY_COOKIE_KEY } from "../components/common/PrivacyFrame";

declare let window: any;

export const axiosConfig = (token : string | null, i18n : i18n | null) => {
    const headers : any = {
       'Content-Type': 'application/json'
    }

    if(token)
        headers['Authorization'] = "Bearer " + token

    if(i18n) {
        let language = i18n.language
        if(language && language.trim().length > 0) {
            if(language.includes('-'))
                language = language.split('-')[0]
            headers['Accept-Language'] = language   
        }
    }          

    return { 
        validateStatus: () => true,
        headers: headers
    }
}

export const parseAxiosError = (response : any) => {
  if(response.data && response.data.error)
    return response.data.error + " [" + response.status + "]"
  return "Http request error " + response.status + " (no message)"
}

export const tryGetClientIp = async () => {
  try {
    const res = await axios.get('https://geolocation-dbd.com/json/', {
      timeout: 3000
    })
    return res.data.IPv4
  } catch (error) {
    return null
  }
}

export const is2FARequired = (response : any) : boolean => {
  return response.status == 250
}

export const getLanguage = (i18n : i18n) => {
    let language = i18n.language
    if(language.includes('-'))
      language = language.split('-')[0]
    switch(language) { 
      case "en":
        return "en"
      case "fr":
        return "fr"
      default: 
        return "en"
    }     
}

export const getLanguageValue = (i18n : i18n, dictionary : Object) : any => {
  return getLanguageEntry(getLanguage(i18n), dictionary);
}

export const getLanguageEntry = (language : string, dictionary : any) : any => {
    if(language.includes('-'))
      language = language.split('-')[0]
    switch(language) { 
      case "en":
        return dictionary["en"]
      case "fr":
        return dictionary["fr"]
      default: 
      return dictionary["en"]
    }     
}

export const formatPrice = (currency : string, amount : number) : string => {
  switch(currency) {
    case "EUR":
      return (amount/100).toFixed(2) + " EUR"
    default:
      alert("Unhandled currency " + currency)
      return "--"
  }
}

export const formatDatetime = (datetime : string | null, nullValue : string | null = "--") : any => {
  return datetime ? new Date(datetime.replace('[UTC]', '')).toLocaleString('sv') : nullValue
}

export const isValidCurrency = (currency : string) : boolean => {
  switch(currency) {
    case "EUR":
      return true
    default:
      return false
  }
}

export const computeInvoiceTotal = (invoice : any) => {
  let currency = null
  let totalPrice = 0
  let totalQuantity = 0
  try{
    for(let i=0;i<invoice.items.length;i++) {
      if(currency == null)
        currency = invoice.items[i].totalAfterTaxPrice.currency
      else if(invoice.items[i].totalAfterTaxPrice.currency != currency) {
        console.error("Different currency ("+currency+" vs "+invoice.items[i].totalAfterTaxPrice.currency +")")
        return null
      }
      totalPrice += invoice.items[i].totalAfterTaxPrice.fractionAmount
      totalQuantity += invoice.items[i].quantity
    }
    return {price: formatPrice(currency, totalPrice), quantity: totalQuantity}
  }catch(error) {
    console.error(error)  
  }
}

export const isInvoicePaid = (settlementStatus : string) => {
  return settlementStatus == "Settled"
}

export const isHttpCodeInRange = (range : number, code : number) : boolean =>  {
    if (code > 999 || code < 0)
			throw "Invalid code (must between 0 and 999)"
    if (range > 999 || range < 0)
        throw "Invalid range (must between 0 and 999)";
	return Math.trunc(range / 100) == Math.trunc(code / 100);
}

export const checkAuth = async (cookies : Cookies, navigate : Function) : Promise<{auth : boolean, error: any | null}> => {
    try {
      const account = cookies.get('account')
      const response = account ? await axios.get(URL_ACCOUNT_IS_LOGGED_IN, axiosConfig(account.token, null)) : null
      if(!response || !isHttpCodeInRange(200, response.status)){ 
        cookies.remove('account')
        navigate('/login')
        return {auth: false, error: null}
      }
      return {auth: true, error: null}
    } catch(error) {
      console.error(error)
      cookies.remove('account')
      navigate('/login')
      return {auth: false, error: error}
    }
}

export const logout = async (cookies : Cookies, navigate : any) => {
    try {
        const account = cookies.get('account')
        const response = account ? await axios.get(URL_ACCOUNT_LOGOUT, axiosConfig(account.token, null)) : null
      } catch(error) {
        console.error(error)
      } finally {
        cookies.remove('account')
        navigate('/')
      }
}

export const isPrivacyPolicyAgreed = (cookies : Cookies) : boolean => {
  try{
    const privacyConsent = cookies.get(PRIVACY_COOKIE_KEY)
    const agreed : boolean = typeof privacyConsent !== 'undefined' && privacyConsent.accepted
    return agreed
  } catch(error) {
    console.error(error)
    return false
  }
}

export const gaPageView = (cookies : Cookies) => {
  if(isPrivacyPolicyAgreed(cookies)) {
    ReactGA.initialize("G-EVLQ97CX4R");
    ReactGA.send("pageview")
  }
}

export function truncateStr(value: string | null | undefined, maxLength : number): string | null {
    if(!value || value == null)
        return null
    if(value.length <= maxLength)
        return value
    maxLength = Math.max(1, maxLength - 3)
    return value.slice(0, maxLength) + "..."
}

export function formatNumber(n: number | string | null | undefined) {
    if(n==null)
        return null
    return Number(n).toLocaleString()
}

export function formatUsername(username: any | null) {
  if(!username)
      return null
  return username.handle + "#" + username.id
}

export function enum2StringArray(_enum : any) : string [] {
    return Object.values(_enum).filter(value => typeof value === 'string') as string [];
}

export function formatDocumentTitle(page : string) : string {
    return page + " | Symbiose Management"
}

export function getHomeBackgroundComponent() : any {
    return <Box backgroundImage={`url("${"./assets/img/amazing-forest.jpg"}")`} opacity={0.7} /*filter='blur(8px)'*/ w='100%' h='100%' zIndex={-10} position='fixed' />
}

export function getBackgroundComponent() : any {
    //return <Box backgroundImage={`url("${"./assets/img/amazing-forest.jpg"}")`} opacity={0.1} filter='blur(8px)' w='100%' h='100%' zIndex={-10} position='fixed' />
    return <Box bg={symbioseTheme.colors.backgroundColor} w='100%' h='100%' zIndex={-10} position='fixed' />
}

export function errorToast(title : string | null, msg : string, duration : number = 9000) : any {
    return {
        position: 'top',
        status: 'error',
        title: title,
        duration: duration,
        isClosable: true,
        description: msg,
        variant: 'solid'
   }
}

export function warningToast(title : string | null, msg : string, duration : number = 2700) : any {
  return {
      position: 'top',
      status: 'warning',
      title: title,
      duration: duration,
      isClosable: true,
      description: msg,
      variant: 'solid'
 }
}

export function successToast(title : string | null, msg : string | null = null, position : string = 'top', duration : number = 5000) : any {
    return {
        containerStyle: {
            backgroundColor: "green",
            color: 'white',
            borderRadius: 5,
            boxShadow: '2px 2px 5px 0px #000000',
        },
        position: position,
        status: 'success',
        duration: duration,
        title: title,
        isClosable: true,
        description: msg,
        variant: 'solid'
   }
}

export const parsePinInput = function(id : string) : string | null {

  let value : string | null = null
  for(let i=0;;i++) {
    const elem = document.getElementById(id + "-" + i) as HTMLInputElement
    if(!elem)
      break
    if(value) {
      value = value + elem.value 
    } else {
      value = elem.value
    }
  }
  return value
}

export const parseInputs = function(id : string) : string[] | null {

  let value : string[] | null = null
  for(let i=0;;i++) {
    const elem = document.getElementById(id + "-" + i) as HTMLInputElement
    if(!elem)
      break
    if(value) {
      value.push(elem.value) 
    } else {
      value = [elem.value]
    }
  }
  return value
}

export const string2NullIfEmpty = function (value : string | null | undefined) : string | null {
  if(!value || value.length <= 0)
    return null
  return value
}

export const tryClearInput = function(id : string) : boolean {
  try{
    const input = document.getElementById(id) as HTMLInputElement
    if(!input)
      return false
    input.value = ''
    return true
  }catch(error) {
    return false
  }
}


