import { ReactNode, useEffect, useState } from "react";
import { Box, Flex, Text, Textarea, Icon, useColorModeValue, Image, VStack, HStack, Link, useColorMode, Button, useMediaQuery, Table, Tbody, Tr, Td, Accordion, AccordionItem, AccordionIcon, AccordionPanel, AccordionButton, position, FormControl, FormLabel, Input, FormHelperText, useToast } from "@chakra-ui/react";

import Header from "../../common/Header";
import Message from "../../common/Message";
import { axiosConfig, errorToast, warningToast, formatDocumentTitle, getBackgroundComponent, getHomeBackgroundComponent, isHttpCodeInRange, parseAxiosError, string2NullIfEmpty, successToast, tryClearInput, tryGetClientIp } from "../../../lib/utils";
import { useNavigate, useLocation } from "react-router-dom";
import Cookies from "universal-cookie";
import { useTranslation } from "react-i18next";
import { Trans } from 'react-i18next'
import ReCAPTCHA from "react-google-recaptcha";

import axios from "axios";
import { symbioseTheme } from "../../../App";
import { URL_SEND_CONTACT_FORM } from "../../../constants";
import { BeatLoader } from "react-spinners";


const htmlId = function(id : string) {
  return "CONTACT-" + id
}


const CONTACT_NAME_ID = htmlId("contact-name")
const CONTACT_EMAIL_ID = htmlId("contact-email")
const CONTACT_PHONE_ID = htmlId("contact-phone")
const CONTACT_SUBJECT_ID = htmlId("contact-subject")
const CONTACT_CONTENT_ID = htmlId("contact-content")


export default function Blog() {
  
  const navigate = useNavigate()
  const cookies = new Cookies()
  const location = useLocation()
  const { colorMode } = useColorMode()
  const [isLargerThan1024] = useMediaQuery('(min-width: 1024px)')
  const [isLargerThan512] = useMediaQuery('(min-width: 512px)')
  const { t, i18n } = useTranslation()
  const toast = useToast()
  const [processing, setProcessing] = useState(false)
  const [reCaptcha, setReCaptcha] = useState(false)
  const [msgSent, setMsgSent] = useState(false)

  document.title = formatDocumentTitle(t('contactPageTitle'))



  useEffect(() => {
  }, [])
  
  const send = async function() {

    const name = string2NullIfEmpty((document.getElementById(CONTACT_NAME_ID) as HTMLInputElement)?.value)
    const email = string2NullIfEmpty((document.getElementById(CONTACT_EMAIL_ID) as HTMLInputElement)?.value)
    const phone = string2NullIfEmpty((document.getElementById(CONTACT_PHONE_ID) as HTMLInputElement)?.value)
    const subject = string2NullIfEmpty((document.getElementById(CONTACT_SUBJECT_ID) as HTMLInputElement)?.value)
    const content = string2NullIfEmpty((document.getElementById(CONTACT_CONTENT_ID) as HTMLInputElement)?.value)
    

    setProcessing(true)
    try {

      if(!name || name.trim().length <= 0
        || !email || email.trim().length <= 0
        /*|| !phone || phone.trim().length <= 0*/
        || !subject || subject.trim().length <= 0
        || !content || content.trim().length <= 0) {
        toast(errorToast(null, t('contactPageMissingFields')))
        return
      }
      
      const clientIp = await tryGetClientIp();
      const aconf = axiosConfig(null, i18n)
      aconf.headers['X-Forwarded-For'] = clientIp      

      const response = await axios.post(URL_SEND_CONTACT_FORM, {
        sender: name,
        contact: {
          email: email,
          phone: phone
        },
        subject: subject,
        content: content
      }, aconf)
      
      if(!isHttpCodeInRange(200, response.status)) 
        throw parseAxiosError(response)
      else {
        setMsgSent(true)
        toast(successToast(t('contactPageSuccessMsg')))
        tryClearInput(CONTACT_NAME_ID)
        tryClearInput(CONTACT_EMAIL_ID)
        tryClearInput(CONTACT_PHONE_ID)
        tryClearInput(CONTACT_SUBJECT_ID)
        tryClearInput(CONTACT_CONTENT_ID)
      }
    } catch(error) {
      console.error(error)
      toast(errorToast(null, typeof error == 'string' ? error : t('genericError')))
    } finally {
      setProcessing(false)
    }
  }

  const formW = isLargerThan1024 ? '400px' : (isLargerThan512 ? '350px' : '90%')
  const labelFontSize = 'sm'
  const labelMargin = '10px 0 5px 0'

  return (
    <>
    {getBackgroundComponent()}
    <Header />
    {msgSent ?
      <Message _color={symbioseTheme.colors.headerTextColor} message={t('contactPageSuccessMsg')}/>
    :
      <Flex flexDirection="column" alignItems="center" justifyContent="center" textAlign="center" width="100%">
        <VStack position='relative' spacing={5} w='100%' alignItems="center" justifyContent="center" py={'40px'}>        
          <VStack color={symbioseTheme.colors.textBackgroundColor} minW={formW}>
            <FormControl isRequired>
              <FormLabel fontSize={labelFontSize} margin={labelMargin}><u>{t('contactPageName')}</u></FormLabel>
              <Input id={CONTACT_NAME_ID} variant='outline' textAlign='left' borderColor={symbioseTheme.colors.headerTextColor} />
            </FormControl>
            <FormControl isRequired>
              <FormLabel fontSize={labelFontSize} margin={labelMargin}><u>{t('contactPageEmail')}</u></FormLabel>
              <Input id={CONTACT_EMAIL_ID} variant='outline' textAlign='left' borderColor={symbioseTheme.colors.headerTextColor} />
            </FormControl>
            <FormControl display={'none'}>
              <FormLabel fontSize={labelFontSize} margin={labelMargin}><u>{t('contactPagePhone')}</u></FormLabel>
              <Input id={CONTACT_PHONE_ID} variant='outline' textAlign='center' borderColor={symbioseTheme.colors.headerTextColor} />
            </FormControl>
            <FormControl isRequired>
              <FormLabel fontSize={labelFontSize} margin={labelMargin}><u>{t('contactPageSubject')}</u></FormLabel>
              <Input id={CONTACT_SUBJECT_ID} variant='outline' textAlign='left' borderColor={symbioseTheme.colors.headerTextColor} />
            </FormControl>
            <FormControl isRequired>
              <FormLabel fontSize={labelFontSize} margin={labelMargin}><u>{t('contactPageContent')}</u></FormLabel>
              <Textarea id={CONTACT_CONTENT_ID} minH={300} variant='outline' textAlign='left' borderColor={symbioseTheme.colors.headerTextColor} placeholder={t('contactPageContentPlaceholder') as string} />
            </FormControl>
            <Box h={10}/>
            <ReCAPTCHA
              sitekey="6LergzcpAAAAACDl4rSDt_fb5FYK9X67upNsxALA"
              onChange={()=>setReCaptcha(true)}
              size={isLargerThan512 ? 'normal' : 'compact'}
            />
            <Box h={10}/>
            <Button isLoading={processing} disabled={processing || !reCaptcha} variant='solid' spinner={<BeatLoader size={8} />} onClick={send}><Text>{t('contactPageSendButton')}</Text></Button>
          </VStack>                
        </VStack>
      </Flex>
    }
    
    </>
  )
}