export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL
//export const URL_CLIENT_SERVER = "http://localhost:9999"
//export const URL_CLIENT_SERVER = ""


export const URL_SEND_CONTACT_FORM = API_BASE_URL + "/v1/customer/rfi"

export const URL_ACCOUNT_REGISTER = API_BASE_URL + "/v1/account/signup"
export const URL_ACCOUNT_LOGIN = API_BASE_URL + "/v1/account/signin"
export const URL_ACCOUNT_LOGOUT = API_BASE_URL + "/v1/account/signout"
export const URL_ACCOUNT_IS_LOGGED_IN = API_BASE_URL + "/v1/account/isauthenticated"
export const URL_ACCOUNT_OTA = API_BASE_URL + "/v1/account/ota"
export const URL_ACCOUNT_REQUEST_RESET_PWD = API_BASE_URL + "/v1/account/reset-pwd"
export const URL_ACCOUNT_UPDATE = API_BASE_URL + "/v1/account/update"
export const URL_ACCOUNT_2FA = API_BASE_URL + "/v1/account/tfa"
export const URL_ACCOUNT_INFO = API_BASE_URL + "/v1/account/info"
export const URL_ACCOUNT_INVOICES = API_BASE_URL + "/v1/account/invoices"

export const URL_CUSTODIAN_GET_PORTFOLIO = API_BASE_URL + "/v1/custodian/portfolio"


export const URL_MARKETPLACE_GET_PRODUCTS = API_BASE_URL + "/v1/marketplace/products"
export const URL_MARKETPLACE_CHECKOUT = API_BASE_URL + "/v1/marketplace/checkout"
export const URL_MARKETPLACE_COLLECT = API_BASE_URL + "/v1/marketplace/collect"

export const URL_PAYMENT_GATEWAYS_INFO = API_BASE_URL + "/v1/payment-gateway/info"


export const URL_BLOG_PREVIEW = API_BASE_URL + "/v1/blog/preview"
export const URL_BLOG_ARTICLE = API_BASE_URL + "/v1/blog/article"