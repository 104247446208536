import { ReactNode, useEffect, useState } from "react";
import { Box, Flex, Text, Icon, useColorModeValue, Image, VStack, HStack, Link, useColorMode, Button, useMediaQuery, Table, Tbody, Tr, Td, Accordion, AccordionItem, AccordionIcon, AccordionPanel, AccordionButton, position, FormControl, FormLabel, Input, FormHelperText, useToast } from "@chakra-ui/react";

import Header from "../../common/Header";
import { axiosConfig, errorToast, warningToast, formatDocumentTitle, getBackgroundComponent, getHomeBackgroundComponent, isHttpCodeInRange, parseAxiosError, string2NullIfEmpty, successToast, tryClearInput, tryGetClientIp } from "../../../lib/utils";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import Cookies from "universal-cookie";
import { useTranslation } from "react-i18next";
import { Trans } from 'react-i18next'

import { gaPageView } from "../../../lib/utils";
import businessBackground from '../../../img/forest/business-bg.jpg';
import businessBackgroundTiny from '../../../img/forest/business-bg-tiny.jpg';
import {ReactComponent as symbioseSvg} from '../../../img/logo/main/Logo_Symbiose_MANAGEMENT_arbre_blank.svg';
import { symbioseTheme } from "../../../App";
import Loading from "../../common/Loading";
import { GiEarthAfricaEurope, GiEarthAmerica, GiEcology, GiForest, GiGreenhouse, GiReceiveMoney } from "react-icons/gi";
import { ImEarth, ImNewspaper, ImUserTie } from "react-icons/im";
import { MdAccountBalance, MdSavings } from "react-icons/md";
import { BsFillFileEarmarkBarGraphFill, BsFillTreeFill } from "react-icons/bs";
import { RiPlantLine } from "react-icons/ri";
import ProgressiveImage from "react-progressive-graceful-image";
import PreviewCard from "./PreviewCard";
import static_article from "../../../article.json"
import axios from "axios";
import { URL_BLOG_ARTICLE, URL_BLOG_PREVIEW } from "../../../constants";


import blogBackground from '../../../img/forest/blog-bg.jpg';
import blogBackgroundTiny from '../../../img/forest/blog-bg-tiny.jpg';
import PageNavigation from "../../common/PageNavigation";

const htmlId = function(id : string) {
  return "BLOGPREVIEW-" + id
}


const PAGE_SIZE = 9

interface ArticlePreviews { 
  
  previews : [],
  page: number,
  total : number
}


export default function BlogPreview() {
  
  const navigate = useNavigate()
  const cookies = new Cookies()
  const location = useLocation()
  const { colorMode } = useColorMode()
  const [isLargerThan1024] = useMediaQuery('(min-width: 1024px)')
  const [isLargerThan512] = useMediaQuery('(min-width: 512px)')
  const [isLargerThan360] = useMediaQuery('(min-width: 360px)')
  const { t, i18n } = useTranslation()
  const toast = useToast()
  const [searchParams, setSearchParams] = useSearchParams();
  const [processing, setProcessing] = useState(true)
  const [articlePreviews, setArticlePreviews] = useState<ArticlePreviews | null>(null)

  

  const hasAccount = cookies.get('account') ? true : false
  document.title = formatDocumentTitle(t('blogPageTitle'))

  const pageOfIndex = (index : number) => {
    if(!index)
      return 0
    return Math.floor(index/PAGE_SIZE)
  }

  const loadArticlePreviews = async (page : number) => {
    setProcessing(true)

    try {
      if(page < 0) {
        const p = searchParams.get("p")
        if(p)
          try
          {
            page = Number.parseInt(p) - 1
          }catch(error) {
            page = 0
          }
        else
          page = 0
      }
      
      if(page<0)
        page = 0
      searchParams.set('p', (page+1).toString())
      setSearchParams(searchParams)
      const articlePreviewResponse = await axios.post(URL_BLOG_PREVIEW, {from: page * PAGE_SIZE, to: (page + 1) * PAGE_SIZE}, axiosConfig(null, i18n))
      if(!isHttpCodeInRange(200, articlePreviewResponse.status)) 
        throw parseAxiosError(articlePreviewResponse)
      const _articlePreviews = articlePreviewResponse.data
      if(page == 0 || _articlePreviews.previews.length > 0) {
        setArticlePreviews({..._articlePreviews, page: page})
      } else {
        loadArticlePreviews(pageOfIndex(_articlePreviews.total-1))
        //toast(errorToast(null, t('genericError')))  
      }
    } catch(error) {
      console.error(error)
      toast(errorToast(null, typeof error == 'string' ? error : t('genericError')))
    } finally {
      setProcessing(false)
    }
  }

  useEffect(() => {
    gaPageView(cookies)
    try{
      loadArticlePreviews(-1)
    } catch(error) {
      toast(errorToast(null, typeof error == 'string' ? error : t('genericError')))
    } finally {
      
    }
  }, [])
  

  const bannerFontSize = isLargerThan512 ? '6xl' :  (isLargerThan360 ? '4xl' : '3xl')
  const bannerFontWeight = isLargerThan512 ? 'bold' : 'normal'
  const bannerHeight = isLargerThan512 ? '300px' : '200px'
  const bannerMargin = isLargerThan512 ? 10 : 0
  const bannerPadding = isLargerThan512 ? 3 : 3

  const articleMinHeight = isLargerThan512 ? '400px' : '200px'
  const articleMaxWidth = isLargerThan1024 ? '768' : '512'
  const articleTitleSize = isLargerThan512 ? '60' : '3xl'
  const articleSubTitleSize = isLargerThan512 ? '17' : 'xl'
  const articleSubSubTitleSize = isLargerThan512 ? '13' : 'md'
  
  const previewWidth = isLargerThan360 ? '75%' : '95%'

  const maxWidth = '100%'
  const separatorSpacing = 10
  const topPadding = isLargerThan512 ? 5 : 2
  
  
  return (
    <>
    {getBackgroundComponent()}
    <Header />
    <Flex flexDirection="column" alignItems="center" justifyContent="center" width="100%">
        
      <Flex flexDirection="column" w='100%' maxW={maxWidth} minHeight={articleMinHeight} p={topPadding} color={symbioseTheme.colors.headerTextColor} bg={symbioseTheme.colors.headerColor} alignItems="center" justifyContent="center">
        <Box flexGrow={1} />
        {
          /*<Flex flexDirection="column" m={forestMargin} p={forestPadding} color={symbioseTheme.colors.textColor} bg={symbioseTheme.colors.textBackgroundColor} 
            fontSize={forestFontSize} minW={'100%'} minH='200px' alignItems="center" justifyContent="center">
            <Box flexGrow={1} minH={separatorSpacing/3} />
            <Text fontSize={'4xl'} fontWeight={forestFontWeight} w={previewWidth} maxW={previewWidth}>{'SYMBIOSE'}</Text>            
            <Text fontSize={'4xl'} fontWeight={forestFontWeight} w={previewWidth} maxW={previewWidth} marginLeft={'10%'}>{'BLOG'}</Text>            
            <Box flexGrow={1} minH={separatorSpacing/3} />
          </Flex>*/
          <Flex flexDirection="row" w='100%' minH={bannerHeight} m={bannerMargin} p={bannerPadding} color={symbioseTheme.colors.textColor} bg={symbioseTheme.colors.textBackgroundColor}>
              <Box flexGrow={1}>
                <Flex flexDirection="column" minH={'100%'} alignItems="center" justifyContent="center" textAlign={'center'}>
                  <Box flexGrow={1} />
                  <Text fontSize={bannerFontSize} fontWeight={bannerFontWeight} w={previewWidth} maxW={previewWidth}>{'SYMBIOSE'}</Text>            
                  <Box h='0px' borderTop={'solid 1px'} w='30%'/>
                  <Text fontSize={bannerFontSize} fontWeight={bannerFontWeight} w={previewWidth} maxW={previewWidth}>{'BLOG'}</Text>            
                  <Box flexGrow={1} />
                </Flex>
              </Box>              
              <Box flexGrow={1} display={isLargerThan512 ? 'initial' : 'none'} />
          </Flex>
          /*<VStack position='relative' spacing={5} w='100%' h={forestHeight} alignItems="center" justifyContent="center" zIndex={100}>
          <ProgressiveImage 
            src={blogBackground} placeholder={blogBackgroundTiny}
            >
            {(src, loading) => (
              <img style={{ opacity: loading ? 1 : 1, objectFit: 'cover', zIndex: -1, position: 'absolute', width: '100%', height: forestHeight}}  src={src} alt="Retail" />
            )}
          </ProgressiveImage>
          <Flex flexDirection="column" m={forestMargin} p={forestPadding} bg={symbioseTheme.colors.textBackgroundColor} fontSize={forestFontSize} maxW={isLargerThan1024 ? '1024px' : ''}>
            <Box flexGrow={1} minH={separatorSpacing/2} />
            <Text fontSize={forestTitleFontSize} fontWeight={forestFontWeight} w='100%'>{'Le blog Symbiose'}</Text>            
            <Box flexGrow={1} minH={separatorSpacing/2} />
          </Flex>
            </VStack>*/
        }            
        {processing ?
          <>
            <Box minH={150} />
            <Loading message=" " />
          </>
          :
          <>
          {/*<Box dangerouslySetInnerHTML={{ __html: static_article.html }} />*/}
          {articlePreviews ? 
            <Flex flexDirection="column" alignItems="center" justifyContent="center" textAlign="center" w="100%">
              <VStack w={previewWidth} maxW={previewWidth}>
                <Box h='50px'/>
                <Flex flexDirection='row' flexWrap='wrap' alignItems="center" justifyContent="center" textAlign="center">
                {articlePreviews.previews.map((o, i) => (
                  <PreviewCard key={i} articlePreview={o}/>
                ))}
                </Flex>
                <Box h='50px'/>            
                <PageNavigation currentPage={()=> articlePreviews.page} totalItems={() => articlePreviews.total} pageOfIndex={(index) => pageOfIndex(index)} loadPage={(page) => loadArticlePreviews(page)} />
              </VStack>
            </Flex>
            :
            <Loading message=" " />
          } 
          </>
        }  
        <Box flexGrow={1} minH={separatorSpacing} />
      </Flex>

    </Flex>
  </>
  )
}