import { MouseEventHandler, ReactNode, useEffect, useState } from "react";
import { Box, Flex,Text, Icon, useColorModeValue, Link, useMediaQuery, Button, Stack, InputGroup, InputLeftElement, Input, InputRightElement, InputLeftAddon, background, VStack, Divider, Center, Tooltip, HStack, FormLabel, FormControl, FormHelperText, Table, Tbody, Td, Tr, useToast } from "@chakra-ui/react";
import { axiosConfig, errorToast, formatDocumentTitle, getBackgroundComponent, isHttpCodeInRange, logout } from "../../../lib/utils";
import { symbioseTheme } from "../../../App";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useElements, useStripe, PaymentElement} from '@stripe/react-stripe-js';
import BeatLoader from "react-spinners/BeatLoader";

const htmlId = function(id : string) {
  return "STRIPEGATEWAY-" + id
}

interface Props {
  invoiceId : string
}

export default function  StripeGateway({invoiceId} : Props) {

  const { t, i18n } = useTranslation()
  const [isLargerThan1024] = useMediaQuery('(min-width: 1024px)')
  const [isLargerThan360] = useMediaQuery('(min-width: 360px)')
  const [isTalerThan512] = useMediaQuery('(min-height: 512px)')
  const navigate = useNavigate()
  const toast = useToast() 
  const stripe = useStripe()
  const elements = useElements()


  const [buttonClicked, setButtonClicked] = useState(false)
     
  const pay = async (e : React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.preventDefault();
    setButtonClicked(true)

    try {

      if(!stripe || !elements) {
        console.error('Stripe objects not set')
        toast(errorToast(null, t('genericError')))
        return
      }

      if(!invoiceId) {
        console.error('Invoice id not set')
        toast(errorToast(null, t('genericError')))
        return
      }
      
      const callback = window.location.protocol + "//" + window.location.host + "/collect?id=" + invoiceId 

      const result = await stripe.confirmPayment({
        //`Elements` instance that was used to create the Payment Element
        elements,
        confirmParams: {
          return_url: callback,
        },
      });

      if (result.error) {
        // Show error to your customer (for example, payment details incomplete)
        console.error(result.error.message);
      } else {
        // Your customer will be redirected to your `return_url`. For some payment
        // methods like iDEAL, your customer will be redirected to an intermediate
        // site first to authorize the payment, then redirected to the `return_url`.
      }
    } catch(error) {
      console.error(error)
      toast(errorToast(null, typeof error == 'string' ? error : t('genericError')))
    } finally {
      setButtonClicked(false)
      navigate('/marketplace', {replace: true})
    }    
  }


  return (
      <VStack spacing={5} >
        <PaymentElement options={{terms: {card: 'never'}}} />
        <Box>
          <Button minW='250px' bg={symbioseTheme.colors.importantButtonBackgroundColor}
            isLoading={buttonClicked} disabled={buttonClicked} spinner={<BeatLoader size={8} color={symbioseTheme.colors.darkGreen} />}
            onClick={pay}
          >
            {t('paymentGatewayPlaceOrder')}
          </Button>
          <Text fontSize='xs' opacity={0.75} maxW='250px' mt={2}>{t('paymentGatewayLegalDisclaimer')}</Text>
        </Box>
      </VStack>
  )
}