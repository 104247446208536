import { ReactNode } from "react";
import { Box, Flex, Text, Link, Stack, useColorMode, useMediaQuery, VStack, HStack } from "@chakra-ui/react";
import { SiDiscord, SiTwitter, SiTelegram, SiMedium } from 'react-icons/si';
import { AiFillFileText, AiFillMediumCircle } from 'react-icons/ai';
import { MdAlternateEmail, MdMail } from 'react-icons/md';
import { useTranslation } from "react-i18next";
import { BsLinkedin } from "react-icons/bs";
import { FiExternalLink } from "react-icons/fi";


type Props = {
  children?: ReactNode;
};
/*
coloriser la navabar link
change icon
create charte eco
mettre les cgu dans react*/

export default function Footer ({ children }: Props) {
  const [isLargerThan1024] = useMediaQuery('(min-width: 1024px)')
  const { t, i18n } = useTranslation()

  const getLegalFilePath = function(file : string) : string {
    if(i18n.language.toUpperCase().includes('FR'))
      return "/legal/fr/" + file
    else
      return "/legal/fr/" + file
  }


  return (
    <Flex
      opacity={0.9}
      flexDirection="row"
      alignItems="center"
      justifyContent="center"
      pt={1}
      color={'white'} bg={'black'}
      fontSize={isLargerThan1024 ? '2xs' : '2xs'}
    >
      <Box  textAlign='center' alignItems='center' justifyContent='center'>
        {/*<Flex flexDirection='row' alignItems="center" justifyContent="center" mb={1}>
          <Link href='https://twitter.com/' isExternal mx='3'><SiTwitter size={25}/></Link>
          <Link href='https://www.linkedin.com/' isExternal mx='3'><BsLinkedin size={25}/></Link>
          <Link href='https://medium.com/' isExternal mx='3'><AiFillMediumCircle size={25}/></Link>
          <Link href='mailto:contact@symbiosemanagement.com' isExternal mx='3'><MdAlternateEmail size={25}/></Link>
        </Flex>*/}
        <Flex flexDirection='row' alignItems="center" justifyContent="center" mb={1} wrap='wrap'>
          <a href={getLegalFilePath('terms-and-conditions.html')} target="_blank" rel="noopener noreferrer">
            <HStack><Text>{t('footerTermsAndConditions')}</Text><FiExternalLink/></HStack>
          </a>
          <Box w='20px'/>
          <a href={getLegalFilePath('legal-mentions.html')} target="_blank" rel="noopener noreferrer">
            <HStack><Text>{t('footerLegalMentions')}</Text><FiExternalLink/></HStack>
          </a>
          <Box w='20px'/>
          <a href={getLegalFilePath('privacy-policy.html')} target="_blank" rel="noopener noreferrer">
            <HStack><Text>{t('footerPrivacy')}</Text><FiExternalLink/></HStack>
          </a>
          <Box w='20px'/>
          <a href={getLegalFilePath('cookie-policy.html')} target="_blank" rel="noopener noreferrer">
            <HStack><Text>{t('footerCookie')}</Text><FiExternalLink/></HStack>
          </a>
          <Box w='20px'/>
          <Link href='mailto:contact@symbiosemanagement.com' isExternal mx='3'><u>{t('footerContactUs')}</u></Link>
        </Flex>           
        <Text>
          {t('footerLegalDisclaimer', {year: new Date().getFullYear()})}
        </Text>
      </Box>
    </Flex>

  )
}