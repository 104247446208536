import { ReactNode, useEffect, useState } from "react";
import { Box, Flex,Text, Icon, useColorModeValue, Link, useMediaQuery, Button, Stack, InputGroup, InputLeftElement, Input, InputRightElement, InputLeftAddon, background, VStack, Divider, Center, Tooltip, HStack, FormLabel, FormControl, FormHelperText, Table, Tbody, Td, Tr, Image, NumberInput, NumberDecrementStepper, NumberInputField, NumberIncrementStepper, useToast, InputRightAddon } from "@chakra-ui/react";
import { axiosConfig, formatDocumentTitle, formatPrice, getBackgroundComponent, getLanguage, getLanguageEntry, getLanguageValue, isHttpCodeInRange, logout } from "../../lib/utils";
import { symbioseTheme } from "../../App";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { errorToast, parseAxiosError, successToast } from "../../lib/utils";
import { URL_ACCOUNT_OTA } from "../../constants";
import { BsFillKeyFill, BsKeyFill } from "react-icons/bs";
import BeatLoader from "react-spinners/BeatLoader";


interface Props {
  emailId? : string
  emailAddress? : string
  otaId : string,
  globalCoolDown? : {value: number | null, setValue(v : number | null) : any},
  _color? : string
}

export default function OTA({emailAddress, emailId, otaId, globalCoolDown: gloabalCoolDown, _color} : Props) { 

  const { t, i18n } = useTranslation()
  const toast = useToast()
  
  const [processing, setProcessing] = useState(false)
  const [_coolDown, _setCoolDown] = useState<number | null>(null)

  const coolDown = {value: gloabalCoolDown ? gloabalCoolDown.value : _coolDown, setValue: gloabalCoolDown ? gloabalCoolDown.setValue : _setCoolDown}
  
  const requestOTA = async function() {
    setProcessing(true)

    try {
      const _email = emailAddress ? emailAddress : (emailId ? (document.getElementById(emailId) as HTMLInputElement).value : null)
      if(!_email || _email.trim().length <= 0) {
        toast(errorToast(null, t('otaInvalidEmail')))
        return
      }
     
      const response = await axios.post(URL_ACCOUNT_OTA, {
        identifier: {email: _email}
      }, axiosConfig(null, i18n))
      
      if(!isHttpCodeInRange(200, response.status)) 
        throw parseAxiosError(response)
      else {
        toast(successToast(t('otaRequestSuccess')))
        coolDown.setValue!(60)
      }
    } catch(error) {
      console.error(error)
      toast(errorToast(null, typeof error == 'string' ? error : t('genericError')))
    } finally {
      setProcessing(false)
    }
  }

  useEffect(() => {
    const interval = setInterval(() => {
      if(coolDown.value && coolDown.value < 0)
        coolDown.setValue(null)
      else if(coolDown.value)
        coolDown.setValue(coolDown.value - 1);
    }, 1030/*+1.8 seconds delay for each 60s*/);

    return () => clearInterval(interval);
  }, [coolDown]);
  


  return (
    <InputGroup>
      <InputLeftAddon
        pointerEvents='none'
        fontSize='1.1em'
        children={<BsKeyFill />}
        bg={'transparent'} color={_color} borderColor={_color}
      />
      <Input id={otaId} bg={'transparent'} color={_color} borderColor={_color} _hover={{borderColor: _color}} type='text' textAlign='center' placeholder={t('ota') as string} autoComplete="off" />
      <InputRightAddon w='75px' p={0} bg={'transparent'} color={_color} borderColor={_color}>
        <Button variant={'link'} p={1} w='100%' fontSize='xs' whiteSpace='break-spaces' wordBreak='break-word' isLoading={processing} disabled={!(!coolDown.value && !processing)} spinner={<BeatLoader size={8} />} onClick={requestOTA} >
          {coolDown.value ?
          <Text bg={'transparent'} color={_color} fontSize='md'>{coolDown.value} s</Text>
          :
          <Text bg={'transparent'} color={_color}>{t('otaRequestButton')}</Text>
          }
        </Button>
      </InputRightAddon>
    </InputGroup>
  )
}