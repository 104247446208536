import { ReactNode, useEffect, useState } from "react";
import { Box, Flex, Text, Icon, useColorModeValue, Image, VStack, HStack, Link, useColorMode, Button, useMediaQuery, Table, Tbody, Tr, Td, Accordion, AccordionItem, AccordionIcon, AccordionPanel, AccordionButton, position, FormControl, FormLabel, Input, FormHelperText, useToast } from "@chakra-ui/react";

import Header from "../../common/Header";
import { axiosConfig, errorToast, formatDocumentTitle, getBackgroundComponent, getHomeBackgroundComponent, isHttpCodeInRange, parseAxiosError, string2NullIfEmpty, successToast, tryClearInput, tryGetClientIp } from "../../../lib/utils";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import { useTranslation } from "react-i18next";
import { Trans } from 'react-i18next'
import { gaPageView } from "../../../lib/utils";

import businessBackground from '../../../img/forest/business-bg.jpg';
import businessBackgroundTiny from '../../../img/forest/business-bg-tiny.jpg';
import { symbioseTheme } from "../../../App";
import { GiEarthAfricaEurope, GiEarthAmerica, GiEcology, GiForest, GiGreenhouse, GiReceiveMoney } from "react-icons/gi";
import { ImEarth, ImNewspaper, ImUserTie } from "react-icons/im";
import { MdAccountBalance, MdSavings } from "react-icons/md";
import { BsFillFileEarmarkBarGraphFill, BsFillTreeFill } from "react-icons/bs";
import { RiPlantLine } from "react-icons/ri";
import ProgressiveImage from "react-progressive-graceful-image";

import axios from "axios";
import { URL_ACCOUNT_LOGIN, URL_SEND_CONTACT_FORM } from "../../../constants";


const htmlId = function(id : string) {
  return "ABOUTBUSINESS-" + id
}

const CONTACT_NAME_ID = htmlId("contact-name")
const CONTACT_EMAIL_ID = htmlId("contact-email")
const CONTACT_PHONE_ID = htmlId("contact-phone")
const CONTACT_SUBJECT_ID = htmlId("contact-subject")
const CONTACT_CONTENT_ID = htmlId("contact-content")


export default function AboutBusiness() {
  
  const navigate = useNavigate()
  const cookies = new Cookies()
  const { colorMode } = useColorMode()
  const [isLargerThan1024] = useMediaQuery('(min-width: 1024px)')
  const [isLargerThan512] = useMediaQuery('(min-width: 512px)')
  const { t, i18n } = useTranslation()
  const toast = useToast()
  const [processing, setProcessing] = useState(false)

  const hasAccount = cookies.get('account') ? true : false
  document.title = formatDocumentTitle(t('navbarAboutRetail'))

  useEffect(() => {
    gaPageView(cookies)
  }, [])
  

  const sendContactForm = async function() {

    const name = string2NullIfEmpty((document.getElementById(CONTACT_NAME_ID) as HTMLInputElement)?.value)
    const email = string2NullIfEmpty((document.getElementById(CONTACT_EMAIL_ID) as HTMLInputElement)?.value)
    const phone = string2NullIfEmpty((document.getElementById(CONTACT_PHONE_ID) as HTMLInputElement)?.value)
    const subject = string2NullIfEmpty((document.getElementById(CONTACT_SUBJECT_ID) as HTMLInputElement)?.value)
    const content = string2NullIfEmpty((document.getElementById(CONTACT_CONTENT_ID) as HTMLInputElement)?.value)
    

    setProcessing(true)
    try {

      if(!name || name.trim().length <= 0
        || !email || email.trim().length <= 0
        /*|| !phone || phone.trim().length <= 0*/
        || !subject || subject.trim().length <= 0
        || !content || content.trim().length <= 0) {
        toast(errorToast(null, t('aboutBusinessPageMissingFields')))
        return
      }
      
      const clientIp = await tryGetClientIp();
      const aconf = axiosConfig(null, i18n)
      aconf.headers['X-Forwarded-For'] = clientIp      

      const response = await axios.post(URL_SEND_CONTACT_FORM, {
        sender: name,
        contact: {
          email: email,
          phone: phone
        },
        subject: subject,
        content: content
      }, aconf)
      
      if(!isHttpCodeInRange(200, response.status)) 
        throw parseAxiosError(response)
      else {
        toast(successToast(t('contactFormSuccessMsg')))
        tryClearInput(CONTACT_NAME_ID)
        tryClearInput(CONTACT_EMAIL_ID)
        tryClearInput(CONTACT_PHONE_ID)
        tryClearInput(CONTACT_SUBJECT_ID)
        tryClearInput(CONTACT_CONTENT_ID)
      }
    } catch(error) {
      console.error(error)
      toast(errorToast(null, typeof error == 'string' ? error : t('genericError')))
    } finally {
      setProcessing(false)
    }
  }
  
  
  const forestHeight = isLargerThan512 ? '600px' : '300px'
  const forestFontSize = isLargerThan512 ? 'xl' : 'md'
  const forestFontWeight = isLargerThan512 ? 'bold' : 'normal'
  const forestMargin = isLargerThan512 ? 10 : 0
  const forestPadding = isLargerThan512 ? 3 : 3


  const pitchMsgMaxWidth = isLargerThan512 ? '80%' : '90%'
  const picthLogoTitleSize = isLargerThan512 ? 'md' : 'sm'
  const picthLogoTitleWidth = isLargerThan512 ? '250px' : '150px'
  const picthLogoSize = isLargerThan512 ? 70 : 35
  const picthLogoMargin = isLargerThan512 ? 3 : 3
  const picthLogoPadding = isLargerThan512 ? 2 : 2

  const sectionBrickHeight = isLargerThan512 ? '400px' : '200px'
  const sectionBrickTitleSize = isLargerThan512 ? '60' : '3xl'
  const sectionBrickMsgSize = isLargerThan512 ? 'xl' : 'md'
  const sectionBrickMinSpacing = 10
  const sectionBrickPadding = isLargerThan512 ? 5 : 2
  
  const sectionMsgHeight = isLargerThan512 ? '250px' : '250px'
  const sectionMsgTitleSize = isLargerThan512 ? '30' : 'xl'
  const sectionMsgMsgSize = isLargerThan512 ? 'xl' : 'md'
  const sectionMsgPaddingX = isLargerThan512 ? '12%' : '5%'
  const sectionMsgTopMaxPadding = isLargerThan512 ? 7 : 5
  const sectionMsgRightPadding = isLargerThan512 ? '5px 50px 5px 5px' : '2px 10px 2px 2px'
  const sectionMsgLeftPadding = isLargerThan512 ? '5px 5px 5px 50px' : '2px 2px 2px 10px'
  const contactFormSectionWidth = isLargerThan512 ? 500 : '90%'


  const faqWidth = isLargerThan512 ? '60%' : '90%'
  
  return (
    <>
    {getBackgroundComponent()}
    <Header />
    <Flex flexDirection="column" alignItems="center" justifyContent="center" textAlign="center" width="100%">
      <VStack position='relative' spacing={5} w='100%' h={forestHeight} alignItems="center" justifyContent="center">
        <ProgressiveImage 
        src={businessBackground} placeholder={businessBackgroundTiny}
        >
          {(src, loading) => (
            <img style={{ opacity: loading ? 1 : 1, objectFit: 'cover', zIndex: -1, position: 'absolute', width: '100%', height: forestHeight}}  src={src} alt="Business" />
          )}
        </ProgressiveImage>
        <Box zIndex={10} m={forestMargin} p={forestPadding} bg={symbioseTheme.colors.textBackgroundColor} fontSize={forestFontSize} maxW={isLargerThan1024 ? '1024px' : ''}>
          <Text fontWeight={forestFontWeight} w='100%'><Trans>{t('aboutBusinessPageTopMsg')}</Trans></Text>
        </Box>
      </VStack>
      <Flex flexDirection="column" w='100%' minHeight={sectionBrickHeight} p={sectionBrickPadding} color={symbioseTheme.colors.textBackgroundColor} bg={symbioseTheme.colors.headerColor} alignItems="center" justifyContent="center">
        <Box flexGrow={1} />
        <Text fontSize={sectionBrickTitleSize} fontWeight='bold'>{t('aboutBusinessPagePitchTitle')}</Text>
        <Box flexGrow={1} minH={sectionBrickMinSpacing} />
        <Text fontSize={sectionBrickMsgSize} maxW={pitchMsgMaxWidth}><Trans>{t('aboutBusinessPagePitchMsg')}</Trans></Text>
        <Box flexGrow={1} minH={sectionBrickMinSpacing} />
        <Flex flexDirection="row" alignItems="center" justifyContent="center" wrap='wrap'>
          <VStack m={picthLogoMargin}>
            <Text w={picthLogoTitleWidth} fontSize={picthLogoTitleSize} fontWeight='bold'>{t('aboutBusinessPagePitchBrick1Title')}</Text>
            <Box bg={symbioseTheme.colors.textBackgroundColor} p={picthLogoPadding} color={symbioseTheme.colors.textColor}>
              {<BsFillFileEarmarkBarGraphFill size={picthLogoSize}/>}
            </Box>
          </VStack>
          <VStack m={picthLogoMargin}>
            <Text w={picthLogoTitleWidth} fontSize={picthLogoTitleSize} fontWeight='bold'>{t('aboutBusinessPagePitchBrick2Title')}</Text>
            <Box bg={symbioseTheme.colors.textBackgroundColor} p={picthLogoPadding} color={symbioseTheme.colors.textColor}>
              {<ImNewspaper size={picthLogoSize}/>}
            </Box>
          </VStack>
          <VStack m={picthLogoMargin}>
            <Text w={picthLogoTitleWidth} fontSize={picthLogoTitleSize} fontWeight='bold'>{t('aboutBusinessPagePitchBrick3Title')}</Text>
            <Box bg={symbioseTheme.colors.textBackgroundColor} p={picthLogoPadding} color={symbioseTheme.colors.textColor}>
              {<BsFillTreeFill size={picthLogoSize}/>}
            </Box>
          </VStack>
        </Flex>
        <Box flexGrow={1} minH={sectionBrickMinSpacing} />
      </Flex>

      <Flex flexDirection="column" w='100%' minHeight={sectionMsgHeight} p={sectionMsgRightPadding} bg={symbioseTheme.colors.textBackgroundColor} alignItems="right" justifyContent="right" textAlign="right">
        <Box flexGrow={1} maxH={sectionMsgTopMaxPadding} />
        <Text fontSize={sectionMsgTitleSize} fontWeight='bold' px={sectionMsgPaddingX}>{t('aboutBusinessPageSection1Title')}</Text>
        <Box flexGrow={1} />
        <Text fontSize={sectionMsgMsgSize} px={sectionMsgPaddingX}>{t('aboutBusinessPageSection1Content')}</Text>
        <Box flexGrow={1} />
      </Flex>
      <Flex flexDirection="column" w='100%' minHeight={sectionMsgHeight} p={sectionMsgLeftPadding} color={symbioseTheme.colors.headerTextColor} bg={symbioseTheme.colors.headerColor} alignItems="left" justifyContent="left" textAlign="left">
        <Box flexGrow={1} maxH={sectionMsgTopMaxPadding} />
        <Text color={symbioseTheme.colors.textBackgroundColor} fontSize={sectionMsgTitleSize} fontWeight='bold' px={sectionMsgPaddingX}>{t('aboutBusinessPageSection2Title')}</Text>
        <Box flexGrow={1} />
        <Text fontSize={sectionMsgMsgSize} px={sectionMsgPaddingX}>{t('aboutBusinessPageSection2Content')}</Text>
        <Box flexGrow={1} />
      </Flex>
      <Flex flexDirection="column" w='100%' minHeight={sectionMsgHeight} p={sectionMsgRightPadding} bg={symbioseTheme.colors.textBackgroundColor} alignItems="right" justifyContent="right" textAlign="right">
        <Box flexGrow={1} maxH={sectionMsgTopMaxPadding} />
        <Text fontSize={sectionMsgTitleSize} fontWeight='bold' px={sectionMsgPaddingX}>{t('aboutBusinessPageSection3Title')}</Text>
        <Box flexGrow={1} />
        <Text fontSize={sectionMsgMsgSize} px={sectionMsgPaddingX}>{t('aboutBusinessPageSection3Content')}</Text>
        <Box flexGrow={1} />
      </Flex>
      <Flex flexDirection="column" w='100%' minHeight={sectionMsgHeight} color={symbioseTheme.colors.headerTextColor} bg={symbioseTheme.colors.headerColor} alignItems="center" justifyContent="center" textAlign="center">
        <Box flexGrow={1} minH={sectionMsgTopMaxPadding} />
        <Box w={contactFormSectionWidth}>
          <a href="/contact">
            <Button variant='outline'>{t('contactButton')}</Button>
          </a>
        </Box>        
        <Box flexGrow={1} minH={100}/>
      </Flex>
    </Flex>
  </>
  )
}