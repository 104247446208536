import { ReactNode, useEffect, useState } from "react";
import { Box, Flex,Text, Icon, useColorModeValue, Link, useMediaQuery, Button, Stack, InputGroup, InputLeftElement, Input, InputRightElement, InputLeftAddon, background, VStack, Divider, Center, Tooltip, HStack, FormLabel, FormControl, FormHelperText, Table, Tbody, Td, Tr, Image, NumberInput, NumberDecrementStepper, NumberInputField, NumberIncrementStepper, useToast, InputRightAddon, PinInput, PinInputField, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter } from "@chakra-ui/react";
import { axiosConfig, formatDocumentTitle, formatPrice, getBackgroundComponent, getLanguage, getLanguageEntry, getLanguageValue, isHttpCodeInRange, logout, parsePinInput } from "../../lib/utils";
import { symbioseTheme } from "../../App";
import Cookies from 'universal-cookie';
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { errorToast, parseAxiosError, successToast } from "../../lib/utils";
import BeatLoader from "react-spinners/BeatLoader";
import TFA from "./TFA";


const htmlId = function(id : string) {
  return "TFAMODAL-" + id
}

const TFA_ID = htmlId("tfa-code")

interface Props {
  caller?: any
  isOpen : boolean
  onClose() : any
  on2FA(tfa : string | null, caller: any) : any,
  _color? : string,
  _bg? : string
  buttonColor? : string,
  buttonBg? : string
}

export default function TFAModal({caller: caller = null, isOpen: isOpen, onClose: onClose, on2FA: on2FA, _color, _bg,  buttonColor, buttonBg} : Props) { 

  const navigate = useNavigate()
  const cookies = new Cookies()
  const { t, i18n } = useTranslation()
  const [isLargerThan1024] = useMediaQuery('(min-width: 1024px)')
  const toast = useToast()

  const [processing, setProcessing] = useState(false)

  const validate = function () {
    try{
      setProcessing(true)
      const tfa = parsePinInput(TFA_ID)
      on2FA(tfa, caller)
    }catch(error) {
      console.error(error)
      toast(errorToast(null, typeof error == 'string' ? error : t('genericError')))
    } finally {
      setProcessing(false)
      onClose()
    }
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} motionPreset='slideInBottom' isCentered>
      <ModalOverlay />
      <ModalContent bg={_bg} color={_color} borderColor={_color}>
        <ModalHeader></ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack>
          <TFA _bg={_bg} _color={_color} tfaId={TFA_ID} onEnter={validate} />
          </VStack>          
        </ModalBody>
        <ModalFooter>
          <Button isLoading={processing} disabled={processing} color={buttonColor} bg={buttonBg} spinner={<BeatLoader size={8} />} onClick={validate}>{t('2faValidateButton')}</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}