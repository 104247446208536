import { Box, Flex,Text, Icon, useColorModeValue, Link, useMediaQuery, Button, Stack, InputGroup, InputLeftElement, Input, InputRightElement, InputLeftAddon, background, VStack, Divider, Center, Tooltip, HStack, FormLabel, FormControl, FormHelperText, Table, Tbody, Td, Tr, Image, NumberInput, NumberDecrementStepper, NumberInputField, NumberIncrementStepper, PlacementWithLogical } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

const htmlId = function(id : string) {
  return "CARTITEM-" + id
}

interface Props {
  imgUrl : any,
  title : string
  price : string,
  tooltipPlacement? : PlacementWithLogical
}

export default function CartItem({imgUrl, title, price, tooltipPlacement = 'left'} : Props) { 

  const { t, i18n } = useTranslation()
  const [isLargerThan1024] = useMediaQuery('(min-width: 1024px)')
  const [isTalerThan512] = useMediaQuery('(min-height: 512px)')


  return (
    <VStack w='100%'>
      <Tooltip label={tooltipPlacement ? title : null} openDelay={500} closeDelay={500} placement={tooltipPlacement} hasArrow >
        <Box w='100%' h='50px' position='relative'>
          <Image 
            filter='blur(1px)'
            opacity={0.6}
            w='100%' h='100%'
            display='block'
            objectFit='cover'
            src={imgUrl}
            alt={title}
          />
          <Box position='absolute' w='100%' top='30%'   >
            <Text fontSize='md' fontWeight='bold'  overflow='hidden' textOverflow='ellipsis' whiteSpace='nowrap'>{title}</Text>
          </Box>
        </Box>
      </Tooltip>
      <Text fontSize='xs' fontWeight='bold'>{price}</Text>
    </VStack>
  )
}