
import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import * as ReactDOMClient from 'react-dom/client';
import { ColorModeScript } from '@chakra-ui/react'
import './index.css';
import App from "./App"
import theme from './theme'
import './fonts/manaspc.ttf';
import './i18n';


/*ReactDOM.render(
  <React.StrictMode>
      <ColorModeScript initialColorMode={theme.config.initialColorMode} />
      <App />
  </React.StrictMode>,
  document.getElementById("root")
);*/

// After


const container = document.getElementById('app');
const root = ReactDOMClient.createRoot(container!); // createRoot(container!) if you use TypeScript
root.render(
  <>
    <ColorModeScript initialColorMode={theme.config.initialColorMode} />
    <App />
  </>,
);
