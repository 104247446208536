import { ReactNode, useEffect, useState } from "react";
import { Box, Flex,Text, Icon, useColorModeValue, Link, useMediaQuery, Button, Stack, InputGroup, InputLeftElement, Input, InputRightElement, InputLeftAddon, background, VStack, Divider, Center, Tooltip, HStack, FormLabel, FormControl, FormHelperText, Table, Tbody, Td, Tr, Image, NumberInput, NumberDecrementStepper, NumberInputField, NumberIncrementStepper } from "@chakra-ui/react";
import { CheckIcon, EmailIcon, InfoIcon, LockIcon, PhoneIcon, QuestionOutlineIcon, ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import { symbioseTheme } from "../../../App";
import { useTranslation } from "react-i18next";


interface Props {
  passwordId : string,
  _color? : string
  _bg? : string
}

export default function SigninPassword({passwordId, _color} : Props) { 

  const { t, i18n } = useTranslation()
  const [showPassword, setShowPassword] = useState(false)
  
  return (
    <InputGroup>
      <InputLeftAddon
        pointerEvents='none'
        fontSize='1em'
        children={<LockIcon color={_color}/>}
        bg={'transparent'} color={_color} borderColor={_color}
      />
      <Input id={passwordId} pr='4.5rem' bg={'transparent'} color={_color} borderColor={_color} _hover={{borderColor: _color}} type={showPassword ? 'text' : 'password'} placeholder={t('loginSigninPassword') as string} />
      <InputRightElement width='4.5rem' color={_color}>
        {showPassword ?
          <ViewOffIcon h='1.75rem' onClick={() => setShowPassword(!showPassword)} />
        :
          <ViewIcon h='1.75rem' onClick={() => setShowPassword(!showPassword)} />
        }              
      </InputRightElement>
    </InputGroup>
  )
}