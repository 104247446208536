import { ReactNode, useEffect, useState } from "react";
import { Box, Flex,Text, Icon, useColorModeValue, Link, useMediaQuery, Button, Stack, InputGroup, InputLeftElement, Input, InputRightElement, InputLeftAddon, background, VStack, Divider, Center, Tooltip, HStack, FormLabel, FormControl, FormHelperText, Tbody, Th, Tr, Thead, Table, Td, useToast, LinkBox, Image, layout, useRangeSlider } from "@chakra-ui/react";
import { CheckIcon, EmailIcon, LockIcon, PhoneIcon, QuestionOutlineIcon } from "@chakra-ui/icons";
import { axiosConfig, checkAuth, errorToast, formatDocumentTitle, formatPrice, getBackgroundComponent, getLanguageValue, isHttpCodeInRange, isValidCurrency, logout, parseAxiosError } from "../../../lib/utils";
import { symbioseTheme } from "../../../App";
import axios from "axios";
import Cookies from 'universal-cookie';
import { URL_ACCOUNT_INVOICES, URL_MARKETPLACE_CHECKOUT, URL_PAYMENT_GATEWAYS_INFO } from "../../../constants";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "../../common/Header";
import { Chart } from "react-google-charts";
import { GiConsoleController } from "react-icons/gi";
import { useTranslation } from "react-i18next";
import Loading from "../../common/Loading";
import CartItem from "../marketplace/CartItem";
import { off } from "process";
import PaymentItem from "./PaymentItem";
import {loadStripe, StripeElementLocale, Appearance} from '@stripe/stripe-js';
import { PaymentElement} from '@stripe/react-stripe-js';
import {Elements} from '@stripe/react-stripe-js';
import StripeGateway from "./StripeGateway";
import Message from "../../common/Message";
import InvoiceTable from "../invoice/InvoiceTable";

const htmlId = function(id : string) {
  return "PAYMENTGATEWAY-" + id
}

export default function PaymentGateway() {
  
  const navigate = useNavigate()
  const cookies = new Cookies()
  const { t, i18n } = useTranslation()
  const [isLargerThan1024] = useMediaQuery('(min-width: 1024px)')
  const [isLargerThan360] = useMediaQuery('(min-width: 360px)')
  const [isTalerThan512] = useMediaQuery('(min-height: 512px)')
  const toast = useToast()
  const location = useLocation()

  const [invoice, setInvoice] = useState<{invoice : any, error : boolean} | null>(null)
  const [paymentConfig, setPaymentConfig] = useState<{stripe : {publicKey : string, stripeInstance: any}} | null>(null)

  const parseParams = () : {invoiceId : string, paymentGateway : any} | null => {
    try{
      if(!location.state || !location.state.invoiceId || !location.state.payment || !location.state.payment.gateway)
        return null

      let paymentConfig = null
      switch(location.state.payment.gateway) {
        case 'Stripe':
            const clientSecret = location.state.payment?.data?.clientSecret
            if(!clientSecret)
              throw "Client secret not set"
          break
        default:
          throw "Unknown payment gateway '"+location.state.payment.gateway+"'"
      }
      return {invoiceId: location.state.invoiceId, paymentGateway: {type: location.state.payment.gateway, data: {...location.state.payment.data}}}
    } catch(error) {
      console.error(error)
      toast(errorToast(null, typeof error == 'string' ? error : t('genericError')))
      return null
    }
  }
  
  const params = parseParams()
  const invoiceId = params?.invoiceId
  const paymentGateway = params?.paymentGateway
   
  
  document.title = formatDocumentTitle(t('paymentGatewayPageTitle', {id: invoiceId ? invoiceId : ""}))

  const loadPaymentConfig = async () => {
    try {
      const account = cookies.get('account')
      const response = await axios.get(URL_PAYMENT_GATEWAYS_INFO, axiosConfig(account.token, i18n))
      if(response.status == 401) {
        logout(cookies, navigate)
        return
      }

      if(!isHttpCodeInRange(200, response.status))
        throw parseAxiosError(response)
   
      setPaymentConfig({stripe : {publicKey: response.data.stripe.publicKey, stripeInstance: await loadStripe(response.data.stripe.publicKey)}})

    } catch(error) {
      console.error(error)
      toast(errorToast(null, typeof error == 'string' ? error : t('genericError')))
      setPaymentConfig(null)
    }
  }

  const fetchInvoice = async () => {
    try {
      if(!params) {
        console.error('params is not set')
        toast(errorToast(null, t('genericError')))
        return
      }
      const account = cookies.get('account')

      const response = await axios.post(URL_ACCOUNT_INVOICES, {invoiceIds: [invoiceId]}, axiosConfig(account.token, i18n))

      if(isHttpCodeInRange(200, response.status)) {
        const _invoice = response.data?.invoices?.[0]
        const payable = _invoice && _invoice.settlement?.status == 'Pending'
        setInvoice({invoice: _invoice ? _invoice : null, error: !payable })
      } else if(response.status == 401) {
        logout(cookies, navigate)
        return
      } else 
        throw parseAxiosError(response)

    } catch(error) {
      console.error(error)
      toast(errorToast(null, typeof error == 'string' ? error : t('genericError')))
      setPaymentConfig(null)
    }
  }
  

  useEffect(() => {
    const load = async () => {
      const auth = await checkAuth(cookies, navigate);
      if(auth.error)
        toast(errorToast(null, t('genericError')))
      else if (auth.auth) {
        if(params) {
          await loadPaymentConfig()
          await fetchInvoice()
        }
      }
    }
    load()
  }, [])
 

  const containerWidth = isLargerThan1024 ? '75%' : (isLargerThan360 ? '90%' : '100%')
  const containerColor = symbioseTheme.colors.headerTextColor
  const containerBg = symbioseTheme.colors.headerColor 
  const checkoutTitleMarginY = isLargerThan1024 ? '40px' : '40px'
  const boxBorderRadius = 7
  
  const paymentBorder ='none'// isLargerThan1024 ? '1px solid' : 'none'
  const paymentMargin = isLargerThan1024 ? 5 : 0
  const paymentPadding = isLargerThan1024 ? 10 : 2


  const invoiceMargin = isLargerThan1024 ? 5 : (isLargerThan360 ? 2 : 0)
  const invoicePadding = isLargerThan1024 ? 10 : (isLargerThan360 ? 5 : 2)

  return (
    <>
    {getBackgroundComponent()}
    <Header />
    {params && paymentConfig && invoice && !invoice.error ?
    <Flex flexDirection="column" alignItems="center" justifyContent="center" textAlign="center" color={containerColor} bg={containerBg}>
      <HStack my={checkoutTitleMarginY} >
        <Text fontSize='2xl' fontWeight='bold' color={symbioseTheme.colors.textColorRed}> {t('paymentTotal') + ":"}&nbsp;{formatPrice(invoice.invoice.afterTaxTotal.currency, invoice.invoice.afterTaxTotal.fractionAmount)}</Text>
      </HStack>
     
      <VStack w={containerWidth} >
        <VStack  border={paymentBorder} m={paymentMargin}  p={paymentPadding} borderRadius={boxBorderRadius}>
          <Elements stripe={paymentConfig.stripe.stripeInstance}
                     options={
              {
                clientSecret: params.paymentGateway.data.clientSecret,
                locale: i18n.language as StripeElementLocale,
                appearance: {theme: 'stripe'}
              }
            }
            >
            <StripeGateway invoiceId={invoice.invoice.id}/>
          </Elements>
        </VStack>
        <VStack border='1px solid' m={invoiceMargin}  p={invoicePadding} borderRadius={boxBorderRadius}>
            <InvoiceTable invoice={invoice.invoice}/>
        </VStack>             
      </VStack>
      <Box h='40px'/>
    </Flex>
    :
    <>
      {!params ?
      <Message _color={containerColor} _bg={containerBg} message={t('paymentEmptyCart')} _navigate={{url: '/marketplace', message: t('paymentGoToMarketplace')}}/>
      :
      <>
        {invoice && invoice.error ?
        <Message _color={containerColor} _bg={containerBg} message={t('paymentInvoiceError')} _navigate={{url: '/marketplace', message: t('paymentGoToMarketplace')}}/>
        :
        <Loading _color={containerColor} _bg={containerBg} message={t('paymentGatewayLoading')}/>
        }
      </>
      }
    </>
    }    
    </>
  )
}