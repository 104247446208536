import { Box, Flex, HStack, Spinner, Table, Tbody, Td, Text, Th, Thead, Toast, Tr, useToast, VStack } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { symbioseTheme } from "../../../App";
import { computeInvoiceTotal, errorToast, formatDatetime, formatPrice, isInvoicePaid } from "../../../lib/utils";
import Message from "../../common/Message";

interface Props {
  invoice : any
}


export default function InvoiceTable ({invoice} : Props) {
  const { t, i18n } = useTranslation()
  const toast = useToast()

  const total = computeInvoiceTotal(invoice)
  if(!total)
    toast(errorToast(null, t('genericError')))

  const firstColumnWidth = '60%'
  const majorFontSize = 'lg'
  const minorFontSize = 'xs'
  
  return (
    <>
    {total ?
      <Box p={2} m={2} maxW='100%' wordBreak='break-all'>
        <VStack spacing={5}>
          <Text textAlign='center' w='100%' wordBreak='break-word'><b>{t('invoiceTableIdTitle', {id: invoice.id}) + (isInvoicePaid(invoice.settlement.status) ? "" : "  (" + t('invoiceTableUnpaid') + ")")}</b></Text>
        </VStack>
        <VStack textAlign='left' w='100%' mt='20px'>
          <Text textAlign='left' w='100%'>{t('invoiceTableId') + ": " + invoice.id}</Text>
          <Text textAlign='left' w='100%'>{t('invoiceTableCustomerId') + ": "}{invoice.customer.id}</Text>
          <Text textAlign='left' w='100%'>{t('invoiceTableCustomerEmail') + ": "}{invoice.customer.email.address}</Text>
          <Text textAlign='left' w='100%'>{t('paymentTotal') + ":"}&nbsp;{total.price}</Text>
          <Text textAlign='left' w='100%'>{t('invoiceTableSettlementCreationTime') + ": "}{formatDatetime(invoice.creationTime)}</Text>
          <Text textAlign='left' w='100%'>{t('invoiceTableSettlementCompletionTime') + ": "}{formatDatetime(invoice.settlement.completionTime, t('invoiceTableUnpaid'))}</Text>
          <Text textAlign='left' w='100%'>{t('invoiceTableSettlementPaymentGateway') + ": "}{invoice.settlement.paymentGateway}</Text>
          <Text textAlign='left' w='100%'>{t('invoiceTableSettlementTransactionReference') + ": "}{invoice.settlement.transactionReference}</Text>
          <Text textAlign='left' w='100%'>{t('invoiceTableSettlementStatus') + ": "}{isInvoicePaid(invoice.settlement.status) ? t('invoiceTablePaid') : t('invoiceTableUnpaid')}</Text>
        </VStack>
        {(invoice.items as any []).map((o, i)=>{
          return (
            <Box key={i} w='100%' p={3} mt={3} border='1px solid' borderRadius={5}>
              <Table variant='unstyled' size={'xs'}>
                <Tbody>
                  <Tr>
                    <Td textAlign='left' wordBreak='break-word' fontSize={majorFontSize} maxW={firstColumnWidth} w={firstColumnWidth}>{o.quantity}{" x "}{o.name}</Td>
                    <Td textAlign='right' wordBreak='break-word' fontSize={majorFontSize}>{formatPrice(o.unitAfterTaxPrice.currency, o.unitAfterTaxPrice.fractionAmount)}</Td>
                  </Tr>
                  <Tr>
                    <Td textAlign='left' fontSize={minorFontSize}>{t('invoiceTableProductReference')}&nbsp;{o.reference}</Td>
                    <Td textAlign='right' wordBreak='break-word'fontSize={minorFontSize}>{t('invoiceTableTaxRate')}&nbsp;{(o.taxRateBps/100).toFixed(2) + "%"}</Td>
                  </Tr>
                </Tbody>
              </Table>
            </Box>
          )
        })}
        <Box w='100%' p={3} mt={3} border='1px solid' borderRadius={5}>
          <Table variant='unstyled' size={'xs'}>
            <Tbody>
              <Tr fontSize={majorFontSize}>
                <Td textAlign='left' wordBreak='break-word' maxW={firstColumnWidth} w={firstColumnWidth}>{t('invoiceTableTotalItemQuantity')}</Td>
                <Td textAlign='right' wordBreak='break-word'>{total.quantity}</Td>
              </Tr>
              <Tr fontSize={majorFontSize} fontWeight='bold' color={symbioseTheme.colors.textColorRed}>
                <Td textAlign='left' wordBreak='break-word' maxW={firstColumnWidth} w={firstColumnWidth}>{t('paymentTotal')}</Td>
                <Td textAlign='right' wordBreak='break-word'>{total.price}</Td>
              </Tr>
            </Tbody>
          </Table>      
        </Box>
      </Box>
    :
      <Message message={t('genericError')} />
    }
    </>
  )
}
