import { ReactNode, useEffect, useState } from "react";
import { Box, Flex,Text, Icon, useColorModeValue, Link, useMediaQuery, Button, Stack, InputGroup, InputLeftElement, Input, InputRightElement, InputLeftAddon, background, VStack, Divider, Center, Tooltip, HStack, FormLabel, FormControl, FormHelperText, Table, Tbody, Td, Tr, Image, NumberInput, NumberDecrementStepper, NumberInputField, NumberIncrementStepper, SimpleGrid, Switch, useToast, useDisclosure } from "@chakra-ui/react";
import { CheckIcon, EmailIcon, InfoIcon, LockIcon, PhoneIcon, QuestionOutlineIcon, ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import { axiosConfig, errorToast, formatDocumentTitle, formatPrice, getBackgroundComponent, getLanguage, getLanguageEntry, getLanguageValue, is2FARequired, isHttpCodeInRange, parseAxiosError, parsePinInput, string2NullIfEmpty, successToast, tryClearInput } from "../../../../lib/utils";
import { symbioseTheme } from "../../../../App";
import axios from "axios";
import Cookies from 'universal-cookie';
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { AiOutlineLock } from "react-icons/ai";
import { IconType } from "react-icons";
import SigninPassword from "../../login/SigninPassword";
import SignupPasswords from "../../login/SignupPasswords";
import BeatLoader from "react-spinners/BeatLoader";
import { URL_ACCOUNT_2FA, URL_ACCOUNT_UPDATE } from "../../../../constants";
import OTA from "../../../common/OTA";
import QRCode from "react-qr-code";
import TFA from "../../../common/TFA";
import TFAModal from "../../../common/TFAModal";

const htmlId = function(id : string) {
  return "SETTINGSECURITY-" + id
}

const NEW_PWD_ID = htmlId("new-pwd")
const NEW_PWD2_ID = htmlId("new-pwd-2")
const PWD_OTA_ID = htmlId("pwd-ota")

const TFA_OTA_ID = htmlId("tfa-ota")
const TFA_ID = htmlId("tfa")

interface Props {
  accountInfo : any,
  refreshAccountInfo() : any,
  _color? : string,
  _bg? : string
}

export default function Security({accountInfo, refreshAccountInfo, _color, _bg} : Props) { 

  const navigate = useNavigate()
  const cookies = new Cookies()
  const { t, i18n } = useTranslation()
  const [isLargerThan1024] = useMediaQuery('(min-width: 1024px)')
  const [isTalerThan512] = useMediaQuery('(min-height: 512px)')
  const toast = useToast()
  const { isOpen, onOpen, onClose } = useDisclosure()

  const [processing, setProcessing] = useState(false)
  const [activable2FA, setActivable2FA] = useState<any | null>(null)
  const [otaCoolDown, setOtaCoolDown] = useState<number | null>(null)
 

  const updatePwd = async function(tfa : string | null = null) {
    const account = cookies.get('account')
    const pwd = document.getElementById(NEW_PWD_ID) as HTMLInputElement
    const pwd2 = document.getElementById(NEW_PWD2_ID) as HTMLInputElement
    setProcessing(true)
    try {
      
      if(!pwd.value || pwd.value.trim().length <= 0
        || !pwd2.value || pwd2.value.trim().length <= 0) {
        toast(errorToast(null, t('loginSignUpMissingFields')))
        return
      }

      if(pwd.value != pwd2.value) {
        toast(errorToast(null, t('loginSignUpPasswordMismatch')))
        return
      }

      const ota = document.getElementById(PWD_OTA_ID) as HTMLInputElement
      const otaInput =  string2NullIfEmpty(ota?.value)
      if(!otaInput) {
        toast(errorToast(null, t('otaTokenMissing')))
        return
      }

      const response = await axios.post(URL_ACCOUNT_UPDATE, 
        {
          identifier: {accountId: account?.id},
          auth: {
            ota: otaInput,
            twoFactor: tfa
          },
          updates: {auth: {pwd: pwd.value}}
        },
        axiosConfig(account?.token, i18n)
      )

      if(is2FARequired(response)) {
        onOpen()
      } else if(response.status == 401) {
        tryClearInput(PWD_OTA_ID)
        if(response.data.error)
          toast(errorToast(null, response.data.error))
        else {
          toast(errorToast(null, t('genericError')))
          navigate('/')
          return
        }
      } else if(!isHttpCodeInRange(200, response.status)){
        tryClearInput(PWD_OTA_ID)
        throw parseAxiosError(response)
      } else {
        toast(successToast(null, t('resetPasswordSuccessToast')))
        tryClearInput(NEW_PWD2_ID)
        tryClearInput(NEW_PWD_ID)
        tryClearInput(PWD_OTA_ID)
        refreshAccountInfo()  
      }
      
    } catch(error) {
      console.error(error)
      toast(errorToast(null, typeof error == 'string' ? error : t('genericError')))
    } finally {
      setProcessing(false)
    }
  }

  const tfaStatus = function() : number {
    switch(accountInfo.authenticationInfo.twoFactorAuthenticationStatus) {
      case 'Disabled':
      case 'ActivationExpired':
        return -1
      case 'Enabled':
        return 1
      case 'ActivationPending':
        return 0
      default:
        alert('Invalid 2FA status')
        return -1
    }
  }

  const tfaStatusLabel = function() : string {
    const status = tfaStatus()
    if(status > 0)
      return t('settings2FAStatusEnabled')
    else if(status < 0)
      return t('settings2FAStatusDisabled')
    else if(!activable2FA)
      return t('settings2FAStatusDisabled')
    return t('settings2FAStatusActivationPending')
  }

  const manage2FA = async function(enable : boolean) {

    const account = cookies.get('account')
    setProcessing(true)
    try {

      const otaInput =  string2NullIfEmpty((document.getElementById(TFA_OTA_ID) as HTMLInputElement)?.value)
      const tfaInput =  string2NullIfEmpty(parsePinInput(TFA_ID))

      const _tfaStatus = tfaStatus()
      if(_tfaStatus > 0) {
        if(enable) {
          console.error('2FA already enabled')
          return
        } else {
          if(!tfaInput) {
            toast(errorToast(null, t('2faTokenMissing')))
            return
          }
        }
      }

      if(_tfaStatus < 0) {
        // Currently disabled
        if(!enable) {
          console.error('2FA already disabled')
          return
        }

        if(activable2FA) {
          // Requesting confirmation
          if(!tfaInput) {
            toast(errorToast(null, t('2faTokenMissing')))
            return
          }
        }
        
        if(!activable2FA) {
          // Requesting activation
          if(!otaInput) {
            toast(errorToast(null, t('otaTokenMissing')))
            return
          }
        }
      }

      if(_tfaStatus == 0) {
        // Currently activation pending
        if(enable) {
          // Requesting confirmation
          if(activable2FA) {
            if(!tfaInput) {
              toast(errorToast(null, t('2faTokenMissing')))
              return
            }
          }
        } 
        if(!enable) {
          // Requesting deactivation
          if(!otaInput) {
            toast(errorToast(null, t('otaTokenMissing')))
            return
          }
        }              
      }
   
      const response = await axios.post(URL_ACCOUNT_2FA, 
        {
          identifier: {accountId: account?.id},
          auth: {
            ota: otaInput,
            twoFactor: tfaInput
          },
          enable: enable
        },
        axiosConfig(account?.token, i18n)
      )
      
      if(is2FARequired(response)) {
        onOpen()
      } else if(response.status == 401) {
        tryClearInput(TFA_OTA_ID)
        if(response.data.error)
          toast(errorToast(null, response.data.error))
        else {
          toast(errorToast(null, t('genericError')))
          navigate('/')
          return
        }
      } else if(!isHttpCodeInRange(200, response.status)) {
        tryClearInput(TFA_OTA_ID)
        throw parseAxiosError(response)
      } else {
        if(response.status == 201)
          setActivable2FA(response.data)
        refreshAccountInfo()
        tryClearInput(TFA_OTA_ID)
      }
      
    } catch(error) {
      console.error(error)
      toast(errorToast(null, typeof error == 'string' ? error : t('genericError')))
    } finally {
      setProcessing(false)
    }
  }

  useEffect(() => {
   
  }, [])

  const containerWidth = isLargerThan1024 ? '90%' : '95%'
  const spaceBox = <Box h='25px'/>
  const titleFontSize = 'md'
  const titleFontWeight = 'bold'
  const labelFontSize = 'sm'
  const labelMargin = '0 0 0 0'
  const messageFontSize = isLargerThan1024 ? 'md' : 'xs'
  const messageMaxWidth = isLargerThan1024 ? '350px' : '250px'
  const qrCodeSize = isLargerThan1024 ? 256 : 128
  const tfwBackupFontSize = isLargerThan1024 ? 'md' : 'md'

  return (
    <VStack w={containerWidth} color={_color}>
      <TFAModal _color={_color} _bg={_bg} isOpen={isOpen} onClose={onClose} on2FA={(tfa) => updatePwd(tfa)}/>
      <FormControl border='none'>
        <FormLabel fontSize={titleFontSize} fontWeight={titleFontWeight} margin={labelMargin} textAlign='center'><u>{t('settingsUpdatePasswordTitle')}</u></FormLabel>
        {spaceBox}
        <FormControl border='none'>
          <FormLabel fontSize={labelFontSize} margin={labelMargin}><u>{t('settingsNewPassword')}</u></FormLabel>
          <SignupPasswords password1Id={NEW_PWD_ID} password2Id={NEW_PWD2_ID} _color={_color} />
        </FormControl> 
        {spaceBox}
        <VStack spacing={3}>
          <OTA emailAddress={accountInfo.contact.email.address} otaId={PWD_OTA_ID} globalCoolDown={{value: otaCoolDown, setValue: setOtaCoolDown}} _color={_color} />
          <Button isLoading={processing} disabled={processing} spinner={<BeatLoader size={8} color={symbioseTheme.colors.darkGreen} />} onClick={() => updatePwd()}>{t('settingsValidateButton')}</Button>
        </VStack>
      </FormControl>
      {spaceBox}
      <Divider />
      {spaceBox}
      <VStack>
        <FormControl as={SimpleGrid} >
          <FormLabel fontSize={titleFontSize} fontWeight={titleFontWeight} textAlign='center' ><u>2FA - {tfaStatusLabel()}</u></FormLabel>
        </FormControl>
        {tfaStatus() < 0 ?
          <VStack>
            <OTA emailAddress={accountInfo.contact.email.address} otaId={TFA_OTA_ID} globalCoolDown={{value: otaCoolDown, setValue: setOtaCoolDown}} _color={_color} />
            <Button isLoading={processing} disabled={processing} spinner={<BeatLoader size={8} color={symbioseTheme.colors.darkGreen} />} onClick={()=>manage2FA(true)}>{t('settings2FAActivateButton')}</Button>
          </VStack>
          :
          <>
          {tfaStatus() == 0 ?
          <>
          {activable2FA ?
          <Box>
            <VStack spacing={5}>
              <Box p={0} border='2px solid' borderRadius={2} boxShadow='3px 3px 8px 1px #454545'>
                <QRCode 
                  value={"otpauth://totp/" + t('2faLabel') + "?secret=" + activable2FA.key} 
                  size={qrCodeSize}
                  fgColor={symbioseTheme.colors.textColor}
                />
              </Box>
              <Text textAlign='left'>{t('settings2FAKey')}: {activable2FA.key}</Text>
              <VStack spacing={5}>
                <HStack mt={5} maxW={messageMaxWidth}>
                    <InfoIcon/>
                  <Text fontSize={messageFontSize} opacity={0.7} whiteSpace='break-spaces' wordBreak='break-word' >{t('settings2FAActivationMessage')}</Text>
                </HStack>                
                <a href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2" target="_blank" rel="noopener noreferrer">
                  <img alt="Get it on Google Play" src="https://developer.android.com/images/brand/en_generic_rgb_wo_45.png" />
                </a>

                <a href="https://apps.apple.com/us/app/google-authenticator/id388497605" target="_blank" rel="noopener noreferrer">
                  <img alt="Download on the Apple Store" src="https://linkmaker.itunes.apple.com/en-us/badge-lrg.svg?releaseDate=2017-07-05T00:00:00Z&kind=desktopapp&bubble=macos_apps" />
                </a>
              </VStack>
              {(activable2FA.oneTimeTokens as any []).length > 0 &&
              <VStack>
                <HStack mt={5} maxW={messageMaxWidth}>
                  <InfoIcon/>
                  <Text fontSize={messageFontSize} opacity={0.7} whiteSpace='break-spaces' wordBreak='break-word' >{t('settings2FABackupsMessage')}</Text>
                </HStack>
                {(activable2FA.oneTimeTokens as any []).map((o, i) => {
                  return <Text key={i} textAlign='center' fontSize={tfwBackupFontSize} >{o}</Text>
                })}   
              </VStack>
              }
              <VStack>
                <OTA emailAddress={accountInfo.contact.email.address} otaId={TFA_OTA_ID} globalCoolDown={{value: otaCoolDown, setValue: setOtaCoolDown}} _color={_color} />
                <TFA tfaId={TFA_ID} width='100%'/>
              </VStack>
              <Button isLoading={processing} disabled={processing} spinner={<BeatLoader size={8} color={symbioseTheme.colors.darkGreen} />} onClick={()=>manage2FA(true)}>{t('settings2FAConfirmButton')}</Button>
            </VStack>            
          </Box>
          :
          <VStack>
            <OTA emailAddress={accountInfo.contact.email.address} otaId={TFA_OTA_ID} globalCoolDown={{value: otaCoolDown, setValue: setOtaCoolDown}} _color={_color} />
            <Button isLoading={processing} disabled={processing} spinner={<BeatLoader size={8} color={symbioseTheme.colors.darkGreen} />} onClick={()=>manage2FA(true)}>{t('settings2FAActivateButton')}</Button>
          </VStack>
          }
          </>
          :
          <VStack>
            <OTA emailAddress={accountInfo.contact.email.address} otaId={TFA_OTA_ID} globalCoolDown={{value: otaCoolDown, setValue: setOtaCoolDown}} _color={_color} />
            <TFA tfaId={TFA_ID} width='100%' _color={_color} _bg={_bg} />
            <Button isLoading={processing} disabled={processing} spinner={<BeatLoader size={8} color={symbioseTheme.colors.darkGreen} />} onClick={()=>manage2FA(false)}>{t('settings2FADeactivateButton')}</Button>
          </VStack>
          } 
          </>      
        }
      </VStack>
    </VStack>
  )
}