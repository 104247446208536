import { ReactNode, useEffect, useState } from "react";
import { Box, Flex,Text, Icon, useColorModeValue, Link, useMediaQuery, Button, Stack, InputGroup, InputLeftElement, Input, InputRightElement, InputLeftAddon, background, VStack, Divider, Center, Tooltip, HStack, FormLabel, FormControl, FormHelperText, Table, Tbody, Td, Tr, Image, NumberInput, NumberDecrementStepper, NumberInputField, NumberIncrementStepper } from "@chakra-ui/react";
import { CheckIcon, EmailIcon, InfoIcon, LockIcon, PhoneIcon, QuestionOutlineIcon, ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import { axiosConfig, formatDocumentTitle, formatPrice, getBackgroundComponent, getLanguage, getLanguageEntry, getLanguageValue, isHttpCodeInRange, logout } from "../../../lib/utils";
import { symbioseTheme } from "../../../App";
import axios from "axios";
import Cookies from 'universal-cookie';
import { URL_CUSTODIAN_GET_PORTFOLIO, URL_ACCOUNT_IS_LOGGED_IN, URL_ACCOUNT_LOGIN } from "../../../constants";
import { useNavigate } from "react-router-dom";
import Header from "../../common/Header";
import { useTranslation } from "react-i18next";


interface Props {
  password1Id : string,
  password2Id : string,
  _color? : string
}

export default function SignupPasswords({password1Id, password2Id, _color} : Props) { 

  const { t, i18n } = useTranslation()
  const [showPassword, setShowPassword] = useState(false)
    
  return (
    <VStack spacing={1}>
      <InputGroup>
        <InputLeftAddon
          pointerEvents='none'
          fontSize='1em'
          children={<LockIcon />}
          bg={'transparent'} color={_color} borderColor={_color}
        />
        <Input id={password1Id} pr='4.5rem' color={_color} borderColor={_color} _hover={{borderColor: _color}} type={showPassword ? 'text' : 'password'} placeholder={t('loginSignUpPassword') as string} />
        <InputRightElement width='4.5rem' color={_color}>
          {showPassword ?
            <ViewOffIcon h='1.75rem' onClick={() => setShowPassword(!showPassword)} />
          :
            <ViewIcon h='1.75rem' onClick={() => setShowPassword(!showPassword)} />
          }
        </InputRightElement>
      </InputGroup>
      <InputGroup>
        <InputLeftAddon
          pointerEvents='none'
          fontSize='1em'
          children={<LockIcon />}
          bg={'transparent'} color={_color} borderColor={_color}
        />
        <Input id={password2Id} pr='4.5rem' color={_color} borderColor={_color} _hover={{borderColor: _color}} type={showPassword ? 'text' : 'password'} placeholder={t('loginSignUpRepeatPassword') as string} />
        {<InputRightElement width='4.5rem' color={_color}>
          {showPassword ?
            <ViewOffIcon h='1.75rem' onClick={() => setShowPassword(!showPassword)} />
          :
            <ViewIcon h='1.75rem' onClick={() => setShowPassword(!showPassword)} />
          }
        </InputRightElement>}     
      </InputGroup>
    </VStack>
  )
}