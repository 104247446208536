import { Box, Button, Flex, HStack, Spinner, Table, Tbody, Td, Text, Th, Thead, Toast, Tr, useMediaQuery, useToast, VStack } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { MdArrowBackIos, MdArrowBackIosNew, MdArrowForwardIos } from "react-icons/md";
import { useFetcher } from "react-router-dom";
import { symbioseTheme } from "../../App";

interface Props {
  currentPage() : number,
  totalItems() : number
  loadPage(page : number) : any
  pageOfIndex(index : number) : number
}

const BUTTON_SKIP = "..."

export default function PageNavigation ({currentPage, totalItems, loadPage, pageOfIndex} : Props) {
  const { t, i18n } = useTranslation()
  const [isLargerThan1024] = useMediaQuery('(min-width: 1024px)')
  const [isLargerThan512] = useMediaQuery('(min-width: 512px)')
  const [isTalerThan512] = useMediaQuery('(min-height: 512px)')

  const lastPage = pageOfIndex(totalItems() - 1)
  const lightVersion = lastPage < 7

  const tryLoadPageNumber = (page : number | string | undefined | null) => {
    if(page && typeof(page) == 'number')
      loadPage(page - 1)
  }

  const pageNumber = (pageIndex : number) : number | string => {
    const page = currentPage()
    let pn = page + pageIndex - 2
    if(page < 4)
      pn = pageIndex + 1
    else if(lastPage-page < 4)
      pn = lastPage - 5 + pageIndex

    if(pageIndex == 1 && pn > 2)
      return BUTTON_SKIP
    if(pageIndex == 5)
      if(pn + 1 < (lastPage+1))
        return BUTTON_SKIP
      
    return pn
  } 

  const isSelectedPage = (pageIndex : number) => {
    const pn = pageNumber(pageIndex)
    if(pn && typeof(pn) == 'number')
      return currentPage() == pn - 1
    return false
  }

  const isClickablePage = (pageIndex : number) => {
    return !isSelectedPage(pageIndex) && pageNumber(pageIndex) !== BUTTON_SKIP
  }

  const buttonBorder = (pageNumber : number) => {
    return isSelectedPage(pageNumber) ?  '2px solid' : 'none'
  }

  const wingsWidth = isLargerThan1024 ? (lightVersion ? '100px' : '15%') : (lightVersion ? '20%' : '10%')
  const innerWidth = '10%'
  const buttonSize = isLargerThan1024 ? 'md' : (isLargerThan512 ? 'sm' : 'xs')
  const tableWidth = ''// lightVersion ? '100%' : 'auto'
  const buttonMarginX = isLargerThan512 ? 1 : 0

  return (
    <Flex flexDirection='row' flexWrap='nowrap' w='100%' alignItems="center" justifyContent="center" textAlign="center">
      <Table size={'xs'} variant='unstyled' visibility={lastPage > 0 ? 'visible' : 'hidden'} width={tableWidth}>
        <Tbody> 
          {lightVersion ?
          <Tr>
            <Td w={wingsWidth} textAlign='center'>
              <Button borderLeftRadius={20} w='100%' disabled={currentPage() <= 0} onClick={() => {loadPage(currentPage() - 1)}}>
                {<Flex flexDir='row' justifyContent="center"><MdArrowBackIosNew />{isLargerThan1024 && t('pageNavigationPrevious')}</Flex>}
              </Button>
            </Td>
            <Td w='30px' />
            <Td fontWeight='bold' textAlign='center' >{currentPage() + 1} / {lastPage + 1}</Td>
            <Td w='30px' />
            <Td w={wingsWidth} textAlign='center'>
              <Button borderRightRadius={20} w='100%' disabled={currentPage() >= lastPage} onClick={() => {loadPage(currentPage() + 1)}}>
                {(<Flex flexDir='row' justifyContent="center">{isLargerThan1024 && t('pageNavigationNext')}<MdArrowForwardIos /></Flex>)}
              </Button>
            </Td>
            
          </Tr>
          :
          <Tr>
            <Td w={wingsWidth} textAlign='center'>
              <Button size={buttonSize} mx={buttonMarginX} disabled={currentPage() <= 0} borderLeftRadius={20} onClick={() => {loadPage(currentPage() - 1)}}>
                {(<Flex justifyContent="center"><MdArrowBackIosNew />{isLargerThan1024 && t('pageNavigationPrevious')}</Flex>)}
              </Button>
            </Td>
            <Td w={innerWidth} textAlign='center'>
              <Button size={buttonSize} mx={buttonMarginX} disabled={!isClickablePage(0)} border={buttonBorder(0)} onClick={() => {loadPage(0)}}>1</Button>
            </Td>
            <Td w={innerWidth} textAlign='center' fontWeight={isSelectedPage(1) ? 'bold' : 'normal'}>
              <Button size={buttonSize} mx={buttonMarginX} disabled={!isClickablePage(1)} border={buttonBorder(1)} onClick={() => {tryLoadPageNumber(pageNumber(1))}}>{pageNumber(1)}</Button>
            </Td>
            <Td w={innerWidth} textAlign='center' fontWeight={isSelectedPage(2) ? 'bold' : 'normal'}>
              <Button size={buttonSize} mx={buttonMarginX} disabled={!isClickablePage(2)} border={buttonBorder(2)} onClick={() => {tryLoadPageNumber(pageNumber(2))}}>{pageNumber(2)}</Button>
            </Td>
            <Td w={innerWidth} textAlign='center' fontWeight={isSelectedPage(3) ? 'bold' : 'normal'}>
              <Button size={buttonSize} mx={buttonMarginX} disabled={!isClickablePage(3)} border={buttonBorder(3)} onClick={() => {tryLoadPageNumber(pageNumber(3))}}>{pageNumber(3)}</Button>
            </Td>
            <Td w={innerWidth} textAlign='center' fontWeight={isSelectedPage(4) ? 'bold' : 'normal'}>
              <Button size={buttonSize} mx={buttonMarginX} disabled={!isClickablePage(4)} border={buttonBorder(4)} onClick={() => {tryLoadPageNumber(pageNumber(4))}}>{pageNumber(4)}</Button>
            </Td>
            <Td w={innerWidth} textAlign='center' fontWeight={isSelectedPage(5) ? 'bold' : 'normal'}>
              <Button size={buttonSize} mx={buttonMarginX} disabled={!isClickablePage(5)} border={buttonBorder(5)} onClick={() => {tryLoadPageNumber(pageNumber(5))}}>{pageNumber(5)}</Button>
            </Td>
            <Td w={innerWidth} textAlign='center' fontWeight={isSelectedPage(6) ? 'bold' : 'normal'}>
              <Button size={buttonSize} mx={buttonMarginX} disabled={!isClickablePage(6)} border={buttonBorder(6)} onClick={() => {loadPage(lastPage)}}>{lastPage + 1}</Button>
            </Td>          
            <Td w={wingsWidth} textAlign='center'>
              <Button size={buttonSize} mx={buttonMarginX} disabled={currentPage() >= lastPage} borderRightRadius={20} onClick={() => {loadPage(currentPage() + 1)}}>{(<Flex justifyContent="center">{isLargerThan1024 && t('pageNavigationNext')}<MdArrowForwardIos /></Flex>)}</Button>
            </Td>
          </Tr>
          }
        </Tbody>
      </Table>
    </Flex>
  )
}
