import { ReactNode, useEffect, useState } from "react";
import { Box, Flex,Text, Icon, useColorModeValue, Link, useMediaQuery, Button, Stack, InputGroup, InputLeftElement, Input, InputRightElement, InputLeftAddon, background, VStack, Divider, Center, Tooltip, HStack, FormLabel, FormControl, FormHelperText, Tbody, Th, Tr, Thead, Table, Td, useToast, Image, useNumberInput, NumberInput, NumberInputField, NumberInputStepper, NumberDecrementStepper, NumberIncrementStepper, Popover, PopoverTrigger, PopoverHeader, PopoverContent, PopoverArrow, PopoverCloseButton, PopoverBody, TableContainer, Accordion, AccordionItem, AccordionButton, AccordionIcon, AccordionPanel } from "@chakra-ui/react";
import { CheckIcon, EmailIcon, InfoIcon, LockIcon, PhoneIcon, QuestionOutlineIcon, ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import { axiosConfig, checkAuth, computeInvoiceTotal, errorToast, formatDatetime, formatDocumentTitle, formatPrice, getBackgroundComponent, getLanguage, getLanguageEntry, getLanguageValue, isHttpCodeInRange, isInvoicePaid, logout, parseAxiosError } from "../../../lib/utils";
import { symbioseTheme } from "../../../App";
import axios from "axios";
import Cookies from 'universal-cookie';
import { URL_CUSTODIAN_GET_PORTFOLIO, URL_ACCOUNT_IS_LOGGED_IN, URL_ACCOUNT_LOGIN, URL_MARKETPLACE_GET_PRODUCTS, URL_ACCOUNT_INVOICES } from "../../../constants";
import { useNavigate, useSearchParams } from "react-router-dom";
import Header from "../../common/Header";
import { useTranslation } from "react-i18next";
import Loading from "../../common/Loading";
import Message from "../../common/Message";
import InvoiceTable from "./InvoiceTable";
import PageNavigation from "../../common/PageNavigation";


const htmlId = function(id : string) {
  return "INVOICES-" + id
}

const PAGE_SIZE = 10

export default function Invoices() {
  
  const navigate = useNavigate()
  const cookies = new Cookies()
  const { t, i18n } = useTranslation()
  const [isLargerThan1024] = useMediaQuery('(min-width: 1024px)')
  const [isTalerThan512] = useMediaQuery('(min-height: 512px)')
  const toast = useToast()
  const [invoices, setInvoices] = useState<{loaded : boolean, items: any [], from: number, page : number, total: number | null}>({loaded: false, items: [], from: 0, page: 0, total: null});
        
  document.title = formatDocumentTitle(t('invoicesPageTitle'))
  
  const pageOfIndex = (index : number) => {
    if(!index)
      return 0
    return Math.floor(index/PAGE_SIZE)
  }

  const loadInvoices = async (page : number) => {
    try {
      if(invoices.loaded && (page < 0 || page == invoices.page || (invoices.total && page > pageOfIndex(invoices.total-1))))
        return
      setInvoices({...invoices, loaded: false})
      console.debug('Loading page ' + page)
      const account = cookies.get('account')
      const from = page * PAGE_SIZE
      const response = await axios.post(URL_ACCOUNT_INVOICES, 
        {
          from: from,
          to: from + PAGE_SIZE
        },
        axiosConfig(account.token, i18n)
      )

      if(response.status == 401) {
        logout(cookies, navigate)
        return
      }

      if(!isHttpCodeInRange(200, response.status)) 
        throw parseAxiosError(response)

      setInvoices({loaded: true, items: response.data.invoices, from: from, page: page, total: response.data.total})
    } catch(error) {
      console.error(error)
      toast(errorToast(null, typeof error == 'string' ? error : t('genericError')))
    }
  }  

  useEffect(() => {
    const load = async () => {
      const auth = await checkAuth(cookies, navigate);
      if(auth.error)
        toast(errorToast(null, t('genericError')))
      else if (auth.auth) {
        await loadInvoices(0)       
      }
    }
    load()
  }, [])
 
  const headerMarginY = isLargerThan1024 ? '100px' : '40px'
  const containerWidth = isLargerThan1024 ? "80%" : "95%"
  const containerColor = symbioseTheme.colors.headerTextColor
  const containerBg = symbioseTheme.colors.headerColor
  
  const invoiceHeaderColor = containerColor//symbioseTheme.colors.textColor
  const invoiceHeaderBg = containerBg//symbioseTheme.colors.textBackgroundColor
  const invoiceHeaderLeftWidth = '70%'

  const majorFontSize = isLargerThan1024 ? 'lg' : 'sm'
  const minorFontSize = isLargerThan1024 ? 'md' : 'sm'

  return (
    <>
    {getBackgroundComponent()}
    <Header />
    {invoices.loaded ? 
    <Flex flexDirection="column" w='100%' h='100%' alignItems="center" justifyContent="center" textAlign="center" color={containerColor} bg={containerBg}>
      <VStack minW={containerWidth} w={containerWidth}>
        <Text size="xl" fontSize='2xl' my={headerMarginY} mx='10px'>{t('invoicesPageTitle')}</Text>
        <Accordion allowMultiple w='100%'>
          {invoices.items.map((o, i) => {
            const total = computeInvoiceTotal(o)
            return(
              <Box key={i}>
                {i > 0 &&<Divider borderStyle='dashed' borderColor={containerColor} mt={2}/>}
                <AccordionItem >
                  <h2>
                    <AccordionButton _expanded={{ bg: invoiceHeaderBg, color: invoiceHeaderColor }}>
                      <HStack w='100%' pr={3}>
                        <Table variant={'unstyled'} size={'xs'} w='100%' wordBreak='break-all'>
                          <Tbody>
                            <Tr>
                              <Td textAlign='left' fontSize={minorFontSize} maxW={invoiceHeaderLeftWidth} w={invoiceHeaderLeftWidth}>#{o.id}</Td>
                              <Td textAlign='right' wordBreak='break-word' fontSize={minorFontSize}>{total?.price}</Td>
                            </Tr>
                            <Tr>
                              <Td textAlign='left' fontSize={majorFontSize} maxW={invoiceHeaderLeftWidth} w={invoiceHeaderLeftWidth}>{formatDatetime(o.creationTime)}</Td>
                              <Td textAlign='right' fontSize={minorFontSize}>{isInvoicePaid(o.settlement.status) ? t('invoiceTablePaid') : t('invoiceTableUnpaid')}</Td>
                            </Tr>
                          </Tbody>
                        </Table>
                      </HStack>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel >
                    <Box border={'1px solid'} borderRadius={5}>
                      <InvoiceTable invoice={o} />
                    </Box>
                  </AccordionPanel>
                </AccordionItem>
              </Box>
            )
          })}
          {invoices.items.length <= 0 &&
            <Message message={t('invoicesEmptyMessage')} _navigate={{url: '/marketplace', message: t('invoicesVisitMarketplace')}} />
          }
        </Accordion>
        <Box h='20px'/>       
        <PageNavigation currentPage={()=> invoices.page} totalItems={() => invoices.total!} pageOfIndex={(page) => pageOfIndex(page)} loadPage={(page) => loadInvoices(page)} />
        <Box h='40px'/>       
      </VStack>
    </Flex>
    :
    <Loading _color={containerColor} _bg={containerBg} message={t('invoicesLoading')}/>
    }
    
    </>
  )
}