import { ReactNode, useEffect, useState } from "react";
import { Box, Flex,Text, Icon, useColorModeValue, Link, useMediaQuery, Button, Stack, InputGroup, InputLeftElement, InputRightElement, InputLeftAddon, background, VStack, Divider, Center, Tooltip, HStack, FormLabel, FormControl, FormHelperText, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, useDisclosure, Input, useToast } from "@chakra-ui/react";
import { CheckIcon, EmailIcon, InfoIcon, LockIcon, PhoneIcon, QuestionOutlineIcon, ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import { axiosConfig, errorToast, formatDocumentTitle, getBackgroundComponent, is2FARequired, isHttpCodeInRange, parseAxiosError, successToast, tryClearInput } from "../../../lib/utils";
import { symbioseTheme } from "../../../App";
import axios from "axios";
import Cookies from 'universal-cookie';
import { URL_ACCOUNT_LOGIN, URL_ACCOUNT_OTA, URL_ACCOUNT_REGISTER, URL_ACCOUNT_UPDATE } from "../../../constants";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Footer from "../../common/Footer";
import BeatLoader from "react-spinners/BeatLoader";
import Email from "../../common/Email";
import SignupPasswords from "../login/SignupPasswords";
import { MdArrowBack } from "react-icons/md";
import OTA from "../../common/OTA";
import TFAModal from "../../common/TFAModal";

const htmlId = function(id : string) {
  return "RESETPASSWORD-" + id
}

const EMAIL_ID = htmlId("email")
const OTA_ID = htmlId("ota")
const RESET_PWD_ID = htmlId("reset-pwd")
const RESET_PWD2_ID = htmlId("reset-pwd-2")


export default function ResetPassword() {

  const navigate = useNavigate()
  const cookies = new Cookies()
  const { t, i18n } = useTranslation()
  const [isLargerThan1024] = useMediaQuery('(min-width: 1024px)')
  const [searchParams, setSearchParams] = useSearchParams();
  const toast = useToast()
  const { isOpen, onOpen, onClose } = useDisclosure()
  
  const [buttonClicked, setButtonClicked] = useState(false)
   

  document.title = formatDocumentTitle(t('resetPasswordTitle'))

  useEffect(() => {
    if(cookies.get('account'))
      navigate('/')
  })

 
  const resetPassword = async function(tfa : string | null = null) {

    const email = document.getElementById(EMAIL_ID) as HTMLInputElement
    const ota = document.getElementById(OTA_ID) as HTMLInputElement
    const pwd = document.getElementById(RESET_PWD_ID) as HTMLInputElement
    const pwd2 = document.getElementById(RESET_PWD2_ID) as HTMLInputElement

    setButtonClicked(true)
    try {
      
      if(!email.value || email.value.trim().length <= 0) {
        toast(errorToast(null, t('resetPasswordInvalidEmail')))
        return
      }

      if(!pwd.value || pwd.value.trim().length <= 0
        || !pwd2.value || pwd2.value.trim().length <= 0) {
        toast(errorToast(null, t('resetPasswordInvalidPassword')))
        return
      }

      if(pwd.value != pwd2.value) {
        toast(errorToast(null, t('resetPasswordPasswordMismatch')))
        return
      }

      if(!ota.value || ota.value.trim().length <= 0) {
        toast(errorToast(null, t('resetPasswordInvalidOTA')))
        return
      }

      const response = await axios.post(URL_ACCOUNT_UPDATE, 
        {
          identifier: {email: email.value},
          auth: {ota: ota.value, twoFactor: tfa},
          updates: {auth: {pwd: pwd.value}}
        },
        axiosConfig(null, i18n)
      )
      
      if(is2FARequired(response)) {
        onOpen()
      } else if(response.status == 401) {
        tryClearInput(OTA_ID)
        if(response.data.error)
          toast(errorToast(null, response.data.error))
        else
          toast(errorToast(null, t('resetPasswordInvalidOTA')))
      } else if(!isHttpCodeInRange(200, response.status)) {
        tryClearInput(OTA_ID)
        throw parseAxiosError(response)
      } else {
        toast(successToast(t('resetPasswordSuccessToast')))
        tryClearInput(OTA_ID)
        tryClearInput(RESET_PWD_ID)
        tryClearInput(RESET_PWD2_ID)
        navigate('/', {replace: true})
      }
    } catch(error) {
      console.error(error)
      toast(errorToast(null, typeof error == 'string' ? error : t('genericError')))
    } finally {
      setButtonClicked(false)
    }
  }

  const containerWidth = isLargerThan1024 ? "80%" : "95%"
  const containerColor = symbioseTheme.colors.headerTextColor
  const containerBg = symbioseTheme.colors.headerColor

  return (
    <>
    {getBackgroundComponent()}
    <Flex flexDirection="column" alignItems="center" justifyContent="center" textAlign="center" width="100%" height="100%" color={containerColor} bg={containerBg}>
      <TFAModal _color={containerColor} _bg={containerBg} isOpen={isOpen} onClose={onClose} on2FA={(tfa) => resetPassword(tfa)}/>
      <HStack w='30%' cursor='pointer' onClick={() => navigate('/')}>
        <MdArrowBack /> 
        <Text><u><i>{t('backToHome')}</i></u></Text>
      </HStack>

      <VStack py='40px' spacing={4}>
        <Text><b><i>{t('resetPasswordHeaderMessage')}</i></b></Text>
        <Email emailId={EMAIL_ID} _color={containerColor} />
        <OTA emailId={EMAIL_ID} otaId={OTA_ID} _color={containerColor} />
        <VStack>
          <Box h='15px'/>
          <HStack w='100%' alignItems="center" justifyContent="center"><InfoIcon /><Text><i>{t('resetPasswordNewPasswordMessage')}</i></Text></HStack>
          <Box h='10px'/>
          <SignupPasswords password1Id={RESET_PWD_ID} password2Id={RESET_PWD2_ID} _color={containerColor} />          
          <Box h='10px'/>
          <Button minW='150px' isLoading={buttonClicked} disabled={buttonClicked} spinner={<BeatLoader size={8} color={symbioseTheme.colors.darkGreen} />} onClick={() => resetPassword()}>{t('resetPasswordButton')}</Button>
        </VStack>
      </VStack>       
    </Flex>
    <Footer />
    </>
  )
}