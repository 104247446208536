import { ReactNode, useEffect, useState } from "react";
import { Box, Flex,Text, Icon, useColorModeValue, Link, useMediaQuery, Button, Stack, InputGroup, InputLeftElement, Input, InputRightElement, InputLeftAddon, background, VStack, Divider, Center, Tooltip, HStack, FormLabel, FormControl, FormHelperText, Table, Tbody, Td, Tr, Image, NumberInput, NumberDecrementStepper, NumberInputField, NumberIncrementStepper, SimpleGrid, Switch, useToast, useDisclosure } from "@chakra-ui/react";
import { CheckIcon, EmailIcon, InfoIcon, LockIcon, PhoneIcon, QuestionOutlineIcon, ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import { axiosConfig, errorToast, formatDocumentTitle, formatPrice, getBackgroundComponent, getLanguage, getLanguageEntry, getLanguageValue, is2FARequired, isHttpCodeInRange, parseAxiosError, parseInputs, parsePinInput, string2NullIfEmpty, successToast, tryClearInput } from "../../../../lib/utils";
import { symbioseTheme } from "../../../../App";
import axios from "axios";
import Cookies from 'universal-cookie';
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { AiOutlineLock } from "react-icons/ai";
import { IconType } from "react-icons";
import SigninPassword from "../../login/SigninPassword";
import SignupPasswords from "../../login/SignupPasswords";
import BeatLoader from "react-spinners/BeatLoader";
import { URL_ACCOUNT_2FA, URL_ACCOUNT_UPDATE } from "../../../../constants";
import OTA from "../../../common/OTA";
import QRCode from "react-qr-code";
import TFA from "../../../common/TFA";
import TFAModal from "../../../common/TFAModal";
import PostalAddress from "../../../common/PostalAddress";
import PhoneNumber from "../../../common/PhoneNumber";
import Email from "../../../common/Email";

const htmlId = function(id : string) {
  return "SETTINGCONTACT-" + id
}

const EMAIL_OTA_ID = htmlId("email-ota")
const EMAIL_ID = htmlId("email")
const PHONE_OTA_ID = htmlId("phone-ota")
const ADDRESS_LINES_ID = htmlId("address-lines")
const ADDRESS_COUNTRY_ID = htmlId("address-country")
const ADDRESS_COUNTRY_OTA_ID = htmlId("address-ota")

interface Props {
  accountInfo : any,
  refreshAccountInfo() : any,
  _color? : string,
  _bg? : string
}


export default function Contact({accountInfo, refreshAccountInfo, _color, _bg} : Props) { 

  const navigate = useNavigate()
  const cookies = new Cookies()
  const { t, i18n } = useTranslation()
  const [isLargerThan1024] = useMediaQuery('(min-width: 1024px)')
  const [isTalerThan512] = useMediaQuery('(min-height: 512px)')
  const toast = useToast()

  const { isOpen, onOpen, onClose } = useDisclosure()
  const [processing, setProcessing] = useState(false)
  const [otaCoolDown, setOtaCoolDown] = useState<number | null>(null)
  const [phoneNumber, setPhoneNumber] = useState<any | null>(accountInfo.contact.phone?.number)

  
  const updateEmail = async function(tfa : string | null = null) {
    const account = cookies.get('account')
    const emailinput = string2NullIfEmpty((document.getElementById(EMAIL_ID) as HTMLInputElement)?.value)
    setProcessing(true)
    
    try {    
    
      const otaInput =  string2NullIfEmpty((document.getElementById(EMAIL_OTA_ID) as HTMLInputElement)?.value)
      if(!otaInput) {
        toast(errorToast(null, t('otaTokenMissing')))
        return
      }

      const response = await axios.post(URL_ACCOUNT_UPDATE, 
        {
          identifier: {accountId: account?.id},
          auth: {
            ota: otaInput,
            twoFactor: tfa
          },
          updates: {
            contact: {
              email: {
                address: emailinput
              }
            }
          }
        },
        axiosConfig(account?.token, i18n)
      )

      if(is2FARequired(response)) {
        onOpen()
      } else if(response.status == 401) {
        tryClearInput(EMAIL_OTA_ID)
        if(response.data.error)
          toast(errorToast(null, response.data.error))
        else {
          toast(errorToast(null, t('genericError')))
          navigate('/')
          return
        }
      } else if(!isHttpCodeInRange(200, response.status)){
        tryClearInput(EMAIL_OTA_ID)
        throw parseAxiosError(response)
      } else {
        toast(successToast(null, t('settingsDataUpdateToastSuccess')))
        tryClearInput(EMAIL_OTA_ID)
        refreshAccountInfo()  
      }
      
    } catch(error) {
      console.error(error)
      toast(errorToast(null, typeof error == 'string' ? error : t('genericError')))
    } finally {
      setProcessing(false)
    }
  }

  const updatePhone = async function(tfa : string | null = null) {
    const account = cookies.get('account')
    setProcessing(true)
    
    try {    
    
      const otaInput =  string2NullIfEmpty((document.getElementById(PHONE_OTA_ID) as HTMLInputElement)?.value)
      if(!otaInput) {
        toast(errorToast(null, t('otaTokenMissing')))
        return
      }

      const response = await axios.post(URL_ACCOUNT_UPDATE, 
        {
          identifier: {accountId: account?.id},
          auth: {
            ota: otaInput,
            twoFactor: tfa
          },
          updates: {
            contact: {
              phone: {
                number: '+' + phoneNumber
              }
            }
          }
        },
        axiosConfig(account?.token, i18n)
      )

      if(is2FARequired(response)) {
        onOpen()
      } else if(response.status == 401) {
        tryClearInput(PHONE_OTA_ID)
        if(response.data.error)
          toast(errorToast(null, response.data.error))
        else {
          toast(errorToast(null, t('genericError')))
          navigate('/')
          return
        }
      } else if(!isHttpCodeInRange(200, response.status)){
        tryClearInput(PHONE_OTA_ID)
        throw parseAxiosError(response)
      } else {
        toast(successToast(null, t('settingsDataUpdateToastSuccess')))
        tryClearInput(PHONE_OTA_ID)
        refreshAccountInfo()  
      }
      
    } catch(error) {
      console.error(error)
      toast(errorToast(null, typeof error == 'string' ? error : t('genericError')))
    } finally {
      setProcessing(false)
    }
  }

  const updatePostalAddress = async function(tfa : string | null = null) {
    const account = cookies.get('account')
    const addressLines = parseInputs(ADDRESS_LINES_ID)
    const addressCountry =  string2NullIfEmpty((document.getElementById(ADDRESS_COUNTRY_ID) as HTMLSelectElement)?.value)
    setProcessing(true)
    
    try {    
    
      if(!addressLines || addressLines?.length <= 0 || !addressCountry) {
        toast(errorToast(null, t('settingsContactPostalAddressIncomplete')))
        return
      }

      const otaInput =  string2NullIfEmpty((document.getElementById(ADDRESS_COUNTRY_OTA_ID) as HTMLInputElement)?.value)
      if(!otaInput) {
        toast(errorToast(null, t('otaTokenMissing')))
        return
      }


      const response = await axios.post(URL_ACCOUNT_UPDATE, 
        {
          identifier: {accountId: account?.id},
          auth: {
            ota: otaInput,
            twoFactor: tfa
          },
          updates: {
            contact: {
              postalAddress: {
                lines: addressLines,
                country: addressCountry
              }
            }
          }
        },
        axiosConfig(account?.token, i18n)
      )

      if(is2FARequired(response)) {
        onOpen()
      } else if(response.status == 401) {
        tryClearInput(ADDRESS_COUNTRY_OTA_ID)
        if(response.data.error)
          toast(errorToast(null, response.data.error))
        else {
          toast(errorToast(null, t('genericError')))
          navigate('/')
          return
        }
      } else if(!isHttpCodeInRange(200, response.status)){
        tryClearInput(ADDRESS_COUNTRY_OTA_ID)
        throw parseAxiosError(response)
      } else {
        toast(successToast(null, t('settingsDataUpdateToastSuccess')))
        tryClearInput(ADDRESS_COUNTRY_OTA_ID)
        refreshAccountInfo()  
      }
      
    } catch(error) {
      console.error(error)
      toast(errorToast(null, typeof error == 'string' ? error : t('genericError')))
    } finally {
      setProcessing(false)
    }
  }


  useEffect(() => {
   
  }, [])

  const containerWidth = isLargerThan1024 ? '90%' : '95%'
  const spaceBox = <Box h='25px'/>
  const titleFontSize = 'md'
  const titleFontWeight = 'bold'
  const labelMargin = '0 0 0 0'
  const messageFontSize = isLargerThan1024 ? 'md' : 'xs'
  const messageMaxWidth = isLargerThan1024 ? '350px' : '250px'

  return (
    <VStack w={containerWidth}>
      <FormControl border='none'>
        <TFAModal isOpen={isOpen} onClose={onClose} on2FA={(tfa) => updateEmail(tfa)} _color={_color} _bg={_bg} />
        <FormLabel fontSize={titleFontSize} fontWeight={titleFontWeight} margin={labelMargin} textAlign='center'><u>{t('settingsContactEmailTitle')}</u></FormLabel>
        {spaceBox}
        <Email emailId={EMAIL_ID} defaultValue={accountInfo.contact.email.address} _color={_color} />
        <VStack>
          <HStack mt={5} maxW={messageMaxWidth}>
            <InfoIcon />
            <Text fontSize={messageFontSize} opacity={0.7} whiteSpace='break-spaces' wordBreak='break-word' >{t('settingsContactEmailMessage')}</Text>
          </HStack>  
        </VStack>
        {spaceBox} 
        <VStack spacing={3}>
          <OTA emailAddress={accountInfo.contact.email.address} otaId={EMAIL_OTA_ID} globalCoolDown={{value: otaCoolDown, setValue: setOtaCoolDown}} _color={_color} />
          <Button isLoading={processing} disabled={processing} spinner={<BeatLoader size={8} color={symbioseTheme.colors.darkGreen} />} onClick={() => updateEmail()}>{t('settingsValidateButton')}</Button>
        </VStack> 
      </FormControl>   
      {spaceBox}
      <Divider />
      {spaceBox}
      <FormControl border='none'>
        <FormLabel fontSize={titleFontSize} fontWeight={titleFontWeight} margin={labelMargin} textAlign='center'><u>{t('settingsContactPhoneNumberTitle')}</u></FormLabel>
        <VStack spacing={3} alignItems="center" justifyContent="center">
        {spaceBox}
        <PhoneNumber _color={_color} _bg={_bg} value={phoneNumber} setValue={setPhoneNumber} />
        {spaceBox} 
          <OTA emailAddress={accountInfo.contact.email.address} otaId={PHONE_OTA_ID} globalCoolDown={{value: otaCoolDown, setValue: setOtaCoolDown}} _color={_color} />
          <Button isLoading={processing} disabled={processing} spinner={<BeatLoader size={8} color={symbioseTheme.colors.darkGreen} />} onClick={() => updatePhone()}>{t('settingsValidateButton')}</Button>
        </VStack> 
      </FormControl>
      {spaceBox}
      <Divider />
      {spaceBox}
      <FormControl border='none'>
        <FormLabel fontSize={titleFontSize} fontWeight={titleFontWeight} margin={labelMargin} textAlign='center'><u>{t('settingsContactPostalAddressTitle')}</u></FormLabel>
        {spaceBox}
        <PostalAddress 
          addressId={ADDRESS_LINES_ID} 
          countryId={ADDRESS_COUNTRY_ID} 
          addressLines={accountInfo.contact.postalAddress ? accountInfo.contact.postalAddress.lines : []}
          addressCountry={accountInfo.contact.postalAddress ? accountInfo.contact.postalAddress.country : null}
          _color={_color}
        />
        {spaceBox}
        {spaceBox}
        {spaceBox}
        <VStack spacing={3}>
          <OTA emailAddress={accountInfo.contact.email.address} otaId={ADDRESS_COUNTRY_OTA_ID} globalCoolDown={{value: otaCoolDown, setValue: setOtaCoolDown}} _color={_color} />
          <Button isLoading={processing} disabled={processing} spinner={<BeatLoader size={8} color={symbioseTheme.colors.darkGreen} />} onClick={() => updatePostalAddress()}>{t('settingsValidateButton')}</Button>
        </VStack> 
      </FormControl>                 
    </VStack>
  )
}