import { ReactNode } from "react";
import { Box, Flex, Text } from "@chakra-ui/react";
import Footer from "./common/Footer";

type Props = {
  children?: ReactNode;
};

export default function Layout({ children }: Props) {

  return (
    <>
      <Box flex="1" bg="transparent">
        {children}
      </Box>
      <Footer />
    </>
  )
}