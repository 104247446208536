import { ReactNode, useEffect, useRef, useState } from "react";
import { Box, Button, Flex, Link, MenuButton, MenuItem, MenuList, Portal, Switch, Icon, useColorMode, useDisclosure, useMediaQuery, useColorModeValue, MenuDivider, IconButton, VStack, Image, theme, HStack, Text } from "@chakra-ui/react";
import { useNavigate } from 'react-router-dom'
import ReactGA from "react-ga4";
import Navbar from "./Navbar";
import { isPrivacyPolicyAgreed } from "../../lib/utils";
import symbioseLogo from '../../img/logo/alt/logov3.svg';
import {ReactComponent as symbioseSvg} from '../../img/logo/main/Logo_Symbiose_MANAGEMENT_arbre_blank.svg';
import {ReactComponent as symbioseTitleSvg} from '../../img/logo/main/SYMBIOSE_MANAGEMENT_Black_sans_fond.svg';
import Cookies from 'universal-cookie';
import { symbioseTheme } from "../../App";
import { useTranslation } from "react-i18next";
import { Trans } from 'react-i18next'
import { FiExternalLink } from "react-icons/fi";



export const PRIVACY_COOKIE_KEY = 'privacyConsent'

export default function PrivacyFrame(){
  const navigate = useNavigate()
  const cookies = new Cookies()
  const { t, i18n } = useTranslation()  
  const { colorMode, toggleColorMode } = useColorMode()
  
  const [isLargerThan1400] = useMediaQuery('(min-width: 1400px)')
  const [isLargerThan1300] = useMediaQuery('(min-width: 1300px)')
  const [isLargerThan1024] = useMediaQuery('(min-width: 1024px)')
  const [isLargerThan750] = useMediaQuery('(min-width: 750px)')
  const [isLargerThan512] = useMediaQuery('(min-width: 512px)')

  const [viewable, setViewable] = useState(false)

  const getLegalFilePath = function(file : string) : string {
    if(i18n.language.toUpperCase().includes('FR'))
      return "/legal/fr/" + file
    else
      return "/legal/fr/" + file
  }


  const privacyPolicyResponse = function(accept : boolean)
  { 
    cookies.set(PRIVACY_COOKIE_KEY, {accepted: accept, datetime: new Date().toUTCString()})
    if(accept)
      ReactGA.initialize("G-EVLQ97CX4R", {testMode: false});
    else {
      cookies.remove("_ga");
      cookies.remove("_gat");
      cookies.remove("_gid");
    }
    setViewable(false)
  }

  useEffect(() => {
    let consentCookie = cookies.get(PRIVACY_COOKIE_KEY)
    const now = new Date()
    const expiry = new Date(now.valueOf() - 6 * 30 * 24 * 60 * 60 * 1000) // ask every 6 months
    if(consentCookie) {
      if(consentCookie.accepted)
        setViewable(false)
      else {
        if(new Date(consentCookie.datetime) < expiry) {
          cookies.remove(PRIVACY_COOKIE_KEY)
          consentCookie = null
          setViewable(true)
        } else
        setViewable(false)
      }
    } else
      setViewable(true)
  }, [])

  const isLargeScreen = isLargerThan512
  const width = isLargeScreen ? '420px' : '90%'
  const height = isLargeScreen ? '250px' : '280px'
  const bottom = isLargeScreen ? 5 : '0'
  const left = isLargeScreen ? 5 : 'unset'
  const linkSpacing = '10px'
  const fontSize = isLargeScreen ? 'md' : 'sm'
  

  return (
  <>
    {viewable ?
    <Flex
      flexDirection="column"
      w={width}
      h={height}
      zIndex={100}
      bottom={bottom}
      left={left}
      margin={3}
      //bg={symbioseTheme.colors.textBackgroundColor}      
      bg='black'
      alignItems="center" justifyContent="center"
      boxShadow={'3px 3px 8px 1px #454545'}
      border='1px solid'
      position={'fixed'}
      padding={5}

    > 
      <Box flexGrow={1} />
      <VStack w='100%' h='100%' fontSize={fontSize} alignItems="center" justifyContent="center">
        <Text>{t('privacyConsent1')}<a href={getLegalFilePath('cookie-policy.html')} target="_blank" rel="noopener noreferrer"><u>{t('privacyConsent2')}</u></a>.</Text>
        <Box flexGrow={1} />
        <Button variant='outline' onClick={() => privacyPolicyResponse(true)} fontSize={fontSize}>{t('privacyConsentAccept')}</Button>
        <Box flexGrow={1} />
        <HStack onClick={() => privacyPolicyResponse(false)} cursor='pointer'>
          <Text><u>{t('privacyConsentDeny')}</u></Text>
        </HStack>
        <Box flexGrow={1} />
      </VStack>
    </Flex>
    :
    <>
    </>
    }
  </>   
  )
}