import { Box, HStack, Spinner, Text, VStack } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { MdArrowBack } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { symbioseTheme } from "../../App";

interface Props {
  message? : string | null,
  _navigate? : {url : string, message : string} | null,
  _color? : string,
  _bg? : string
}

export default function Message ({message = null, _navigate=null, _color, _bg} : Props) {
  const { t, i18n } = useTranslation()
  const navigate = useNavigate()

  if(!message)
    message = t('genericError')
  
  if(!_navigate)
    _navigate = {url: "/", message: t('backToHome')}

  return (
    <VStack h='100%' w='100%' alignItems="center" justifyContent="center" textAlign="center" color={_color} bg={_bg}>
      <Text fontWeight='bold' fontSize='2xl'>{message}</Text>
      <Box h='20px'/>
      <a href={_navigate!.url}>
        <HStack>
          <MdArrowBack /> 
          <Text><u><i>{_navigate.message}</i></u></Text>
        </HStack>
      </a>
    </VStack>
  )
};
