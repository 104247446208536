import { ReactNode, useEffect } from "react";
import { Box, Flex, Text, Icon, useColorModeValue, Image, VStack, HStack, Link, useColorMode, Button, useMediaQuery, Table, Tbody, Tr, Td } from "@chakra-ui/react";
import { SiDiscord, SiTwitter, SiTelegram, SiMedium } from 'react-icons/si';
import { MdAlternateEmail, MdArrowBack, MdArrowForward, MdMail, MdShoppingCart } from 'react-icons/md';
import { AiFillFileText, AiFillInstagram, AiFillMediumCircle, AiOutlineCheck, AiOutlineFileText, AiOutlineShoppingCart } from 'react-icons/ai';
import { BsFillTreeFill, BsLinkedin } from 'react-icons/bs';

import Header from "../common/Header";
import { formatDocumentTitle, getBackgroundComponent, getHomeBackgroundComponent } from "../../lib/utils";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import { useTranslation } from "react-i18next";
import { Trans } from 'react-i18next'
import { gaPageView } from "../../lib/utils";

//import {ReactComponent as symbioseSvg} from '../../img/logo/alt/logov3-color.svg';
import {ReactComponent as symbioseSvg} from '../../img/logo/main/Logo_Symbiose_MANAGEMENT_arbre_blank.svg';
import retailBackground from '../../img/forest/retail-bg.jpg';
import retailBackgroundTiny from '../../img/forest/retail-bg-tiny.jpg';
import businessBackground from '../../img/forest/business-bg.jpg';
import businessBackgroundTiny from '../../img/forest/business-bg-tiny.jpg';
import { symbioseTheme } from "../../App";
import { RiInstagramFill } from "react-icons/ri";
import ProgressiveImage from "react-progressive-graceful-image";

export default function Home() {
  
  const navigate = useNavigate()
  const cookies = new Cookies()
  const { colorMode } = useColorMode()
  const [isLargerThan1024] = useMediaQuery('(min-width: 1024px)')
  const [isLargerThan512] = useMediaQuery('(min-width: 512px)')
  const [isLargerThan350] = useMediaQuery('(min-width: 350px)')
  const { t, i18n } = useTranslation()

  document.title = formatDocumentTitle(t('homePageTitle'))

  useEffect(() => {
    gaPageView(cookies)  
  })

  
  const splitHeight = isLargerThan512 ? '600px' : '300px'
  const splitButtonFontSize = isLargerThan1024 ? 'lg' : (isLargerThan512 ? 'md' :  (isLargerThan350 ? 'sm' : 'xs'))

  const sectionHeight = isLargerThan512 ? '400px' : '200px'

  const dataBrickSize = isLargerThan512 ? '260px' : '150px'
  const dataBrickMargin = isLargerThan512 ? 10 : 2
  const dataBrickPadding = isLargerThan512 ? 5 : 2
  const dataBrickTitleSize = isLargerThan512 ? '100' : '50'
  const dataBrickMsgSize = isLargerThan512 ? 'sm' : '2xs'


  const ourStuffTitleSize = isLargerThan512 ? '60' : '3xl'
  const ourStuffMsgSize = isLargerThan512 ? 'xl' : 'md'
  const communityIconSize = isLargerThan1024 ? 50 : (isLargerThan512 ? 50 : (isLargerThan350 ? 35 : 30))

  const companyLogoBoxSize = isLargerThan512 ? '200px' : '100px'
  const companyNameSize = isLargerThan1024 ? '5xl' : (isLargerThan512 ? '5xl' : (isLargerThan350 ? '2xl' : 'xl'))
  const companyQuoteSize = isLargerThan512 ? 'xl' : 'md'
  
  return (
    <>
    {getBackgroundComponent()}
    <Header />
    <Flex flexDirection="column" alignItems="center" justifyContent="center" textAlign="center" width="100%">
      {isLargerThan512 ?
        <HStack w='100%' h={splitHeight} alignItems="center" justifyContent="center" spacing={0}>
          <Flex position='relative' flexDirection="column" w='50%' h='100%'>
            <ProgressiveImage
            src={retailBackground} placeholder={retailBackgroundTiny}
            >
              {(src, loading) => (
                <img style={{ opacity: loading ? 1 : 1, objectFit: 'cover', zIndex: -1, position: 'absolute', height: '100%', width: '100%'}}  src={src} alt="Retail" />
              )}
            </ProgressiveImage>
            <Box flexGrow={1} />
            <Box textAlign='left' m={10}>
              <Text fontWeight='bold'><span style={{backgroundColor: symbioseTheme.colors.textBackgroundColor, padding: 5}}>{t('homePageSplitRetailTitle')}</span></Text>
              <Text><span style={{backgroundColor: symbioseTheme.colors.textBackgroundColor, padding: 5}}>{t('homePageSplitRetailMsg')}</span></Text>
              <Box h='20px'/>
              <a href="/about-retail">
                <Button variant='outline' fontSize={splitButtonFontSize}>{t('homePageSplitRetailButton')}</Button>
              </a>
            </Box>
            <Box h='5%'/>
          </Flex>
          <Flex position='relative'  flexDirection="column" w='50%' h='100%'>
            <ProgressiveImage
              src={businessBackground} placeholder={businessBackgroundTiny}
              >
                {(src, loading) => (
                  <img style={{ opacity: loading ? 1 : 1, objectFit: 'cover', zIndex: -1, position: 'absolute', height: '100%', width: '100%'}}  src={src} alt="Business" />
                )}
            </ProgressiveImage>
            <Box flexGrow={1} />
            <Box textAlign='right' m={10}>
              <Text fontWeight='bold'><span style={{backgroundColor: symbioseTheme.colors.textBackgroundColor, padding: 5}}>{t('homePageSplitProTitle')}</span></Text>
              <Text><span style={{backgroundColor: symbioseTheme.colors.textBackgroundColor, padding: 5}}>{t('homePageSplitProMsg')}</span></Text>
              <Box h='20px'/>
              <a href="/about-business">
                <Button variant='outline' fontSize={splitButtonFontSize}>{t('homePageSplitProButton')}</Button>
              </a>
            </Box>
            <Box h='5%'/>
          </Flex>
        </HStack>
        :
        <VStack w='100%' spacing={0} bg={symbioseTheme.colors.textBackgroundColor}>
          <VStack w='100%' h={splitHeight} backgroundImage={retailBackground} backgroundPosition='center' alignItems="center" justifyContent="center">
            <VStack textAlign='center' w='100%' p={3} bg={symbioseTheme.colors.textBackgroundColor}>
              <Text fontWeight='bold'>{t('homePageSplitRetailTitle')}</Text>
              <Text fontWeight='md' lineHeight={7} w='100%'><span>{t('homePageSplitRetailMsg')}</span></Text>
              <a href="/about-retail">
                <Button variant='outline' fontSize={splitButtonFontSize}>{t('homePageSplitRetailButton')}</Button>
              </a>
            </VStack>
          </VStack>
          <VStack w='100%' h={splitHeight} backgroundImage={businessBackground} backgroundPosition='center' alignItems="center" justifyContent="center">
            <VStack textAlign='center' w='100%' p={3} bg={symbioseTheme.colors.textBackgroundColor}>
              <Text fontWeight='bold'>{t('homePageSplitProTitle')}</Text>
              <Text fontWeight='md' lineHeight={7} w='100%'>{t('homePageSplitProMsg')}</Text>
              <a href="/about-business">
                <Button variant='outline' fontSize={splitButtonFontSize}>{t('homePageSplitProButton')}</Button>
              </a>
            </VStack>
          </VStack>
        </VStack>
      }
      <VStack w='100%' minHeight={sectionHeight} alignItems="center" justifyContent="center">
        <Flex w='100%' flexDirection="row" alignItems="center" justifyContent="center" flexWrap='wrap'>
          <VStack bg={symbioseTheme.colors.textBackgroundColor} borderRadius={5} alignItems="center" justifyContent="center" w={dataBrickSize} h={dataBrickSize} margin={dataBrickMargin} p={dataBrickPadding}>
            <Text fontSize={dataBrickTitleSize} fontWeight='bold'>{t('homePageDataBrick1Data')}</Text>
            <Text fontSize={dataBrickMsgSize}><Trans>{t('homePageDataBrick1Msg')}</Trans></Text>
          </VStack>
          <VStack bg={symbioseTheme.colors.textBackgroundColor} borderRadius={5} alignItems="center" justifyContent="center" w={dataBrickSize} h={dataBrickSize} margin={dataBrickMargin} p={dataBrickPadding}>
            <Text fontSize={dataBrickTitleSize} fontWeight='bold'>{t('homePageDataBrick2Data')}</Text>
            <Text fontSize={dataBrickMsgSize}><Trans>{t('homePageDataBrick2Msg')}</Trans></Text>
          </VStack>
          <VStack bg={symbioseTheme.colors.textBackgroundColor} borderRadius={5} alignItems="center" justifyContent="center" w={dataBrickSize} h={dataBrickSize} margin={dataBrickMargin} p={dataBrickPadding}>
            <Text fontSize={dataBrickTitleSize} fontWeight='bold'>{t('homePageDataBrick3Data')}</Text>
            <Text fontSize={dataBrickMsgSize}><Trans>{t('homePageDataBrick3Msg')}</Trans></Text>
          </VStack>
        </Flex>
      </VStack>
      <Flex flexDirection="column" w='100%' minHeight={sectionHeight} p={5} bg={symbioseTheme.colors.textBackgroundColor} alignItems="center" justifyContent="center">
        <Box flexGrow={1} />
        <Text fontSize={ourStuffTitleSize} fontWeight='bold'>{t('homePageOurVisionTitle')}</Text>
        <Box flexGrow={1} />
        <Text fontSize={ourStuffMsgSize}>{t('homePageOurVisionMsg1')}</Text>
        <Box flexGrow={1} minH={3} />
        <Text fontSize={ourStuffMsgSize}>{t('homePageOurVisionMsg2')}</Text>
        <Box flexGrow={1} minH={3} />
        <Button variant='outline'>
          <Link href={i18n.language.toUpperCase().includes('FR') ? 'https://www.myfairmoney.fr/plus-dinformations-sur-les-fonds' : 'https://www.myfairmoney.eu/fund-database'} isExternal>{t('homePageOurVisionButton')}</Link>
        </Button>
        <Box flexGrow={1} />
      </Flex>
      <Flex flexDirection="column" w='100%' minHeight={sectionHeight} p={5} color={symbioseTheme.colors.headerTextColor} bg={symbioseTheme.colors.headerColor} alignItems="center" justifyContent="center">
        <Box flexGrow={1} />
        <Text color={symbioseTheme.colors.textBackgroundColor} fontSize={ourStuffTitleSize} fontWeight='bold'>{t('homePageOurCharterTitle')}</Text>
        <Box flexGrow={1} />
        <Text fontSize={ourStuffMsgSize}>{t('homePageOurCharterMsg1')}</Text>
        <Box flexGrow={1} minH={3} />
        <Text fontSize={ourStuffMsgSize}>{t('homePageOurCharterMsg2')}</Text>
        <Box flexGrow={1} minH={3} />
        <a href="/charter">
          <Button variant='outline'>{t('homePageOurCharterButton')}</Button>
        </a>
        <Box flexGrow={1} />
      </Flex>
      <Flex flexDirection="column" w='100%' minHeight={sectionHeight} p={5} bg={symbioseTheme.colors.textBackgroundColor} alignItems="center" justifyContent="center">
        <Box flexGrow={1} />
        <Text fontSize={ourStuffTitleSize} fontWeight='bold'>{t('homePageCommunityTitle')}</Text>
        <Box flexGrow={1} minH={3}/>
        <HStack flexDirection='row' spacing={0}>
            <Link href='https://twitter.com/' isExternal><SiTwitter size={communityIconSize}/></Link>
            <Box w='30px'/>
            <Link href='https://fr.linkedin.com/company/symbiose-management' isExternal><BsLinkedin size={communityIconSize}/></Link>
            <Box w='30px'/>
            <Link href='https://www.instagram.com/' isExternal ><RiInstagramFill size={communityIconSize}/></Link>
            <Box w='30px'/>
            <Link href='mailto:contact@symbiose-management.com' isExternal ><MdAlternateEmail size={communityIconSize}/></Link>
        </HStack>
        <Box flexGrow={1} />
      </Flex>
      <Flex flexDirection="column" w='100%' minHeight={sectionHeight} p={2} color={symbioseTheme.colors.headerTextColor} bg={symbioseTheme.colors.headerColor} alignItems="center" justifyContent="center">
        <Box flexGrow={1} />
        <Icon as={symbioseSvg} boxSize={companyLogoBoxSize} style={{stroke: symbioseTheme.colors.headerTextColor, fill: symbioseTheme.colors.headerTextColor}}/>
        <Box flexGrow={1} />
        <HStack>
          <Text fontSize={companyNameSize} fontWeight='medium'>SYMBIOSE</Text>
          <Text fontSize={companyNameSize} fontWeight='bold'>MANAGEMENT</Text>
        </HStack>
        <Box flexGrow={1} />
        <Text as='i' fontSize={companyQuoteSize}>"{t('homePageFounderQuote')}"</Text>
        <Box flexGrow={1} />
      </Flex>      
    </Flex>
  </>
  )
}