import {
    useDisclosure,
    MenuItem,
    Menu,
    MenuButton,
    MenuList,
    MenuDivider,
    useColorModeValue,
    useMediaQuery,
    Flex,
    Text,
} from "@chakra-ui/react"
import { useNavigate } from 'react-router-dom'

import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons'
import Cookies from "universal-cookie"
import { useTranslation } from "react-i18next"
import { symbioseTheme } from "../../App"
import { AiFillBank, AiFillSetting } from "react-icons/ai"
import { BiReceipt, BiUser } from "react-icons/bi"
import { MdBusiness } from "react-icons/md"
import { ImEarth, ImOffice } from "react-icons/im"
import { BsBuilding } from "react-icons/bs"

interface Props {
    accountMenu? : boolean
}

export default function Navbar({accountMenu = true} : Props) {

    const navigate = useNavigate()
    const cookies = new Cookies()
    const { t, i18n } = useTranslation() 
    const menuButtonColor = symbioseTheme.colors.headerTextColor
    const menuFontSize = 'lg'
    const menuOpacity = 0.90
    const menuItemHoverColor = symbioseTheme.colors.headerTextColor

    return (
        <Flex flexDirection="row" flexWrap='wrap' fontSize='md' fontWeight='bold'>
            {accountMenu ?
            <>
                <Menu >
                    <a href="/">
                        <MenuButton
                            color={menuButtonColor}
                            fontSize={menuFontSize}
                            mx={1}
                            py={[1, 2, 2]}
                            px={4}
                            borderRadius={5}
                            aria-label="Options"
                            //bg='transparent'
                            opacity={menuOpacity}
                            _hover={{opacity: 1}}
                        >
                        {t('navbarHome')}
                        </MenuButton>
                    </a>
                </Menu>
                <Menu>
                    <MenuButton
                        color={menuButtonColor}
                        fontSize={menuFontSize}
                        mx={1}
                        py={[1, 2, 2]}
                        px={4}
                        borderRadius={5}
                        aria-label="Options"
                        bg='transparent'
                        opacity={menuOpacity}
                        _hover={{opacity: 1}}
                    >
                        {t('navbarAccount')} {<ChevronDownIcon />}
                    </MenuButton>
                    <MenuList boxShadow='lg' border='none' color={menuButtonColor}>
                        <MenuItem opacity={menuOpacity} _hover={{opacity: 1, color: menuItemHoverColor}} onClick={() => navigate('/portfolio')}>
                            <AiFillBank size={25}/>
                            <Text ml='20px' fontSize={menuFontSize} w='100%'>{t('navbarAccountOverview')} </Text>
                        </MenuItem>
                        <MenuItem opacity={menuOpacity} _hover={{opacity: 1, color: menuItemHoverColor}} onClick={() => navigate('/invoices')}>
                            <BiReceipt size={25}/>
                            <Text ml='20px' fontSize={menuFontSize} w='100%'>{t('navbarAccountInvoices')} </Text>
                        </MenuItem>
                        <MenuItem opacity={menuOpacity} _hover={{opacity: 1, color: menuItemHoverColor}} onClick={() => navigate('/settings')}>
                            <AiFillSetting size={25}/>
                            <Text ml='20px' fontSize={menuFontSize} w='100%'>{t('navbarAccountSettings')} </Text>
                        </MenuItem>
                    </MenuList>
                </Menu>
                <Menu >
                    <a href="/marketplace">
                        <MenuButton
                            color={menuButtonColor}
                            fontSize={menuFontSize}
                            mx={1}
                            py={[1, 2, 2]}
                            px={4}
                            borderRadius={5}
                            aria-label="Options"
                            //bg='transparent'
                            opacity={menuOpacity}
                            _hover={{opacity: 1}}
                        >
                        {t('navbarMarketplace')}
                        </MenuButton>
                    </a>
                </Menu>
                <Menu >
                    <a href="/blog">
                        <MenuButton
                            color={menuButtonColor}
                            fontSize={menuFontSize}
                            mx={1}
                            py={[1, 2, 2]}
                            px={4}
                            borderRadius={5}
                            aria-label="Options"
                            //bg='transparent'
                            opacity={menuOpacity}
                            _hover={{opacity: 1}}
                        >
                        {t('navbarBlog')}
                        </MenuButton>
                    </a>
                </Menu>
            </>
            :
            <>
                <Menu >
                    <a href="/">
                        <MenuButton
                            color={menuButtonColor}
                            fontSize={menuFontSize}
                            mx={1}
                            py={[1, 2, 2]}
                            px={4}
                            borderRadius={5}
                            aria-label="Options"
                            //bg='transparent'
                            opacity={menuOpacity}
                            _hover={{opacity: 1}}
                        >
                        {t('navbarHome')}
                        </MenuButton>
                    </a>
                </Menu>                
                <Menu >
                    <MenuButton
                        color={menuButtonColor}
                        fontSize={menuFontSize}
                        mx={1}
                        py={[1, 2, 2]}
                        px={4}
                        borderRadius={5}
                        aria-label="Options"
                        bg='transparent'
                        opacity={menuOpacity}
                        _hover={{opacity: 1}}
                    >
                        {t('navbarAbout')} {<ChevronDownIcon />}
                    </MenuButton>
                    <MenuList boxShadow='lg' border='none' color={menuButtonColor}>
                        <a href="/about-retail">
                            <MenuItem opacity={menuOpacity} _hover={{opacity: 1, color: menuItemHoverColor}}>
                                <BiUser size={25}/>
                                <Text ml='20px' fontSize={menuFontSize} w='100%'>{t('navbarAboutRetail')} </Text>
                            </MenuItem>
                        </a>
                        <a href="/about-business">
                            <MenuItem opacity={menuOpacity} _hover={{opacity: 1, color: menuItemHoverColor}}>
                                <BsBuilding size={25}/>
                                <Text ml='20px' fontSize={menuFontSize} w='100%'>{t('navbarAboutPro')} </Text>
                            </MenuItem>
                        </a>
                        <a href="/charter">
                            <MenuItem opacity={menuOpacity} _hover={{opacity: 1, color: menuItemHoverColor}}>
                                <ImEarth size={25}/>
                                <Text ml='20px' fontSize={menuFontSize} w='100%'>{t('navbarAboutCharter')} </Text>
                            </MenuItem>
                        </a>
                    </MenuList>
                </Menu>
                <Menu >
                    <a href="/blog">
                        <MenuButton
                            color={menuButtonColor}
                            fontSize={menuFontSize}
                            mx={1}
                            py={[1, 2, 2]}
                            px={4}
                            borderRadius={5}
                            aria-label="Options"
                            //bg='transparent'
                            opacity={menuOpacity}
                            _hover={{opacity: 1}}
                        >
                        {t('navbarBlog')}
                        </MenuButton>
                    </a>
                </Menu>
            </>
            }
        </Flex>
    )
}