import { ReactNode, useEffect, useState } from "react";
import { Box, Flex,Text, Icon, useColorModeValue, Link, useMediaQuery, Button, Stack, InputGroup, InputLeftElement, InputRightElement, InputLeftAddon, background, VStack, Divider, Center, Tooltip, HStack, FormLabel, FormControl, FormHelperText, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, useDisclosure, Input, useToast } from "@chakra-ui/react";
import { CheckIcon, EmailIcon, LockIcon, PhoneIcon, QuestionOutlineIcon, ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import { axiosConfig, errorToast, formatDocumentTitle, getBackgroundComponent, isHttpCodeInRange, parseAxiosError, successToast } from "../../../lib/utils";
import { symbioseTheme } from "../../../App";
import axios from "axios";
import Cookies from 'universal-cookie';
import { URL_ACCOUNT_LOGIN, URL_ACCOUNT_OTA, URL_ACCOUNT_REGISTER, URL_ACCOUNT_REQUEST_RESET_PWD, URL_ACCOUNT_UPDATE } from "../../../constants";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Footer from "../../common/Footer";
import BeatLoader from "react-spinners/BeatLoader";
import Email from "../../common/Email";
import { MdArrowBack } from "react-icons/md";
import Message from "../../common/Message";

const htmlId = function(id : string) {
  return "REQUESTRESETPASSWORD-" + id
}

const EMAIL = htmlId("email")


//TODO: not used
export default function RequestResetPassword() {

  const navigate = useNavigate()
  const cookies = new Cookies()
  const { t, i18n } = useTranslation()
  const [isLargerThan1024] = useMediaQuery('(min-width: 1024px)')
  const toast = useToast()
  
  const [buttonClicked, setButtonClicked] = useState(false)
  const [requestSent, setRequestSent] = useState<boolean>(false)
  
  document.title = formatDocumentTitle(t('requestResetPasswordTitle'))

  useEffect(() => {
    if(cookies.get('account'))
      navigate('/')
  }, [])

 
  const requestPasswordReset = async function() {
    
    const email = document.getElementById(EMAIL) as HTMLInputElement
    if(!email.value || email.value.trim().length <= 0) {
      toast(errorToast(null, t('requestResetPasswordInvalidEmail')))
      return
    }

    setButtonClicked(true)
    try {      

      const response = await axios.post(URL_ACCOUNT_REQUEST_RESET_PWD, {
        identifier: {email: email.value}
      }, axiosConfig(null, i18n))
      
      if(!isHttpCodeInRange(200, response.status)) 
        throw parseAxiosError(response)
      else {
        setRequestSent(true)
        cookies.set('account', null)
      }
    } catch(error) {
      console.error(error)
      toast(errorToast(null, typeof error == 'string' ? error : t('genericError')))
    } finally {
      setButtonClicked(false)
    }
  }



  const containerWidth = isLargerThan1024 ? "80%" : "95%"
  const containerColor = symbioseTheme.colors.headerTextColor
  const containerBg = symbioseTheme.colors.headerColor

  return (
    <>
    {getBackgroundComponent()}
    <Flex flexDirection="column" alignItems="center" justifyContent="center" textAlign="center" width="100%" h="100%">
      {requestSent ?
      <Message _color={containerColor} _bg={containerBg} message={t('requestResetPasswordEmailSent')}/>
      :
      <VStack w={containerWidth} color={containerColor} bg={containerBg}>
        <a href="/">
          <HStack w='30%'>
            <MdArrowBack /> 
            <Text color={containerColor}><u><i>{t('backToHome')}</i></u></Text>
          </HStack>
        </a>
        
        <VStack py='40px' spacing={4}>
          <Text><b><i>{t('resetPasswordHeaderMessage')}</i></b></Text>
          <Email emailId={EMAIL} _color={containerColor} />
          <Box />
          <Button isLoading={buttonClicked} disabled={buttonClicked} spinner={<BeatLoader size={8} />} onClick={requestPasswordReset}>{t('loginSignInButton')}</Button>
        </VStack>     
      </VStack>
      }        
    </Flex>
    <Footer />
    </>
  )
}