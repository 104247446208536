import { ReactNode, useEffect, useState } from "react";
import { Box, Flex, Text, Icon, useColorModeValue, Image, VStack, HStack, Link, useColorMode, Button, useMediaQuery, Table, Tbody, Tr, Td, Accordion, AccordionItem, AccordionIcon, AccordionPanel, AccordionButton, useToast } from "@chakra-ui/react";

import Header from "../../common/Header";
import { axiosConfig, errorToast, formatDocumentTitle, getBackgroundComponent, getHomeBackgroundComponent, isHttpCodeInRange, parseAxiosError, string2NullIfEmpty, successToast, tryClearInput, tryGetClientIp } from "../../../lib/utils";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import { useTranslation } from "react-i18next";
import { Trans } from 'react-i18next'
import { gaPageView } from "../../../lib/utils";

import charterBackground from '../../../img/forest/charter-bg.jpg';
import charterBackgroundTiny from '../../../img/forest/charter-bg-tiny.jpg';
import { symbioseTheme } from "../../../App";
import { GiEarthAfricaEurope, GiEarthAmerica, GiEcology, GiForest, GiReceiveMoney } from "react-icons/gi";
import { ImEarth } from "react-icons/im";
import { MdAccountBalance, MdSavings } from "react-icons/md";
import { BsFillTreeFill } from "react-icons/bs";
import { RiPlantLine } from "react-icons/ri";
import ProgressiveImage from "react-progressive-graceful-image";
import axios from "axios";
import { URL_SEND_CONTACT_FORM } from "../../../constants";


const htmlId = function(id : string) {
  return "CHARTER-" + id
}


export default function Charter() {
  
  const navigate = useNavigate()
  const cookies = new Cookies()
  const { colorMode } = useColorMode()
  const [isLargerThan1024] = useMediaQuery('(min-width: 1024px)')
  const [isLargerThan512] = useMediaQuery('(min-width: 512px)')
  const { t, i18n } = useTranslation()
  const toast = useToast()
  const [processing, setProcessing] = useState(false)

  document.title = formatDocumentTitle(t('navbarAboutCharter'))

  useEffect(() => {
    gaPageView(cookies)
  }, [])
   

  
  const forestHeight = isLargerThan512 ? '600px' : '300px'
  const forestTitleFontSize = isLargerThan512 ? '3xl' : 'xl'
  const forestFontSize = isLargerThan512 ? 'xl' : 'sm'
  const forestFontWeight = isLargerThan512 ? 'bold' : 'normal'
  const forestMargin = isLargerThan512 ? 10 : 0
  const forestPadding = isLargerThan512 ? 3 : 3

  const sectionHeight = isLargerThan512 ? '400px' : '200px'
  const sectionPadding = isLargerThan512 ? 5 : 2
  const sectionTitleSize = isLargerThan512 ? '60' : '3xl'
  const sectionMsgSize = isLargerThan512 ? 'xl' : 'md'
  const sectionMinSpacing = isLargerThan512 ? 10  : 3

  const charterWidth = isLargerThan1024 ? '1000px' : '90%'
  const charterHeight = isLargerThan512 ? '200px' : '200px'
  const charterInnerBoxSeparatorWidth = isLargerThan1024 ? 100 : 0
  const charterBoxSeparatorHeight = isLargerThan1024 ? 50 : 0
  const charterNumFontSize = isLargerThan512 ? 100 : 70

  const contactFormSectionWidth = isLargerThan512 ? 500 : '90%'
  
   return (
    <>
    {getBackgroundComponent()}
    <Header />
    <Flex flexDirection="column" alignItems="center" justifyContent="center" textAlign="center" width="100%">
      <VStack position='relative' spacing={5} w='100%' h={forestHeight} alignItems="center" justifyContent="center">
        <ProgressiveImage 
          src={charterBackground} placeholder={charterBackgroundTiny}
          >
          {(src, loading) => (
            <img style={{ opacity: loading ? 1 : 1, objectFit: 'cover', zIndex: -1, position: 'absolute', width: '100%', height: forestHeight}}  src={src} alt="Retail" />
          )}
        </ProgressiveImage>
        <Flex flexDirection="column" m={forestMargin} p={forestPadding} bg={symbioseTheme.colors.textBackgroundColor} fontSize={forestFontSize} maxW={isLargerThan1024 ? '1024px' : ''}>
          <Box flexGrow={1} minH={sectionMinSpacing} />
          <Text fontSize={forestTitleFontSize} fontWeight={forestFontWeight} w='100%'>{t('charterPageTopTitle')}</Text>
          {isLargerThan512 && 
          <>
            <Box flexGrow={1} minH={sectionMinSpacing} />
            <Text fontWeight={forestFontWeight} w='100%'>{t('charterPageTopMsg1')}</Text>
          </>
          }
          <Box flexGrow={1} minH={sectionMinSpacing} />
          <Text fontWeight={forestFontWeight} w='100%'>{t('charterPageTopMsg2')}</Text>
          <Box flexGrow={1} minH={sectionMinSpacing} />
        </Flex>
      </VStack>
      <Flex flexDirection="column" w='100%' minHeight={sectionHeight} p={sectionPadding} color={symbioseTheme.colors.textBackgroundColor} bg={symbioseTheme.colors.headerColor} alignItems="center" justifyContent="center">
        <Box flexGrow={1} />
        <Text fontSize={sectionTitleSize} fontWeight='bold'>{t('charterPageSection1Title')}</Text>
        <Box flexGrow={1} minH={sectionMinSpacing} />
        <Text fontSize={sectionMsgSize}>
          <Trans
            i18nKey="charterPageSection1Msg0"
            components={{
                link1: <a href='https://www.wwf.fr/' target="_blank" title="WWF France" />,
                link2: <a href='https://www.canopee-asso.org/' target="_blank" title="Canopée" />
            }}
          />
        </Text>
        <Box flexGrow={1} minH={sectionMinSpacing} />
        <Flex flexDirection="row" flexWrap={isLargerThan1024 ? 'nowrap' : 'wrap'} w={charterWidth} minH={charterHeight} p={sectionPadding} alignItems="center" justifyContent="center">
          <VStack alignItems="center" justifyContent="center">
            <Text fontSize={charterNumFontSize} fontWeight={'extrabold'}>01.</Text>
          </VStack>
          <Box w={charterInnerBoxSeparatorWidth}/>
          <VStack  flexGrow={1} alignItems="center" justifyContent="center" textAlign="left">
            <Text fontSize={sectionMsgSize} color={symbioseTheme.colors.headerTextColor}>{t('charterPageSection1Msg1.1')}</Text>
            <Text fontSize={sectionMsgSize} fontWeight='bold'>{t('charterPageSection1Msg1.2')}</Text>
          </VStack>
        </Flex>
        <Box h={charterBoxSeparatorHeight} />
        <Flex flexDirection="row" flexWrap={isLargerThan1024 ? 'nowrap' : 'wrap-reverse'} w={charterWidth} minH={charterHeight} p={sectionPadding} alignItems="center" justifyContent="center">
          <VStack flexGrow={1} alignItems="center" justifyContent="center" textAlign="left">
            <Text fontSize={sectionMsgSize} color={symbioseTheme.colors.headerTextColor}>{t('charterPageSection1Msg2.1')}</Text>
            <Text fontSize={sectionMsgSize} fontWeight='bold'>{t('charterPageSection1Msg2.2')}</Text>
          </VStack>
          <Box w={charterInnerBoxSeparatorWidth}/>
          <VStack alignItems="center" justifyContent="center">
            <Text fontSize={charterNumFontSize} fontWeight={'extrabold'}>02.</Text>
          </VStack>
        </Flex>
        <Box h={charterBoxSeparatorHeight} />
        <Flex flexDirection="row" flexWrap={isLargerThan1024 ? 'nowrap' : 'wrap'} w={charterWidth} minH={charterHeight} p={sectionPadding} alignItems="center" justifyContent="center">
          <VStack alignItems="center" justifyContent="center">
            <Text fontSize={charterNumFontSize} fontWeight={'extrabold'}>03.</Text>
          </VStack>
          <Box w={charterInnerBoxSeparatorWidth}/>
          <VStack  flexGrow={1} alignItems="center" justifyContent="center" textAlign="left">
            <Text fontSize={sectionMsgSize} color={symbioseTheme.colors.headerTextColor}>{t('charterPageSection1Msg3.1')}</Text>
            <Text fontSize={sectionMsgSize} fontWeight='bold'>{t('charterPageSection1Msg3.2')}</Text>
          </VStack>
        </Flex>
        <Box h={charterBoxSeparatorHeight} />
        <Flex flexDirection="row" flexWrap={isLargerThan1024 ? 'nowrap' : 'wrap-reverse'} w={charterWidth} minH={charterHeight} p={sectionPadding} alignItems="center" justifyContent="center">
          <VStack flexGrow={1} alignItems="center" justifyContent="center" textAlign="left">
            <Text fontSize={sectionMsgSize} color={symbioseTheme.colors.headerTextColor}>{t('charterPageSection1Msg4.1')}</Text>
            <Text fontSize={sectionMsgSize} fontWeight='bold'><Trans>{t('charterPageSection1Msg4.2')}</Trans></Text>
          </VStack>
          <Box w={charterInnerBoxSeparatorWidth}/>
          <VStack alignItems="center" justifyContent="center">
            <Text fontSize={charterNumFontSize} fontWeight={'extrabold'}>04.</Text>
          </VStack>
        </Flex>
        <Box h={charterBoxSeparatorHeight} />
        <Flex flexDirection="row" flexWrap={isLargerThan1024 ? 'nowrap' : 'wrap'} w={charterWidth} minH={charterHeight} p={sectionPadding} alignItems="center" justifyContent="center">
          <VStack alignItems="center" justifyContent="center">
            <Text fontSize={charterNumFontSize} fontWeight={'extrabold'}>05.</Text>
          </VStack>
          <Box w={charterInnerBoxSeparatorWidth}/>
          <VStack  flexGrow={1} alignItems="center" justifyContent="center" textAlign="left">
            <Text fontSize={sectionMsgSize} color={symbioseTheme.colors.headerTextColor}>{t('charterPageSection1Msg5.1')}</Text>
            <Text fontSize={sectionMsgSize} fontWeight='bold'>{t('charterPageSection1Msg5.2')}</Text>
          </VStack>
        </Flex>
        <Box flexGrow={1} minH={sectionMinSpacing} />
      </Flex>
      <Flex flexDirection="column" w='100%' minHeight={sectionHeight} p={sectionPadding} color={symbioseTheme.colors.textColor} bg={symbioseTheme.colors.textBackgroundColor} alignItems="center" justifyContent="center">
        <Box flexGrow={1} />
        <Text fontSize={sectionTitleSize}>{t('charterPageSection2Title')}</Text>
        <Box flexGrow={1} minH={sectionMinSpacing} />
        <Text fontSize={sectionMsgSize}>{t('charterPageSection2Msg')}</Text>
        <Box flexGrow={1} minH={sectionMinSpacing} />
        <Box w={contactFormSectionWidth}>
          <a href="/contact">
            <Button variant='outline'>{t('contactButton')}</Button>
          </a>
        </Box>        
        <Box flexGrow={1} minH={100}/>
      </Flex>
    </Flex>
  </>
  )
}