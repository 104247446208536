import { ReactNode, useEffect, useState } from "react";
import { Box, Flex, Text, Icon, useColorModeValue, Image, VStack, HStack, Link, useColorMode, Button, useMediaQuery, Table, Tbody, Tr, Td, Accordion, AccordionItem, AccordionIcon, AccordionPanel, AccordionButton, position, FormControl, FormLabel, Input, FormHelperText, useToast } from "@chakra-ui/react";

import Header from "../../common/Header";
import { axiosConfig, errorToast, warningToast, formatDocumentTitle, getBackgroundComponent, getHomeBackgroundComponent, isHttpCodeInRange, parseAxiosError, string2NullIfEmpty, successToast, tryClearInput, tryGetClientIp } from "../../../lib/utils";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import Cookies from "universal-cookie";
import ReactGA from "react-ga4";
import { useTranslation } from "react-i18next";
import { Trans } from 'react-i18next'

import { gaPageView } from "../../../lib/utils";
import businessBackground from '../../../img/forest/business-bg.jpg';
import businessBackgroundTiny from '../../../img/forest/business-bg-tiny.jpg';
import {ReactComponent as symbioseSvg} from '../../../img/logo/main/Logo_Symbiose_MANAGEMENT_arbre_blank.svg';
import { symbioseTheme } from "../../../App";
import Loading from "../../common/Loading";
import { GiEarthAfricaEurope, GiEarthAmerica, GiEcology, GiForest, GiGreenhouse, GiReceiveMoney } from "react-icons/gi";
import { ImEarth, ImNewspaper, ImUserTie } from "react-icons/im";
import { MdAccountBalance, MdSavings } from "react-icons/md";
import { BsFillFileEarmarkBarGraphFill, BsFillTreeFill } from "react-icons/bs";
import { RiPlantLine } from "react-icons/ri";
import ProgressiveImage from "react-progressive-graceful-image";
import PreviewCard from "./PreviewCard";
import static_article from "../../../article.json"
import axios from "axios";
import { URL_BLOG_ARTICLE, URL_BLOG_PREVIEW } from "../../../constants";


import blogBackground from '../../../img/forest/blog-bg.jpg';
import blogBackgroundTiny from '../../../img/forest/blog-bg-tiny.jpg';
import PageNavigation from "../../common/PageNavigation";

const htmlId = function(id : string) {
  return "BLOGPOST-" + id
}

export default function BlogPost() {
  
  const navigate = useNavigate()
  const cookies = new Cookies()
  const location = useLocation()
  const { colorMode } = useColorMode()
  const [isLargerThan1024] = useMediaQuery('(min-width: 1024px)')
  const [isLargerThan512] = useMediaQuery('(min-width: 512px)')
  const [isLargerThan360] = useMediaQuery('(min-width: 360px)')
  const { t, i18n } = useTranslation()
  const toast = useToast()
  const [searchParams, setSearchParams] = useSearchParams();
  const [processing, setProcessing] = useState(true)
  const [article, setArticle] = useState<any | null>(null)
  

  const hasAccount = cookies.get('account') ? true : false
  document.title = formatDocumentTitle(t('blogPageTitle'))


  const loadArticle = async (alias: string) => {
    setProcessing(true)
    try {
      const articleResponse = await axios.post(URL_BLOG_ARTICLE, {alias: alias}, axiosConfig(null, i18n))
      if(!isHttpCodeInRange(200, articleResponse.status)) 
        throw parseAxiosError(articleResponse)
      const _article = articleResponse.data
      if(Object.keys(_article).length > 0) {
        setArticle(_article)
      } else {
        toast(errorToast(null, t('blogArticleNotFound')))  
      }
    } catch(error) {
      console.error(error)
      toast(errorToast(null, typeof error == 'string' ? error : t('genericError')))
    } finally {
      setProcessing(false)
    }
  }

  useEffect(() => {   
    gaPageView(cookies)
    try{
      let articleAlias = location.pathname.slice(location.pathname.lastIndexOf("/") + 1, location.pathname.length) ;
      loadArticle(articleAlias)
    } catch(ex) {
      console.error("Failed to parse article alias")
      toast(errorToast(null, t('blogArticleNotFound')))  
    } finally {
      
    }
  }, [])
  

  const bannerFontSize = isLargerThan512 ? '6xl' :  (isLargerThan360 ? '4xl' : '3xl')
  const bannerFontWeight = isLargerThan512 ? 'bold' : 'normal'
  const bannerHeight = isLargerThan512 ? '300px' : '200px'
  const bannerMargin = isLargerThan512 ? 10 : 0
  const bannerPadding = isLargerThan512 ? 3 : 3

  const articleMinHeight = isLargerThan512 ? '400px' : '200px'
  const articleMaxWidth = isLargerThan1024 ? '768' : '512'
  const articleTitleSize = isLargerThan512 ? '60' : '3xl'
  const articleSubTitleSize = isLargerThan512 ? '17' : 'xl'
  const articleSubSubTitleSize = isLargerThan512 ? '13' : 'md'
  
  const previewWidth = isLargerThan1024 ? '80%' : '70%'

  const maxWidth = article ? articleMaxWidth : '100%'
  const separatorSpacing = 10
  const topPadding = isLargerThan512 ? 5 : 2
  
  
  return (
    <>
    {getBackgroundComponent()}
    <Header />
    <Flex flexDirection="column" alignItems="center" justifyContent="center" width="100%">
        
      <Flex flexDirection="column" w='100%' maxW={maxWidth} minHeight={articleMinHeight} p={topPadding} color={symbioseTheme.colors.headerTextColor} bg={symbioseTheme.colors.headerColor} alignItems="center" justifyContent="center">
        <Box flexGrow={1} />
        {processing ?
          <>
            <Box minH={150} />
            <Loading message=" " />
          </>
          :
          <>
            <Text fontSize={articleTitleSize} fontWeight='bold' lineHeight={1} m={bannerMargin}>{article?.article.content.title}</Text>
            <Box h={10} />
            <Text fontSize={articleSubTitleSize} color={symbioseTheme.colors.textBackgroundColor} as='i' w='100%'><Trans>{article?.article?.writer ? t('blogPublishedBy', {author: article?.article?.writer}) as any : ""}</Trans></Text>
            <Text fontSize={articleSubSubTitleSize} color={symbioseTheme.colors.textBackgroundColor} w='100%'>
              {t('blogPublishDate', {date: new Date((article?.article?.creationTime as string).replace('[UTC]','')).toLocaleDateString()}) as any}
              {new Date((article?.article?.creationTime as string).replace('[UTC]','')).toLocaleDateString() != new Date((article?.article?.updateTime as string).replace('[UTC]','')).toLocaleDateString() ?
                t('blogUpdateDate', {date: new Date((article?.article?.updateTime as string).replace('[UTC]','')).toLocaleDateString()}) as any
              :
                <></>
              }
            </Text>
            <Box flexGrow={1} minH={separatorSpacing} />
            <Box dangerouslySetInnerHTML={{ __html: article?.article?.content?.body }} style={{ "--color-link": '#0A66C2' } as React.CSSProperties}/>
            <Box flexGrow={1} minH={separatorSpacing} />
          </>          
        }        
        <Box flexGrow={1} minH={separatorSpacing} />
      </Flex>
    </Flex>
  </>
  )
}