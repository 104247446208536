import React, { Suspense, useEffect } from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
  Link
} from "react-router-dom";
import logo from './logo.svg';
import './App.css';
import { isPrivacyPolicyAgreed } from './lib/utils'
import ReactGA from "react-ga4";
import { ChakraProvider, useDisclosure, useColorMode, Box, Flex } from "@chakra-ui/react";
import Layout from './components/Layout';
import { mode } from '@chakra-ui/theme-tools'
import theme  from "./theme.js"
import Header from "./components/common/Header"
import Footer from "./components/common/Footer"
import Home from './components/pages/Home';
import Cookies from 'universal-cookie';
import HomeWrapper from './components/pages/HomeWrapper';
import Login from './components/pages/login/Login';
import axios from 'axios';
import Portfolio from './components/pages/account/portfolio/Portfolio';
import Marketplace from './components/pages/marketplace/Marketplace';
import NotFound from './components/pages/NotFound';
import OrderReview from './components/pages/payment/OrderReview';
import PaymentGateway from './components/pages/payment/PaymentGateway';
import Collect from './components/pages/collect/Collect';
import Invoice from './components/pages/invoice/Invoice';
import Invoices from './components/pages/invoice/Invoices';
import Settings from './components/pages/account/settings/Settings';
import ResetPassword from './components/pages/resetpwd/ResetPassword';
import RequestResetPassword from './components/pages/resetpwd/RequestResetPassword';
import ContactVerified from './components/pages/ContactVerified';
import AboutRetail from './components/pages/about/AboutRetail';
import AboutBusiness from './components/pages/about/AboutBusiness';
import Contact from './components/pages/contact/Contact';
import Charter from './components/pages/about/Charter';
import BlogPreview from './components/pages/blog/BlogPreview';
import BlogPost from './components/pages/blog/BlogPost';
import ScrollToTop from './components/common/ScrollToTop';




const headerTextColor = "#000000DE"
const textColor = "#F5F5F5"
const backgroundColor = textColor
const headerColor = "#F7F7F5"//"#5F9952"
const textBackgroundColor = "#556B2FEA"

export const symbioseTheme = {
  ...theme,
  config: {
    initialColorMode: 'light',
    useSystemColorMode: false
  },
  colors:{
    ...theme.colors,
    uniswap: "#191B1F",
    naturisLightBg: "#E0EBDC",
    naturisLightText1: "#3C3228",
    naturisDarkBg: "#405A4F",
    etGreen: "#5F9952",
    lmGreen: "#4FC114",
    darkGreen: "#233D22",
    green: "#2D5725",
    lightGreen: "#577D28",
    drawerGreen: "#5F8059",
    yellow: "#EDB753",
    lightYellow: "#ECC988",
    textColor: textColor,
    textBackgroundColor: textBackgroundColor,
    headerTextColor: headerTextColor,
    textColorRed: "#B12704 !important",
    backgroundColor: backgroundColor,
    headerColor: headerColor,
    importantButtonBackgroundColor: 'yellow !important',
    buttonColor: textBackgroundColor
  },  
  styles: {
    global: (props: any) => ({
      '*::placeholder': {
        color: mode('blue.400', 'orange.600')(props),
      },
      '*, *::before, &::after': {
        
        borderColor: mode(textColor, textColor)(props),
        wordWrap: 'break-word',
      },
      body: {
        fontFamily: 'system-ui,-apple-system,Segoe UI,Roboto,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji',
        color: mode(textColor, textColor)(props),
        bg: mode(backgroundColor, backgroundColor)(props),
        lineHeight: 'base',
        outline: 0,
        boxShadow: "none"
      },

    }),
  }
}

// Configure axios to not throw exception on response code != 200
axios.defaults.validateStatus = function () {
  return true;
};

function App() {
  
  useEffect(() => {   
    const cookies = new Cookies(); 
    if(isPrivacyPolicyAgreed(cookies)) {
      console.debug('pageview -> ' + (window.location.pathname + window.location.search))
      //ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search});
    }
      
    //console.debug(window.location.pathname + window.location.search)
   /* let userFontFamily : string | undefined | null = cookies.get('userFontFamily')
    if(userFontFamily) {
      console.info("Setting font family " + userFontFamily)
      document.body.style.fontFamily = userFontFamily
    }*/    
   }, []);
    


  return (
     // lets us use Chakra UI syntax across our app:
    <ChakraProvider theme={symbioseTheme} >
      <Suspense fallback={null}>
      <BrowserRouter>
      <ScrollToTop />
      <Flex flexDirection="column" h="100vh" /*w="100vw"*/ _focus={{boxShadow: "none"}} outline={0} >
        {/*<Header />*/}
        <Layout>
          <Routes>
            <Route path='/' element={<React.StrictMode><Home /></React.StrictMode>}/>
            <Route path='/marketplace' element={<React.StrictMode><Marketplace /></React.StrictMode>}/>
            <Route path='/about-retail' element={<AboutRetail/>}/>
            <Route path='/about-business' element={<AboutBusiness/>}/>
            <Route path='/charter' element={<Charter/>}/>
            <Route path='/contact' element={<Contact/>}/>
            <Route path='/blog' element={<BlogPreview />}/>
            <Route path='/blog/post/*' element={<BlogPost />}/>
            <Route path='/login' element={<React.StrictMode><Login /></React.StrictMode>}/>
            <Route path='/contact-verified' element={<React.StrictMode><ContactVerified /></React.StrictMode>}/>
            <Route path='/request-reset-pwd' element={<React.StrictMode><RequestResetPassword /></React.StrictMode>}/>
            <Route path='/reset-pwd' element={<React.StrictMode><ResetPassword /></React.StrictMode>}/>
            <Route path='/portfolio' element={<React.StrictMode><Portfolio /></React.StrictMode>}/>
            <Route path='/settings' element={<React.StrictMode><Settings /></React.StrictMode>}/>
            <Route path='/order' element={<React.StrictMode><OrderReview /></React.StrictMode>}/>
            <Route path='/payment-gateway' element={<PaymentGateway />}/>
            <Route path='/collect' element={<Collect />}/>
            <Route path='/invoice' element={<React.StrictMode><Invoice /></React.StrictMode>}/>
            <Route path='/invoices' element={<React.StrictMode><Invoices /></React.StrictMode>}/>
            <Route path='*' element={<React.StrictMode><NotFound /></React.StrictMode>}/>
          </Routes>
        </Layout>
        {/*<Footer />*/} 
      </Flex>
      </BrowserRouter>
      </Suspense>
    </ChakraProvider>
  );
}

export default App;
