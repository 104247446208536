import { ReactNode, useEffect, useState } from "react";
import { Box, Flex,Text, Icon, useColorModeValue, Link, useMediaQuery, Button, Stack, InputGroup, InputLeftElement, Input, InputRightElement, InputLeftAddon, background, VStack, Divider, Center, Tooltip, HStack, FormLabel, FormControl, FormHelperText, Tbody, Th, Tr, Thead, Table, Td, useToast, LinkBox, Image, layout } from "@chakra-ui/react";
import { CheckIcon, EmailIcon, LockIcon, PhoneIcon, QuestionOutlineIcon } from "@chakra-ui/icons";
import { axiosConfig, checkAuth, errorToast, formatDocumentTitle, formatPrice, getBackgroundComponent, getLanguageValue, isHttpCodeInRange, isValidCurrency, logout, parseAxiosError, successToast } from "../../../lib/utils";
import { symbioseTheme } from "../../../App";
import axios from "axios";
import Cookies from 'universal-cookie';
import { URL_ACCOUNT_INVOICES, URL_MARKETPLACE_CHECKOUT, URL_MARKETPLACE_COLLECT, URL_PAYMENT_GATEWAYS_INFO } from "../../../constants";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import Header from "../../common/Header";
import { Chart } from "react-google-charts";
import { GiCheckMark, GiConsoleController } from "react-icons/gi";
import { useTranslation } from "react-i18next";
import Loading from "../../common/Loading";
import CartItem from "../marketplace/CartItem";
import { off } from "process";
import PaymentItem from "../payment/PaymentItem";
import {loadStripe, StripeElementLocale, Appearance} from '@stripe/stripe-js';
import { PaymentElement} from '@stripe/react-stripe-js';
import {Elements} from '@stripe/react-stripe-js';
import StripeGateway from "../payment/StripeGateway";
import { ImFlickr } from "react-icons/im";
import Message from "../../common/Message";
import Invoice from "../invoice/Invoice";
import InvoiceTable from "../invoice/InvoiceTable";
import { BsBagCheckFill, BsCartCheckFill, BsCheckCircleFill, BsFillCartCheckFill } from "react-icons/bs";
import { AiOutlineArrowRight } from "react-icons/ai";
import { MdArrowBack } from "react-icons/md";

const htmlId = function(id : string) {
  return "COLLECT-" + id
}

export default function Collect() {
  
  const navigate = useNavigate()
  const cookies = new Cookies()
  const { t, i18n } = useTranslation()
  const [isLargerThan1024] = useMediaQuery('(min-width: 1024px)')
  const [isTalerThan512] = useMediaQuery('(min-height: 512px)')
  const toast = useToast()
  const [searchParams, setSearchParams] = useSearchParams();
  const [invoice, setInvoice] = useState<{invoice : any, error : boolean} | null>(null);
  
  const parseParams = () : {invoiceId : string | null} | null => {
    try{
      const invoiceId = searchParams.get("id")
      return {invoiceId: invoiceId}
    } catch(error) {
      console.error(error)
      toast(errorToast(null, typeof error == 'string' ? error : t('genericError')))
      return null
    }
  }
  
  const params = parseParams()
  
  document.title = formatDocumentTitle(t('collectPageTitle'))

  const collect = async () => {
    try {
      if(!params)
        throw "Params not set"
      const account = cookies.get('account')
      const invoiceId = params?.invoiceId
      const collectResponse = await axios.post(URL_MARKETPLACE_COLLECT, {invoiceId: invoiceId}, axiosConfig(account.token, i18n))
      if(collectResponse.status == 401) {
        logout(cookies, navigate)
        return
      }

      if(!isHttpCodeInRange(200, collectResponse.status)) 
        throw parseAxiosError(collectResponse)

      const invoiceResponse = await axios.post(URL_ACCOUNT_INVOICES, {invoiceIds: [invoiceId]}, axiosConfig(account.token, i18n))
      if(invoiceResponse.status == 401) {
        logout(cookies, navigate)
        return
      }

      if(!isHttpCodeInRange(200, invoiceResponse.status)) 
        throw parseAxiosError(invoiceResponse)
      const _invoice = invoiceResponse.data?.invoices?.[0]
      setInvoice({invoice: _invoice, error: !_invoice})
      
      toast(successToast(t('collectInvoiceSuccessToast')))
    } catch(error) {
      console.error(error)
      toast(errorToast(null, typeof error == 'string' ? error : t('genericError')))
    }
  }  

  useEffect(() => {
    const load = async () => {
      const auth = await checkAuth(cookies, navigate);
      if(auth.error)
        toast(errorToast(null, t('genericError')))
      else if (auth.auth) {
        if(params) 
          await collect()       
      }
    }
    load()
  }, [])
  
  
  const containerWidth = isLargerThan1024 ? "80%" : "95%"
  const containerColor = symbioseTheme.colors.headerTextColor
  const containerBg = symbioseTheme.colors.headerColor
  const headerMarginY = isLargerThan1024 ? '75px' : '50px'
  const successLogoSize = isLargerThan1024 ? 75 : 55
  const successMessageFontSize = isLargerThan1024 ? 'xl' : 'lg'

  return (
    <>
    {getBackgroundComponent()}
    <Header />
    {params && invoice ?
    <Flex flexDirection="column"  alignItems="center" justifyContent="center" textAlign="center" color={containerColor} bg={containerBg}>
      <VStack w={containerWidth}>
        <VStack my={headerMarginY}>
          <BsCheckCircleFill size={successLogoSize}/>
          <Text fontWeight='bold' fontSize={successMessageFontSize}>{t('collectPageSuccessHeader')}</Text>
          <Box h='25px'/>
          <HStack cursor='pointer' onClick={() => navigate('/portfolio')}>
            <MdArrowBack /> 
            <Text><u><i>{t('collectInvoiceGoToPortfolio')}</i></u></Text>
          </HStack>
        </VStack>
        <Box>
          <InvoiceTable invoice={invoice.invoice} />
        </Box>
      </VStack>
      <Box h='40px'/>
    </Flex>
    :
    <>
      {!params ?
      <Message message={t('collectInvoiceNotFound')} _navigate={{url: '/marketplace', message: t('paymentGoToMarketplace')}} />
      :
      <>
      {invoice?.error ?
      <Message _color={containerColor} _bg={containerBg} message={t('collectInvoiceNotFound')} _navigate={{url: '/marketplace', message: t('paymentGoToMarketplace')}} />
      :
      <Loading _color={containerColor} _bg={containerBg} message={t('collectLoading')}/>
      } 
      </>     
      }
    </>
    }    
    </>
  )
}