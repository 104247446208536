import { ReactNode, useEffect, useState } from "react";
import { Box, Flex,Text, Icon, useColorModeValue, Link, useMediaQuery, Button, Stack, InputGroup, InputLeftElement, Input, InputRightElement, InputLeftAddon, background, VStack, Divider, Center, Tooltip, HStack, FormLabel, FormControl, FormHelperText, Table, Tbody, Td, Tr, Image, NumberInput, NumberDecrementStepper, NumberInputField, NumberIncrementStepper } from "@chakra-ui/react";
import { AtSignIcon, CheckIcon, EmailIcon, InfoIcon, LockIcon, PhoneIcon, QuestionOutlineIcon, ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import { symbioseTheme } from "../../App";
import { useTranslation } from "react-i18next";


interface Props {
  emailId : string,
  defaultValue? : string,
  _color? : string
}

export default function Email({emailId, defaultValue="", _color} : Props) { 

  const { t, i18n } = useTranslation()
  
  return (
    <InputGroup>
      <InputLeftAddon
        pointerEvents='none'
        fontSize='1.1em'
        children={<AtSignIcon color={_color}/>}
        bg={'transparent'}
        color={_color}
        borderColor={_color}        
      />
      <Input id={emailId} bg={'transparent'} color={_color} borderColor={_color} _hover={{borderColor: _color}} type='text' placeholder={t('email') as string} defaultValue={defaultValue} />
    </InputGroup>
  )
}