import { ReactNode, useEffect, useState } from "react";
import { Box, Flex,Text, Icon, useColorModeValue, Link, useMediaQuery, Button, Stack, InputGroup, InputLeftElement, Input, InputRightElement, InputLeftAddon, background, VStack, Divider, Center, Tooltip, HStack, FormLabel, FormControl, FormHelperText, Tbody, Th, Tr, Thead, Table, Td, useToast, Image, useNumberInput, NumberInput, NumberInputField, NumberInputStepper, NumberDecrementStepper, NumberIncrementStepper, Popover, PopoverTrigger, PopoverHeader, PopoverContent, PopoverArrow, PopoverCloseButton, PopoverBody, TableContainer } from "@chakra-ui/react";
import { CheckIcon, EmailIcon, InfoIcon, LockIcon, PhoneIcon, QuestionOutlineIcon, ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import { axiosConfig, errorToast, formatDocumentTitle, formatPrice, getBackgroundComponent, getLanguage, getLanguageEntry, getLanguageValue, isHttpCodeInRange, logout, parseAxiosError, warningToast } from "../../../lib/utils";
import { symbioseTheme } from "../../../App";
import axios from "axios";
import Cookies from 'universal-cookie';
import { gaPageView } from "../../../lib/utils";

import { URL_CUSTODIAN_GET_PORTFOLIO, URL_ACCOUNT_IS_LOGGED_IN, URL_ACCOUNT_LOGIN, URL_MARKETPLACE_GET_PRODUCTS } from "../../../constants";
import { useNavigate } from "react-router-dom";
import Header from "../../common/Header";
import { GiConsoleController, GiShoppingCart } from "react-icons/gi";
import { useTranslation } from "react-i18next";
import Loading from "../../common/Loading";
import { AiOutlineDelete, AiOutlineShoppingCart } from "react-icons/ai";
import { MA, TA } from "country-flag-icons/react/3x2";
import ProductCard from "./ProductCard";
import PortfolioTreeCard from "../account/portfolio/PortfolioTreeCard";
import CartItem from "./CartItem";

const htmlId = function(id : string) {
  return "MARKETPLACE-" + id
}

const CURRENCY_PRICE = 'EUR'
const quantity = {min: 1, max: 10, default: 1}
const isValidQuantity = (qty : number) => {
  return qty <= quantity.max && qty >= quantity.min;
}

export default function Marketplace() {
  
  const navigate = useNavigate()
  const cookies = new Cookies()
  const { t, i18n } = useTranslation()
  const [isLargerThan1024] = useMediaQuery('(min-width: 1024px)')
  const [isTalerThan512] = useMediaQuery('(min-height: 512px)')
  const toast = useToast()
  
  const [products, setProducts] = useState<any [] | null>(null)
  const [cart, setCart] = useState<Map<string, {product: any, quantity: number}>>(new Map())
  const [displayCart, setDisplayCart] = useState<boolean>(false)

    
  document.title = formatDocumentTitle(t('marketplacePageTitle'))

  const loadMarketplace = async () => {
    try {

      const response = await axios.post(URL_MARKETPLACE_GET_PRODUCTS, {from: 0, to: 25}, axiosConfig(null, i18n))

      if(!isHttpCodeInRange(200, response.status))
        throw parseAxiosError(response)
      
      setProducts(response.data['products'])
    } catch(error) {
      console.error(error)
      toast(errorToast(null, typeof error == 'string' ? error : t('genericError')))
      setProducts(null)
    }
  }

  const canAddProductToCart = (productId : string) => { 
    const value = cart.get(productId)
    if(value)
      return value.quantity < quantity.max
    return quantity.min > 0
  }

  const addToCartFromProductCard = (product : any, quantityId : string) => {
      const quantity = document.getElementById(quantityId) as HTMLInputElement
      const qty : number = parseInt(quantity.value)
      updateCart(product, qty, true)    
  }

  const deleteFromCart = (productId : any) => {
    cart.delete(productId)
    setCart(new Map(cart))    
  }

  const updateCart = (product : any, qty : number, add : boolean) => {
    if(!isValidQuantity(qty)) {
      toast(warningToast(null, t('marketplaceInvalidQuantity', {max: quantity.max})))
      return
    }      

    let entry = cart.get(product.id)
    if(entry) {
      const newQty = (add ? entry.quantity : 0) + qty
      if(!isValidQuantity(newQty)) {
        toast(warningToast(null, t('marketplaceInvalidQuantity', {max: quantity.max})))
        return
      }
      entry.quantity = newQty
    } else
      cart.set(product.id, {product: product, quantity: qty})

      setCart(new Map(cart))    
  }

  const toggleShowCart = () => {setDisplayCart(!displayCart)}

  const isShowCart = () => { return isLargerThan1024 || displayCart}

  useEffect(() => {
    gaPageView(cookies)
    const load = async () => {
        await loadMarketplace()
    }
    load()
  }, [])
 

  const containerWidth = isLargerThan1024 ? '80%' : '70%'
  const containerColor = symbioseTheme.colors.headerTextColor
  const containerBg = symbioseTheme.colors.headerColor  

  const cartWidth = '150px'
  const cartTop = '100px'
  const cartMinHeight = isTalerThan512 ? '200px' : '150px'
  const cartMaxHeight = isTalerThan512 ? '80%' : '60%'
  const cartBorderRadius = 10
  const cartBoxShadow = isLargerThan1024 ? '3px 3px 8px 1px #454545' : '0px 0px 8px 2px #454545'
  const cartBandBackgroundColor = symbioseTheme.colors.textBackgroundColor
  const cartBandTextColor = 'white'
  const cartTotalQuantityBackgroundColor = symbioseTheme.colors.importantButtonBackgroundColor

  return (
    <>
    {getBackgroundComponent()}
    <Header />
    <Box top={cartTop} right='10px' w={cartWidth} maxW={isShowCart() ? cartWidth : 0} minH={cartMinHeight} maxH={cartMaxHeight} zIndex={1} overflowY={'auto'} opacity={isLargerThan1024 ? 0.75 : 1} _hover={{opacity: 1}} boxShadow={isShowCart() ? cartBoxShadow : 'none'} position='fixed' display='block' borderTopRadius={cartBorderRadius} color={containerColor} bg={containerBg}>
      <Flex h='38px' w='38px' zIndex={2} borderRadius='50%' position='fixed' alignItems="center" justifyContent="center" textAlign="center"
        m={isShowCart() ? '-15px 0px 0px -15px' : '-15px 0px 0px -55px'}
        bg={cartTotalQuantityBackgroundColor} display={!isLargerThan1024 || cart.size > 0 ? 'flex' : 'none'}
        opacity={isShowCart() ? 1 : 0.75}
        onClick={() => {!isLargerThan1024 && toggleShowCart()}}
      >
        <Box w='25px' h='25px' position='fixed' m='-30px 0 0 60px' display={isLargerThan1024 ? 'none' : 'block'}>
          {isShowCart() ?
          <ViewOffIcon w='100%' h='100%'/>
          :
          <ViewIcon w='100%' h='100%'/>
          }
        </Box>
        <Text fontWeight='bold' fontSize='18'>{Array.from(cart.values()).reduce<number>((sum, o) => {return sum + o.quantity}, 0)}</Text>
      </Flex>
      <Flex flexDir='column' w='100%' maxW='100%' h='100%' bg={containerBg} _hover={{bg: containerBg}} borderTopRadius={cartBorderRadius} alignItems="center" justifyContent="center" textAlign="center">
        <Flex w='100%' borderTopRadius={cartBorderRadius} alignItems="center" justifyContent="center" textAlign="center" bg={cartBandBackgroundColor}>
          <GiShoppingCart size={30} color={cartBandTextColor}/>  
        </Flex>  
        <VStack mt='5px' spacing={0} px={2}>
          <Text fontSize='lg' fontWeight='bold' wordBreak='break-all'>{formatPrice(CURRENCY_PRICE, Array.from(cart.values()).reduce<number>((sum, o) => {return sum + o.product.prices[CURRENCY_PRICE].fractionAmount * o.quantity}, 0))}</Text>
          {cookies.get('account') ?
          <Button maxW='100%' h='30px' fontSize={12} fontWeight='bolder'
            bg={cartTotalQuantityBackgroundColor} disabled={!cookies.get('account') || cart.size <= 0}
            onClick={() => {navigate('/order', {state: {cart: cart, currency: CURRENCY_PRICE}})}}
          >{t('marketplaceCartCheckout')}</Button>              
          :
          <Text fontSize='xs' fontWeight='bold'>{t('marketplaceCartSignInMessage')}</Text>
          }
        </VStack>
        <Divider w='90%' orientation='horizontal' borderWidth='1px' my='5px' />
        <VStack p={1} maxW='100%' minH='150px' >
          {Array.from(cart.values()).map((o, i) => {
            const title = getLanguageValue(i18n, o.product.titles)
            return (
              <Box key={i} maxW='100%'>
                <VStack textAlign='center'>
                  {i>0 &&
                    <Divider/>
                  }
                  <CartItem imgUrl={o.product.imageUrl} title={title} price={formatPrice(CURRENCY_PRICE, o.product.prices[CURRENCY_PRICE].fractionAmount)}/>
                  <HStack w='100%' h='30px'>                    
                    <NumberInput min={quantity.min} max={quantity.max} defaultValue={o.quantity} value={o.quantity} w='50%' borderColor={containerColor} textAlign='left'
                      onChange={(valueString) => {
                        o.quantity = parseInt(valueString);
                        setCart(new Map(cart))
                      }}                      
                    >
                      <NumberInputField id={htmlId("cart-"+o.product.id+"-quantity")} readOnly={true} h='30px' paddingRight={0} paddingLeft='15px'/>
                      <NumberInputStepper >
                        <NumberIncrementStepper border='none'/>
                        <NumberDecrementStepper border='none'/>
                      </NumberInputStepper>
                    </NumberInput>  
                    <Flex w='50%' h='100%' alignItems="center" justifyContent="center" textAlign="center">
                      <Button w='100%' h='100%' onClick={() => {deleteFromCart(o.product.id)}}><AiOutlineDelete size={25}/></Button>
                    </Flex>
                  </HStack>
                </VStack>
              </Box>
            )
            })
          }
        </VStack>         
        <Box w='100%' h='10px'/>
      </Flex>
    </Box>
    {products ? 
    <Flex flexDirection="column" alignItems="center" justifyContent="center" textAlign="center" color={containerColor} bg={containerBg}>
      <VStack w={containerWidth} maxW={containerWidth}>
        <Box h='50px'/>
        <Flex flexDirection='row' flexWrap='wrap' alignItems="center" justifyContent="center" textAlign="center">
        {products.map((o, i) => (
          <ProductCard key={i} product={o} currency={CURRENCY_PRICE} quantity={quantity} canAddProductToCart={canAddProductToCart} addToCartFromProductCard={addToCartFromProductCard}/>
        ))}
        </Flex>
        <Box h='50px'/>            
      </VStack>
    </Flex>
    :
    <Loading _color={containerColor} _bg={containerBg} />
    }    
    </>
  )
}