import { ReactNode, useEffect, useState } from "react";
import { Box, Flex,Text, Icon, useColorModeValue, Link, useMediaQuery, Button, Stack, InputGroup, InputLeftElement, Input, InputRightElement, InputLeftAddon, background, VStack, Divider, Center, Tooltip, HStack, FormLabel, FormControl, FormHelperText, Tbody, Th, Tr, Thead, Table, Td, useToast, Image, useNumberInput, NumberInput, NumberInputField, NumberInputStepper, NumberDecrementStepper, NumberIncrementStepper, Popover, PopoverTrigger, PopoverHeader, PopoverContent, PopoverArrow, PopoverCloseButton, PopoverBody, TableContainer } from "@chakra-ui/react";
import { CheckIcon, EmailIcon, InfoIcon, LockIcon, PhoneIcon, QuestionOutlineIcon, ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import { axiosConfig, checkAuth, errorToast, formatDocumentTitle, formatPrice, getBackgroundComponent, getLanguage, getLanguageEntry, getLanguageValue, isHttpCodeInRange, logout, parseAxiosError } from "../../../lib/utils";
import { symbioseTheme } from "../../../App";
import axios from "axios";
import Cookies from 'universal-cookie';
import { URL_CUSTODIAN_GET_PORTFOLIO, URL_ACCOUNT_IS_LOGGED_IN, URL_ACCOUNT_LOGIN, URL_MARKETPLACE_GET_PRODUCTS, URL_ACCOUNT_INVOICES } from "../../../constants";
import { useNavigate, useSearchParams } from "react-router-dom";
import Header from "../../common/Header";
import { GiConsoleController, GiShoppingCart } from "react-icons/gi";
import { useTranslation } from "react-i18next";
import Loading from "../../common/Loading";
import { AiOutlineDelete, AiOutlineShoppingCart } from "react-icons/ai";
import Message from "../../common/Message";
import InvoiceTable from "./InvoiceTable";


const htmlId = function(id : string) {
  return "INVOICE-" + id
}

export default function Invoice() {
  
  const navigate = useNavigate()
  const cookies = new Cookies()
  const { t, i18n } = useTranslation()
  const [isLargerThan1024] = useMediaQuery('(min-width: 1024px)')
  const [isTalerThan512] = useMediaQuery('(min-height: 512px)')
  const toast = useToast()
  const [searchParams, setSearchParams] = useSearchParams();
  const [invoice, setInvoice] = useState<{invoice : any, error : boolean} | null>(null);
 

    
  const parseParams = () : {invoiceId : string | null} | null => {
    try{
      const invoiceId = searchParams.get("id")
      return {invoiceId: invoiceId}
    } catch(error) {
      console.error(error)
      toast(errorToast(null, typeof error == 'string' ? error : t('genericError')))
      return null
    }
  }
  
  
  document.title = formatDocumentTitle(t('invoicePageTitle', {id: invoice && !invoice.error ? invoice.invoice?.id : ""}))
  const params = parseParams()
   

  const loadInvoice = async () => {
    try {
      if(!params?.invoiceId) {
        toast(errorToast(null, t('invoiceIdNotSet')))  
        setInvoice({invoice: null, error: true})
        return
      }
        
      const account = cookies.get('account')
     
      const invoiceResponse = await axios.post(URL_ACCOUNT_INVOICES, {invoiceIds: [params!.invoiceId]}, axiosConfig(account.token, i18n))
      if(invoiceResponse.status == 401) {
        logout(cookies, navigate)
        return
      }

      if(!isHttpCodeInRange(200, invoiceResponse.status)) 
        throw parseAxiosError(invoiceResponse)
      const _invoice = invoiceResponse.data?.invoices?.[0]
      setInvoice({invoice: _invoice, error: !_invoice})
      if(_invoice)
        document.title = formatDocumentTitle(t('invoicePageTitle', {id: _invoice.id}))
    } catch(error) {
      console.error(error)
      toast(errorToast(null, typeof error == 'string' ? error : t('genericError')))
    }
  }  

  useEffect(() => {
    const load = async () => {
      const auth = await checkAuth(cookies, navigate);
      if(auth.error)
        toast(errorToast(null, t('genericError')))
      else if (auth.auth) {
        if(params) 
          await loadInvoice()       
      }
    }
    load()
  }, [])
 
  const containerWidth = isLargerThan1024 ? "80%" : "95%"
  return (
    <>
    {getBackgroundComponent()}
    <Header />
    {params && invoice ? 
      <>
      {invoice.error ?
        <Message message={t('invoiceLoadingFailed')}/>
        :
        <Flex flexDirection="column" w='100%' alignItems="center" justifyContent="center" textAlign="center">
          <Box mt='100px' w={containerWidth}>
            <InvoiceTable invoice={invoice.invoice} />
          </Box>
        </Flex>
      }
      </>
    :
    <Loading message={t('invoiceLoading')}/>
    }
    
    </>
  )
}