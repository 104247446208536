import { ReactNode, useEffect } from "react";
import { Box, Flex, Text, Icon, useColorModeValue, Image, VStack, HStack, Link, useColorMode, Button, useMediaQuery, Table, Tbody, Tr, Td, Accordion, AccordionItem, AccordionIcon, AccordionPanel, AccordionButton } from "@chakra-ui/react";

import Header from "../../common/Header";
import { formatDocumentTitle, getBackgroundComponent, getHomeBackgroundComponent } from "../../../lib/utils";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import { useTranslation } from "react-i18next";
import { Trans } from 'react-i18next'
import { gaPageView } from "../../../lib/utils";

import retailBackground from '../../../img/forest/retail-bg.jpg';
import retailBackgroundTiny from '../../../img/forest/retail-bg-tiny.jpg';
import process1 from '../../../img/icon/retail-process-1.png';
import process2 from '../../../img/icon/retail-process-2.png';
import process3 from '../../../img/icon/retail-process-3.png';
import process4a from '../../../img/icon/retail-process-4a.png';
import process4b from '../../../img/icon/retail-process-4b.png';
import { symbioseTheme } from "../../../App";
import { GiEarthAfricaEurope, GiEarthAmerica, GiEcology, GiForest, GiReceiveMoney } from "react-icons/gi";
import { ImEarth } from "react-icons/im";
import { MdAccountBalance, MdSavings } from "react-icons/md";
import { BsFillTreeFill } from "react-icons/bs";
import { RiPlantLine } from "react-icons/ri";
import ProgressiveImage from "react-progressive-graceful-image";


export default function AboutRetail() {
  
  const navigate = useNavigate()
  const cookies = new Cookies()
  const { colorMode } = useColorMode()
  const [isLargerThan1024] = useMediaQuery('(min-width: 1024px)')
  const [isLargerThan512] = useMediaQuery('(min-width: 512px)')
  const { t, i18n } = useTranslation()

  const hasAccount = cookies.get('account') ? true : false
  document.title = formatDocumentTitle(t('navbarAboutRetail'))

  useEffect(() => {
    gaPageView(cookies)
  }, [])
  
  
  
  const forestHeight = isLargerThan512 ? '600px' : '300px'
  const forestFontSize = isLargerThan512 ? 'xl' : 'md'
  const forestFontWeight = isLargerThan512 ? 'bold' : 'normal'
  const forestMargin = isLargerThan512 ? 10 : 0
  const forestPadding = isLargerThan512 ? 3 : 3

  const sectionHeight = isLargerThan512 ? '400px' : '200px'
  const sectionPadding = isLargerThan512 ? 5 : 2
  const sectionTitleSize = isLargerThan512 ? '60' : '3xl'
  const sectionMsgSize = isLargerThan512 ? 'xl' : 'md'
  const sectionMinSpacing = 10

  const pitchMsgMaxWidth = isLargerThan512 ? '80%' : '90%'
  const picthLogoTitleSize = isLargerThan512 ? 'md' : 'sm'
  const picthLogoTitleWidth = isLargerThan512 ? '250px' : '150px'
  const picthLogoSize = isLargerThan512 ? 70 : 35
  const picthLogoMargin = isLargerThan512 ? 3 : 3
  const picthLogoPadding = isLargerThan512 ? 2 : 2


  const processWidth = isLargerThan1024 ? '1000px' : '90%'
  const processHeight = isLargerThan512 ? '200px' : '200px'
  const processInnerBoxSeparatorWidth = isLargerThan1024 ? 100 : 0
  const processInnerBoxSeparatorHeight = isLargerThan1024 ? 0 : 150
  const processBoxSeparatorHeight = isLargerThan1024 ? 20 : 0
  const processNumFontSize = isLargerThan512 ? 100 : 70
  const processNumFontWeight = isLargerThan512 ? 'extrabold' : 'bold'
  const processMsgFontWeight = isLargerThan512 ? 'bold' : 'normal'

  const process1Icon = <Image src={process1} boxSize={100} />
  const process2Icon = <Image src={process2} boxSize={100} />
  const process3Icon = <Image src={process3} boxSize={100} />
  const process4aIcon = <Image src={process4a} boxSize={100} />
  const process4bIcon = <Image src={process4b} boxSize={100} />
  


  const faqWidth = isLargerThan512 ? '60%' : '90%'
  
   return (
    <>
    {getBackgroundComponent()}
    <Header />
    <Flex flexDirection="column" alignItems="center" justifyContent="center" textAlign="center" width="100%">
      <VStack position='relative' spacing={5} w='100%' h={forestHeight} alignItems="center" justifyContent="center">
        <ProgressiveImage 
          src={retailBackground} placeholder={retailBackgroundTiny}
          >
          {(src, loading) => (
            <img style={{ opacity: loading ? 1 : 1, objectFit: 'cover', zIndex: -1, position: 'absolute', width: '100%', height: forestHeight}}  src={src} alt="Retail" />
          )}
        </ProgressiveImage>
        <Box m={forestMargin} p={forestPadding} bg={symbioseTheme.colors.textBackgroundColor} fontSize={forestFontSize} maxW={isLargerThan1024 ? '1024px' : ''}>
          <Text fontWeight={forestFontWeight} w='100%'>{t('aboutRetailPageTopMsg')}</Text>
        </Box>
        <a href="/login">
          <Button variant='outline'>{t('aboutRetailPagePitchSignIn')}</Button>
        </a>
      </VStack>
      <Flex flexDirection="column" w='100%' minHeight={sectionHeight} p={sectionPadding} color={symbioseTheme.colors.textBackgroundColor} bg={symbioseTheme.colors.headerColor} alignItems="center" justifyContent="center">
        <Box flexGrow={1} />
        <Text fontSize={sectionTitleSize} fontWeight='bold'>{t('aboutRetailPagePitchTitle')}</Text>
        <Box flexGrow={1} minH={sectionMinSpacing} />
        <Text fontSize={sectionMsgSize} maxW={pitchMsgMaxWidth}><Trans>{t('aboutRetailPagePitchMsg')}</Trans></Text>
        <Box flexGrow={1} minH={sectionMinSpacing} />
        <Flex flexDirection="row" alignItems="center" justifyContent="center" wrap='wrap'>
          <VStack m={picthLogoMargin}>
            <Text w={picthLogoTitleWidth} fontSize={picthLogoTitleSize} fontWeight='bold'>{t('aboutRetailPagePitchBrick1Title')}</Text>
            <Box bg={symbioseTheme.colors.textBackgroundColor} p={picthLogoPadding} color={symbioseTheme.colors.textColor}>
              {/*<GiEarthAfricaEurope size={picthLogoSize}/>*/}
              {/*<GiEcology size={picthLogoSize}/>*/}
              {<RiPlantLine size={picthLogoSize}/>}
            </Box>
          </VStack>
          <VStack m={picthLogoMargin}>
            <Text w={picthLogoTitleWidth} fontSize={picthLogoTitleSize} fontWeight='bold'>{t('aboutRetailPagePitchBrick2Title')}</Text>
            <Box bg={symbioseTheme.colors.textBackgroundColor} p={picthLogoPadding} color={symbioseTheme.colors.textColor}>
              {/*<GiReceiveMoney size={picthLogoSize}/>*/}
              {/*<MdAccountBalance size={picthLogoSize}/>*/}
              {<MdAccountBalance size={picthLogoSize}/>}
            </Box>
          </VStack>
          <VStack m={picthLogoMargin}>
            <Text w={picthLogoTitleWidth} fontSize={picthLogoTitleSize} fontWeight='bold'>{t('aboutRetailPagePitchBrick3Title')}</Text>
            <Box bg={symbioseTheme.colors.textBackgroundColor} p={picthLogoPadding} color={symbioseTheme.colors.textColor}>
              {/*<BsFillTreeFill size={picthLogoSize}/>*/}
              {/*<GiForest size={picthLogoSize}/>*/}
              {<BsFillTreeFill size={picthLogoSize}/>}
            </Box>
          </VStack>
        </Flex>
        <Box flexGrow={1} minH={sectionMinSpacing} />
        <VStack>
          <a href="/marketplace">
            <Button variant='outline'>{t('aboutRetailPagePitchVisitMarketplace')}</Button>
          </a>
        </VStack>
        <Box flexGrow={1} minH={sectionMinSpacing} />
      </Flex>
      <Flex flexDirection="column" w='100%' minHeight={sectionHeight} p={sectionPadding} color={symbioseTheme.colors.textColor} bg={symbioseTheme.colors.textBackgroundColor} alignItems="center" justifyContent="center">
        <Box flexGrow={1} />
        <Text fontSize={sectionTitleSize} fontWeight='bold'>{t('aboutRetailPageProcessTitle')}</Text>
        <Box flexGrow={1} minH={sectionMinSpacing} />
        <Text fontSize={sectionMsgSize}>{t('aboutRetailPageProcessSubTitle')}</Text>        
        <Box flexGrow={1} minH={sectionMinSpacing} />
        <Flex flexDirection="row" flexWrap={isLargerThan1024 ? 'nowrap' : 'wrap'} w={processWidth} minH={processHeight} p={sectionPadding} alignItems="center" justifyContent="center">
          <VStack alignItems="center" justifyContent="center">
            <Text fontSize={processNumFontSize} fontWeight={processNumFontWeight}>01.</Text>
          </VStack>
          <Box w={processInnerBoxSeparatorWidth} h={processInnerBoxSeparatorHeight} />
          <VStack  flexGrow={1} alignItems="center" justifyContent="center" textAlign="left">
            <Text fontSize={sectionMsgSize} fontWeight={processMsgFontWeight}>{t('aboutRetailPageProcessMsg1')}</Text>
          </VStack>
          <Box w={processInnerBoxSeparatorWidth} h={processInnerBoxSeparatorHeight} />
          {process1Icon}
        </Flex>
        <Box h={processBoxSeparatorHeight} />
        <Flex flexDirection="row" flexWrap={isLargerThan1024 ? 'nowrap' : 'wrap-reverse'} w={processWidth} minH={processHeight} p={sectionPadding} alignItems="center" justifyContent="center">
          {process2Icon}
          <Box w={processInnerBoxSeparatorWidth} h={processInnerBoxSeparatorHeight} />
          <VStack flexGrow={1} alignItems="center" justifyContent="center" textAlign="left">
            <Text fontSize={sectionMsgSize} fontWeight={processMsgFontWeight}>{t('aboutRetailPageProcessMsg2')}</Text>
          </VStack>
          <Box w={processInnerBoxSeparatorWidth} h={processInnerBoxSeparatorHeight} />
          <VStack alignItems="center" justifyContent="center">
            <Text fontSize={processNumFontSize} fontWeight={processNumFontWeight}>02.</Text>
          </VStack>          
        </Flex>
        <Box h={processBoxSeparatorHeight} />
        <Flex flexDirection="row" flexWrap={isLargerThan1024 ? 'nowrap' : 'wrap'} w={processWidth} minH={processHeight} p={sectionPadding} alignItems="center" justifyContent="center">
          <VStack alignItems="center" justifyContent="center">
            <Text fontSize={processNumFontSize} fontWeight={processNumFontWeight}>03.</Text>
          </VStack>
          <Box w={processInnerBoxSeparatorWidth} h={processInnerBoxSeparatorHeight} />
          <VStack  flexGrow={1} alignItems="center" justifyContent="center" textAlign="left">
            <Text fontSize={sectionMsgSize} fontWeight={processMsgFontWeight}>{t('aboutRetailPageProcessMsg3')}</Text>
          </VStack>
          <Box w={processInnerBoxSeparatorWidth} h={processInnerBoxSeparatorHeight} />
          {process3Icon}
        </Flex>
        <Box h={processBoxSeparatorHeight} />
        <Flex flexDirection="row" flexWrap={isLargerThan1024 ? 'nowrap' : 'wrap-reverse'} w={processWidth} minH={processHeight} p={sectionPadding} alignItems="center" justifyContent="center">
          {process4aIcon}
          <Box w={processInnerBoxSeparatorWidth} h={processInnerBoxSeparatorHeight} />
          <VStack flexGrow={1} alignItems="center" justifyContent="center" textAlign="left">
            <Text fontSize={sectionMsgSize} fontWeight={processMsgFontWeight}>{t('aboutRetailPageProcessMsg4.a')}</Text>
          </VStack>
          <Box w={processInnerBoxSeparatorWidth} h={processInnerBoxSeparatorHeight} />
          <VStack alignItems="center" justifyContent="center">
            <HStack>
              <Text fontSize={processNumFontSize} fontWeight={processNumFontWeight}>04.</Text>
              <Text fontSize={sectionMsgSize} fontWeight={processNumFontWeight}>(a)</Text>
            </HStack>
          </VStack>
        </Flex>
        <Box h={processBoxSeparatorHeight} />
        <Flex flexDirection="row" flexWrap={isLargerThan1024 ? 'nowrap' : 'wrap'} w={processWidth} minH={processHeight} p={sectionPadding} alignItems="center" justifyContent="center">
          <VStack alignItems="center" justifyContent="center">
            <HStack>
            <Text fontSize={processNumFontSize} fontWeight={processNumFontWeight}>04.</Text>
            <Text fontSize={sectionMsgSize} fontWeight={processNumFontWeight}>(b)</Text>
            </HStack>
          </VStack>
          <Box w={processInnerBoxSeparatorWidth} h={processInnerBoxSeparatorHeight} />
          <VStack  flexGrow={1} alignItems="center" justifyContent="center" textAlign="left">
            <Text fontSize={sectionMsgSize} fontWeight={processMsgFontWeight}>{t('aboutRetailPageProcessMsg4.b')}</Text>
          </VStack>
          <Box w={processInnerBoxSeparatorWidth} h={processInnerBoxSeparatorHeight} />
          {process4bIcon}
        </Flex>
        <Box flexGrow={1} minH={sectionMinSpacing} />
      </Flex>
      <Flex flexDirection="column" w='100%' minHeight={sectionHeight} p={sectionPadding} color={symbioseTheme.colors.textBackgroundColor} bg={symbioseTheme.colors.headerColor} alignItems="center" justifyContent="center">
        <Box flexGrow={1} />
        <Accordion allowMultiple w={faqWidth}>
          <Text fontSize={sectionTitleSize} fontWeight='bold'>{t('aboutRetailPageFAQ')}</Text>
          <Box flexGrow={1} minH={sectionMinSpacing} />
          {[1, 2, 3].map((o, i) => {
            return (
              <Box key={i}>
                <AccordionItem  bg={symbioseTheme.colors.textBackgroundColor} color={symbioseTheme.colors.textColor}>
                  <h2>
                    <AccordionButton opacity={0.9} _hover={{opacity: 1}} >
                      <Text fontSize={sectionMsgSize} fontWeight={'bold'}>{t('aboutRetailPageFAQ'+o+'Question')}</Text>
                      <Box flexGrow={1}/>
                      <AccordionIcon />
                    </AccordionButton >
                  </h2>                
                  <AccordionPanel >
                      <Text fontSize={sectionMsgSize} textAlign={'left'}>{t('aboutRetailPageFAQ'+o+'Answer')}</Text>
                  </AccordionPanel>
                </AccordionItem>
                <Box h={10}/>
              </Box>
            )
          })}          
        </Accordion>
        <Box flexGrow={1} />
      </Flex>
    </Flex>
  </>
  )
}