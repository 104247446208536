import { ReactNode, useEffect, useState } from "react";
import { Box, Flex,Text, Icon, useColorModeValue, Link, useMediaQuery, Button, Stack, InputGroup, InputLeftElement, Input, InputRightElement, InputLeftAddon, background, VStack, Divider, Center, Tooltip, HStack, FormLabel, FormControl, FormHelperText, Table, Tbody, Td, Tr, Image, NumberInput, NumberDecrementStepper, NumberInputField, NumberIncrementStepper, useToast, InputRightAddon, PinInput, PinInputField } from "@chakra-ui/react";
import { CheckIcon, EmailIcon, InfoIcon, LockIcon, PhoneIcon, QuestionOutlineIcon, ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import { axiosConfig, formatDocumentTitle, formatPrice, getBackgroundComponent, getLanguage, getLanguageEntry, getLanguageValue, isHttpCodeInRange, logout, parsePinInput } from "../../lib/utils";
import { symbioseTheme } from "../../App";
import { useTranslation } from "react-i18next";
import { MdOutlinePassword, MdPassword } from "react-icons/md";
import { errorToast, parseAxiosError, successToast } from "../../lib/utils";

interface Props {
  tfaId : string
  width? : string
  onEnter?() : any,
  _color? : string,
  _bg? : string
}

export default function TFA({tfaId, width = 'fit-content', onEnter = undefined, _color, _bg} : Props) { 

  const { t, i18n } = useTranslation()
  const [isLargerThan350] = useMediaQuery('(min-width: 350px)')

  const onKey = (e: any) => {
    if(!onEnter)
      return
    
    const tfa = parsePinInput(tfaId)
    if(tfa && tfa.length == 6)
      onEnter()
    else if(e.key === 'Enter' || e.keyCode === 13)
      onEnter()
  }
  
  const pinBorderWidth = 0
  const borderRadius = 5
  const pinSize = isLargerThan350 ? 'sm' : 'xs'

  const pin = (
    <InputGroup justifyContent='center' alignItems='center' alignContent='center' w={width} >
      <HStack border={'1px solid'} borderRadius={borderRadius} w='100%' bg={_bg} color={_color} borderColor={_color}>
        <InputLeftAddon
          p={0}
          w={'51px'} 
          border='none'
          borderRight={'1px'}
          //borderRadius={borderRadius-3}
          pointerEvents='none'
          fontSize='1.1em'
          bg='transparent'
          //children={<Box w='100%' color={symbioseTheme.colors.backgroundColor} ><MdOutlinePassword /></Box>}
          children={<Box w='100%'><Text w='100%' textAlign={'center'}>2FA</Text></Box>}
        />
          
        <PinInput id={tfaId} size={pinSize} otp>
          <PinInputField borderWidth={pinBorderWidth} autoFocus onKeyDown={onKey} onKeyUp={onKey} />
          <PinInputField borderWidth={pinBorderWidth} onKeyDown={onKey} onKeyUp={onKey} />
          <PinInputField borderWidth={pinBorderWidth} onKeyDown={onKey} onKeyUp={onKey} />
          <PinInputField borderWidth={pinBorderWidth} onKeyDown={onKey} onKeyUp={onKey} />
          <PinInputField borderWidth={pinBorderWidth} onKeyDown={onKey} onKeyUp={onKey} />
          <PinInputField borderWidth={pinBorderWidth} onKeyDown={onKey} onKeyUp={onKey} />
        </PinInput>
        <Box w='1px'/>
      </HStack>
    </InputGroup>
  )

  const input = (
    <InputGroup>
      <InputLeftAddon
        pointerEvents='none'
        fontSize='1.1em'
        children={<Box color={symbioseTheme.colors.backgroundColor} ><MdOutlinePassword /></Box>}
        bg={symbioseTheme.colors.textColor}
      />
      <Input id={tfaId} type='text' textAlign='center' placeholder={t('2fa') as string} autoComplete="off" />
    </InputGroup>
  )
  return pin
}