import { ReactNode, useEffect, useState } from "react";
import { Box, Flex,Text, Icon, useColorModeValue, Link, useMediaQuery, Button, Stack, InputGroup, InputLeftElement, Input, InputRightElement, InputLeftAddon, background, VStack, Divider, Center, Tooltip, HStack, FormLabel, FormControl, FormHelperText, Table, Tbody, Td, Tr, Image, NumberInput, NumberDecrementStepper, NumberInputField, NumberIncrementStepper, SimpleGrid, Switch, useToast, useDisclosure } from "@chakra-ui/react";
import { axiosConfig, errorToast, formatDocumentTitle, formatPrice, formatUsername, getBackgroundComponent, getLanguage, getLanguageEntry, getLanguageValue, is2FARequired, isHttpCodeInRange, parseAxiosError, parseInputs, parsePinInput, string2NullIfEmpty, successToast, tryClearInput } from "../../../../lib/utils";
import Cookies from 'universal-cookie';
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";


const htmlId = function(id : string) {
  return "SETTINGSOCIALS-" + id
}

const OTA_ID = htmlId("ota")
const ADDRESS_LINES_ID = htmlId("address-lines")
const ADDRESS_COUNTRY_ID = htmlId("address-country")

interface Props {
  accountInfo : any,
  refreshAccountInfo() : any,
  _color? : string,
  _bg? : string
}

export default function Socials({accountInfo, refreshAccountInfo, _color, _bg} : Props) { 

  const navigate = useNavigate()
  const cookies = new Cookies()
  const { t, i18n } = useTranslation()
  const [isLargerThan1024] = useMediaQuery('(min-width: 1024px)')
  const [isTalerThan512] = useMediaQuery('(min-height: 512px)')
  const toast = useToast()

  const { isOpen, onOpen, onClose } = useDisclosure()
  const [processing, setProcessing] = useState(false)

  

  useEffect(() => {
   
  }, [])

  const containerWidth = isLargerThan1024 ? '90%' : '95%'
  const spaceBox = <Box h='25px'/>
  const smallBox = <Box h='10px'/>
  const titleFontSize = 'md'
  const titleFontWeight = 'bold'
  const labelFontSize = 'sm'
  const labelMargin = '0 0 0 0'
  const valueFontSize = 'md'
  const valueFontWeight = 'bold'

  return (
    <VStack w={containerWidth} minW={'270px'}>
      <FormControl>
        <FormLabel fontSize={titleFontSize} fontWeight={titleFontWeight} margin={labelMargin} textAlign='center'><u>{t('settingsUpdateSocialsTitle')}</u></FormLabel>
        {spaceBox}
        <FormControl border='none'>
          <FormLabel fontSize={labelFontSize} margin={labelMargin}><u>{t('settingsReferralId')}</u></FormLabel>
          {smallBox}
          <Text fontSize={valueFontSize} fontWeight={valueFontWeight} textAlign='center'>{accountInfo.socials.referralId}</Text>
        </FormControl>
        {spaceBox}
        <FormControl border='none'>
          <FormLabel fontSize={labelFontSize} margin={labelMargin}><u>{t('settingsPeusdo')}</u></FormLabel>
          {smallBox}
          <Text fontSize={valueFontSize} fontWeight={valueFontWeight} textAlign='center'>{formatUsername(accountInfo.socials.username)}</Text>
        </FormControl>
      </FormControl>
      <Box h='300px' /*Scrollbar on all settings*/ /> 
    </VStack>
  )
}