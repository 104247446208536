import { ReactNode, useEffect, useState } from "react";
import { Box, Flex,Text, Icon, useColorModeValue, Link, useMediaQuery, Button, Stack, InputGroup, InputLeftElement, Input, InputRightElement, InputLeftAddon, background, VStack, Divider, Center, Tooltip, HStack, FormLabel, FormControl, FormHelperText, Table, Tbody, Td, Tr, Image, NumberInput, NumberDecrementStepper, NumberInputField, NumberIncrementStepper, useToast, InputRightAddon, Select } from "@chakra-ui/react";
import { symbioseTheme } from "../../App";
import axios from "axios";
import Cookies from 'universal-cookie';
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { errorToast, isValidCurrency, parseAxiosError, successToast } from "../../lib/utils";
import countries from "i18n-iso-countries"
import enCountries from "i18n-iso-countries/langs/en.json"
import frCountries from "i18n-iso-countries/langs/en.json"

interface Props {
  addressId : string
  countryId : string
  addressLines? : string []
  addressCountry? : string,
  _color? : string,
  _bg? : string
}

export default function PostalAddress({addressId, countryId, addressLines, addressCountry, _color} : Props) { 

  const { t, i18n } = useTranslation()

  countries.registerLocale(enCountries)
  countries.registerLocale(frCountries)
  const countryList = Object.entries(countries.getNames("en", {select: 'official'})).map(([k, v]) => {return {label: v, value: k};})

  const getAddressInverseByIndex = function(index : number) {
    if(!addressLines || index >= addressLines.length)
      return ""
    return addressLines[addressLines.length - index - 1]
  }


  const labelFontSize = 'sm'
  const labelMargin = '40px 0 20px 0'

  return (
    <VStack>
      <FormControl>
        <FormLabel fontSize={labelFontSize} margin={labelMargin}><u>{t('settingsContactPostalAddressBuildingInfo')}</u></FormLabel>
        <Input id={addressId + "-0"} variant='flushed'textAlign='center' defaultValue={getAddressInverseByIndex(2)} borderColor={_color} />
      </FormControl>
      <FormControl>
        <FormLabel fontSize={labelFontSize} margin={labelMargin}><u>{t('settingsContactPostalAddressStreetInfo')}</u></FormLabel>
        <Input id={addressId + "-1"} variant='flushed'textAlign='center' defaultValue={getAddressInverseByIndex(1)} borderColor={_color} />
      </FormControl>
      <FormControl>
        <FormLabel fontSize={labelFontSize} margin={labelMargin}><u>{t('settingsContactPostalAddressAreaCode')}</u></FormLabel>
        <Input id={addressId + "-2"} variant='flushed'textAlign='center' defaultValue={getAddressInverseByIndex(0)} borderColor={_color} />
      </FormControl>
      <FormControl>
        <FormLabel fontSize={labelFontSize} margin={labelMargin}><u>{t('settingsContactPostalAddressCountry')}</u></FormLabel>
        <Select id={countryId} variant='outline' textAlign='center' borderColor={_color}>
          {countryList.map((o, i) => {
            return <option selected={addressCountry ? (addressCountry == o.label) : (o.value == 'FR')} key={i} value={o.label}>{o.label}</option>
          })}
        </Select>
      </FormControl>
    </VStack>
  )
}