import { ReactNode, useEffect, useState } from "react";
import { Box, Flex,Text, Icon, useColorModeValue, Link, useMediaQuery, Button, Stack, InputGroup, InputLeftElement, Input, InputRightElement, InputLeftAddon, background, VStack, Divider, Center, Tooltip, HStack, FormLabel, FormControl, FormHelperText, Table, Tbody, Td, Tr, Image } from "@chakra-ui/react";
import { CheckIcon, EmailIcon, LockIcon, PhoneIcon, QuestionOutlineIcon } from "@chakra-ui/icons";
import { axiosConfig, formatDocumentTitle, formatPrice, getBackgroundComponent, isHttpCodeInRange, logout } from "../../../lib/utils";
import { symbioseTheme } from "../../../App";
import axios from "axios";
import Cookies from 'universal-cookie';
import { URL_CUSTODIAN_GET_PORTFOLIO, URL_ACCOUNT_IS_LOGGED_IN, URL_ACCOUNT_LOGIN } from "../../../constants";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const htmlId = function(id : string) {
  return "PAYMENTITEM-" + id
}

interface Props {
  product : any,
  title : string
  quantity : number,
  unitPrice : number,
  currency : string
}

export default function PaymentItem({product, title, quantity, unitPrice, currency} : Props) {

  const { t, i18n } = useTranslation()
  const [isLargerThan1024] = useMediaQuery('(min-width: 1024px)')
  const [isLargerThan360] = useMediaQuery('(min-width: 360px)')
  const [isTalerThan512] = useMediaQuery('(min-height: 512px)')
  const isSmall = !isLargerThan1024 && !isTalerThan512
   
  const width = isLargerThan1024 ? '400px' :  (isLargerThan360 ? '250px' : '230px')
  const borderRadius = 5
  const margin = isSmall ? 1 : 4
  const titleFontSize = isSmall ? 'lg' : 'md'
  const tableFontSize = isSmall ? 'md' : '15'
  
  return (
    <HStack w={width} h='150px' m={margin} bg='white' borderRadius={borderRadius} boxShadow='3px 3px 8px 1px #454545' spacing={0}>
      <Image 
        w='35%' h='100%'
        display='block'
        objectFit='cover'
        src={product.imageUrl}
        alt={title}
        borderLeftRadius={borderRadius}
      />

      <VStack w='65%' h='100%' spacing={0} borderRadius={borderRadius}>
        <Text w='100%' fontWeight='bold' fontSize={titleFontSize} overflow={'hidden'} textOverflow='ellipsis' whiteSpace='nowrap' borderRadius={borderRadius}>{title}</Text>
        <Flex flexDirection='row' flexGrow={1} w='100%' p={1} borderRadius={borderRadius}>
          <Box flexGrow={1}/>
          <Flex flexDirection='column' minW='60%' p={1} borderRadius={borderRadius}>
            <Box w='100%' flexGrow={1}/>
            <Table variant='unstyled' size='xs'>
              <Tbody>
                <Tr>
                  <Td textAlign={'left'} fontSize={tableFontSize}>{quantity} x</Td>
                  <Td textAlign={'center'} fontSize={tableFontSize}>{formatPrice(currency, unitPrice)}</Td>
                </Tr>
                <Tr>
                  <Td textAlign={'left'} fontWeight='bold' fontSize={tableFontSize}>{t('paymentTotal')}</Td>
                  <Td textAlign={'center'} fontWeight='bold' fontSize={tableFontSize}>&nbsp;{formatPrice(currency, unitPrice * quantity)}</Td>
                </Tr>
              </Tbody>
            </Table>
          </Flex>
        </Flex>
      </VStack>      
    </HStack>
  )
}