import { extendTheme } from '@chakra-ui/react'
import { mode } from '@chakra-ui/theme-tools'

const theme = extendTheme({
    components: {
        Button: {
            baseStyle: {
                _focus: {
                    boxShadow: 'none'
                }
            },
            variants: {
                solid: (props) => ({
                    color: "#F5F5F5",
                    bg: "#556B2FEA",
                    _hover:{
                        bg: "#556B2FFF",
                    },
                    _active: {
                        bg: "#45532C"
                    }
                }),
                "outline": (props) => ({
                    size: "sm",
                    /*borderColor: mode("purple.600", "orange.600")(props),*/
                    //border: 'none',
                    borderRadius: 0,
                    color: "#F5F5F5",
                    bg: "#556B2FEA",
                    //textDecoration: 'underline',
                    /*fontWeight: "normal",
                    px: 2,
                    height: "26px",*/
                    _hover: {
                        bg: "#556B2FFF"
                    },
                    _active: {
                        bg: "#45532C"
                    }
                }),
                "link": (props) => ({
                    size: "sm",
                    color: mode("purple.600", "orange.600")(props),
                    fontWeight: "normal",
                    fontSize: "sm",
                    _hover:{
                      textDecoration: "none",
                      color: mode("purple.800", "orange.900")(props),
                    }
                }),
            },
            default: {
                size: 'xl',
                variant: 'solid',
            }
        },
    },
})

export default theme